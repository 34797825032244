<script>
import HeaderMain from "@/components/main/HeaderMain.vue";
import FooterMain from "@/components/main/FooterMain.vue";

export default {
  name: "AboutUsPage",
  components: {FooterMain, HeaderMain}
}
</script>

<template>
  <HeaderMain/>
  <div class="about-us-wrapper">
    <div class="hero-image desktop" style="background-image: url(https://soxy.com/blogs/wp-content/uploads/2024/03/fee2cad8f423d8f7c29b505e4da398f1.jpg); "></div>
    <div class="about-section container">
      <div class="row">
        <div class="col-12">
          <div class="category-separator">
            <p class="category-text">About Us</p>
            <div class="separator"></div>
          </div>
        </div>
        <div class="col-12">
          <h1>Our mission is to revolutionize the shopping experience by making it easier, better, and more enjoyable for everyone.</h1>
        </div>
      </div>
    </div>
    <div class="hero-image mobile" style="background-image: url(https://soxy.com/blogs/wp-content/uploads/2024/03/fee2cad8f423d8f7c29b505e4da398f1.jpg); "></div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4">
          <h3>Centralized Shopping</h3>
          <p>We partner with the top retailers all over the world to make it easy for you to browse all their products in one place.</p>
        </div>
        <div class="col-12 col-md-4">
          <h3>Full Transparency</h3>
          <p>With our unique data access, we provide you with full transparency on pricing and similar products that you may want to consider</p>
        </div>
        <div class="col-12 col-md-4">
          <h3>Earn Rewards</h3>
          <p>Join Soxy Rewards club to get cashback and discounts from your all your favorite brands.</p>
        </div>
      </div>
    </div>
    <div class="numbers-section container">
      <div class="row">
        <div class="col-12">
          <div class="category-separator">
            <p class="category-text">Our story in numbers</p>
            <div class="separator"></div>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <h4>2014</h4>
          <p>Soxy was founded in Toronto, Canada</p>
        </div>
        <div class="col-6 col-md-4">
          <h4>2M</h4>
          <p>Monthly Shoppers</p>
        </div>
        <div class="col-6 col-md-4">
          <h4>1.5M</h4>
          <p>Products</p>
        </div>
        <div class="col-6 col-md-4">
          <h4>1.500+</h4>
          <p>Number of Merchants</p>
        </div>
        <div class="col-6 col-md-4">
          <h4>35.000</h4>
          <p>Number of Brands</p>
        </div>
        <div class="col-6 col-md-4">
          <h4>22</h4>
          <p>Number of Employees</p>
        </div>
      </div>
    </div>
    <div class="brands-section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="category-separator">
              <p class="category-text">Brands</p>
              <div class="separator"></div>
            </div>
          </div>
          <div class="col-12">
            <table class="desktop">
              <colgroup>
                <col span="1" style="width: 20%;">
                <col span="1" style="width: 20%;">
                <col span="1" style="width: 20%;">
                <col span="1" style="width: 20%;">
                <col span="1" style="width: 20%;">
              </colgroup>
              <tbody><tr>
                <td><div><a href="https://soxy.com/shop/givenchy"><img src="@/assets/images/aboutUs/Givenchy.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/dior"><img src="@/assets/images/aboutUs/dior.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/balenciaga"><img src="@/assets/images/aboutUs/balenciaga.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/balmain"><img src="@/assets/images/aboutUs/balmain.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/alexander-wang"><img src="@/assets/images/aboutUs/alexanderwang.png" alt="images"></a></div></td>
              </tr>
              <tr>
                <td><div><a href="https://soxy.com/shop/dolce-gabbana"><img src="@/assets/images/aboutUs/dolce&gabbana.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/amiri"><img src="@/assets/images/aboutUs/amiri-1 1.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/canada-goose"><img src="@/assets/images/aboutUs/canada%20goose.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/fendi"><img src="@/assets/images/aboutUs/fendi.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/chloe"><img src="@/assets/images/aboutUs/chloe.png" alt="images"></a></div></td>
              </tr>
              <tr>
                <td><div><a href="https://soxy.com/shop/gucci"><img src="@/assets/images/aboutUs/gucci.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/hermes"><img src="@/assets/images/aboutUs/hermes.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/acne-studios"><img src="@/assets/images/aboutUs/Acne%20Studios.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/balmain"><img src="@/assets/images/aboutUs/balmain.png" alt="images"></a></div></td>
                <td><div><a href="https://soxy.com/shop/fear-of-god"><img src="@/assets/images/aboutUs/fear-of-god.png" alt="images"></a></div></td>
              </tr>
              </tbody></table>
            <table class="mobile">
              <colgroup>
                <col span="1" style="width: 50%;">
                <col span="1" style="width: 50%;">
              </colgroup>
              <tbody>
              <tr>
                <td><div><img src="@/assets/images/aboutUs/Givenchy.png" alt="images"></div></td>
                <td><div><img src="@/assets/images/aboutUs/dior.png" alt="images"></div></td>
              </tr>
              <tr>
                <td><div><img src="@/assets/images/aboutUs/balenciaga.png" alt="images"></div></td>
                <td><div><img src="@/assets/images/aboutUs/balmain.png" alt="images"></div></td>
              </tr>
              <tr>
                <td><div><img src="@/assets/images/aboutUs/dolce&gabbana.png" alt="images"></div></td>
                <td><div><img src="@/assets/images/aboutUs/amiri-1 1.png" alt="images"></div></td>
              </tr>
              <tr>
                <td><div><img src="@/assets/images/aboutUs/gucci.png" alt="images"></div></td>
                <td><div><img src="@/assets/images/aboutUs/hermes.png" alt="images"></div></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-section container">
      <div class="row">
        <div class="col-12">
          <div class="category-separator">
            <p class="category-text">Get in touch with us</p>
            <div class="separator"></div>
          </div>
          <p>Are you a brand that wants to learn more about working with us?</p>
          <p>You can email <a href="mailto:partners@soxy.com">partners@soxy.com</a></p>
          <p>For all other inquiries please email <a href="mailto:hello@soxy.com">hello@soxy.com</a></p>
        </div>
      </div>
    </div>
  </div>
  <FooterMain/>
</template>

<style scoped>

</style>
