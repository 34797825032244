<script>
import {API_BASE_URL} from "@/components/config/config";
import {useAuthStore} from "@/stores/auth";
import axios from "axios";

export default {
  name: "JoinPopup",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    showJoinPopup: {
      type: Boolean,
      default: false
    },
    productId: {
      type: Number,
      required: true
    }
  },
  emits: ['closePopup'],
  data() {
    return {
      cartVisible: false,
      email: '',
      success: false,
    }
  },
  computed: {
    isValidEmail() {
      const emailPattern = /\w+@\w+\.\w+/;
      return emailPattern.test(this.email);
    }
  },
  methods: {
    visiblePopUp() {
      this.success = false;
      this.$emit('closePopup');
    },

    async trackDeals() {
      if (!this.isValidEmail) {
        console.error('Invalid email format');
        return;
      }

      try {
        const authStore = useAuthStore();
        authStore.loadToken();
        const token = authStore.token;

        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const response = await axios.post(`${API_BASE_URL}/favorite/add`, {
          product_id: this.productId,
          email: this.email
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Track deals response:', response);
        this.success = true;

        // Emit the event to notify the parent component
        this.$emit('favoriteAdded');
      } catch (error) {
        console.error('Error tracking deals:', error);
      }
    },
    handleBeforeLeave(el) {
      el.style.animation = 'fadeOut 0.3s forwards';
    }
  }
}
</script>

<template>
  <transition name="fade" @before-leave="handleBeforeLeave">
    <div class="overlay_popup_join" @click="visiblePopUp" v-if="showJoinPopup">
      <transition name="modal-fade" @before-leave="handleBeforeLeave">
    <div class="modal-container" @click.stop>
      <div class="close-menu-wrapper" @click="visiblePopUp">
        <img src="../../assets/icons/cross.svg" style="width: 20px; height: 20px;" class="close-mobile-menu" id="close-mobile-menu">
      </div>
      <p v-if="!success" class="titleWishlist">Want to know when price will change?</p>
      <p v-if="!success" class="subtitleWishlist">Join Soxy to save all your favorites</p>
      <form>
        <div v-if="!success" class="inputWishlistContainer">
          <div style="display: flex; flex-direction: column">
            <input class="inputWishlist" type="text" name="email" id="email" placeholder="Enter your email address" v-model="email" required>
            <span v-if="!isValidEmail && email.length > 0" style="color: red;">Invalid email format</span>
            <span v-else-if="isValidEmail && email.length > 0" style="color: green;">Success</span>
          </div>
          <div class="button" :class="{ disabled: !isValidEmail }" @click="trackDeals" :disabled="!isValidEmail">Track Deals</div>
        </div>
      </form>
      <div v-if="!success" class="signInWishlistContainer">
        <p class="descriptionAlready">Already have an account?</p>
        <p class="signIn"  @click="visiblePopUpWelcome">Sign in here</p>
      </div>
      <div v-else class="successMessage">
        <p class="successTitle">Success!</p>
        <p class="successSubtitle">You will be notified about price updates on this product.</p>
        <div class="buttonSuccess" @click="visiblePopUp">Close</div>
      </div>
    </div>
      </transition>
  </div>
  </transition>
</template>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active, .modal-fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.modal-fade-enter, .modal-fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.close-menu-wrapper{
  position: relative;
  left: calc(55% - 30px);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
  padding-bottom: 20px;
}
.successMessage {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.successTitle {
  font-family: 'Zodiak';
  font-size: 32px;
  font-weight: 540;
  margin-bottom: 0.5em;
}
.successSubtitle {
  font-size: 16px;
  font-weight: 400;
  width: 353px;
  padding-bottom: 44px;
  text-align: center;
}
.buttonSuccess{
  border: none;
  background: #111;
  padding: 0.875em .3em;
  color: white;
  display: flex;
  width: 200px;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.overlay_popup_join {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s forwards;
}

.inputWishlist{
  height: 52px;
  border: solid 1px #000;
  padding-left: 20px;
  padding-right: 20px;
}

.modal-container {
  display: flex;
  padding: 40px;
  margin: 20px;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s forwards;
}

.inputWishlistContainer{
  display: flex;
  padding-bottom: 24px;
}

.signInWishlistContainer{
  display: flex;
  font-size: 14px;
  font-weight: 400;
  width: 276px;
}

.button {
  border: none;
  background: #111;
  padding: 0.875em .3em;
  height: 31px;
  color: white;
  display: flex;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
}

.button:hover{
  text-decoration: underline;
}

.button.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.titleWishlist{
  font-family: 'Zodiak';
  font-size: 32px;
  font-weight: 540;
  display: flex;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.subtitleWishlist{
  font-size: 16px;
  font-weight: 400;
  width: 70%;
  padding-bottom: 44px;
  text-align: center;
}

.descriptionAlready{
  padding-right: 5px;
  color: #8B8B8B;
}
.signIn{
  cursor: pointer;
}

.signIn:hover{
  text-decoration: underline;
}
</style>
