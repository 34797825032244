import { defineStore } from 'pinia';

export const useOrderStore = defineStore('order', {
    state: () => ({
        orderId: '',
        firstName: '',
        lastName: '',
        email: '',
    }),
    actions: {
        setOrderId(orderId) {
            this.orderId = orderId;
        },
        setOrderDetails(details) {
            this.firstName = details.firstName;
            this.lastName = details.lastName;
            this.email = details.email;
        },
        setFirstName(firstName) {
            this.firstName = firstName;
        },
        setLastName(lastName) {
            this.lastName = lastName;
        },
        setEmail(email) {
            this.email = email;
        }
    },
    getters: {
        getOrderId: state => state.orderId,
        orderDetails: (state) => state
    }
});
