<script>
import FooterMain from "@/components/main/FooterMain.vue";
import HeaderMain from "@/components/main/HeaderMain.vue";
import { useOrderStore } from "@/stores/useOrderStore";
import {useAuthStore} from "@/stores/auth";
import axios from "axios";
import { API_BASE_URL } from '@/components/config/config';

export default {
  name: "CheckoutPage",
  components: {HeaderMain, FooterMain},
  data() {
    return {
      cartItems: [],
      menu: [
        {id: 1, label: "WOMEN", url: "shop/women"},
        {id: 2, label: "MEN", url: "shop/men"},
        {id: 3, label: "SHOES", url: "shop/womens-shoes"},
        {id: 1, label: "BAGS", url: "shop/womens-bags"},
        {id: 2, label: "KIDS", url: "shop/kids"},
        {id: 3, label: "HOME & LIVING", url: "shop/home-living"},
        {id: 1, label: "BEAUTY", url: "shop/womens-beauty-products"},
      ],
      firstName: '',
      lastName: '',
      email: '',

      address: '',
      state: '',
      city: '',
      zip: '',
      phone: ''
    }
  },
  created() {
    this.fetchCartData();
  },
  computed: {
    isValidForm() {
      return this.firstName && this.lastName && this.email && this.address && this.state && this.city && this.zip && this.phone ;
    },
    subtotal() {
      return this.cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);
    },
    total() {
      return (parseFloat(this.subtotal)).toFixed(2);
    },
    isValidEmail() {
      const emailPattern = /\w+@\w+\.\w+/;
      return emailPattern.test(this.email);
    }
  },
  methods: {
    updateProductsByMenu(menuItemUrl) {
      console.log("Menu item URL clicked:", menuItemUrl);
      this.$emit('updateProductsByMenu', menuItemUrl);
      this.$router.push(`/${menuItemUrl}`);
    },
    async fetchCartData() {
      const authStore = useAuthStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error('No authentication token found');
        return;
      }

      try {
        const response = await axios.get(`${API_BASE_URL}/cart/view`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log(response.data.data);
        this.cartItems = response.data.data;
        console.log(this.cartItems);
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    },
    validateAndSubmit() {
      if (this.firstName && this.lastName && this.isValidEmail && this.address && this.state && this.city && this.zip && this.phone) {
        const orderStore = useOrderStore();
        // sessionStorage.setItem('firstName', this.firstName);
        // sessionStorage.setItem('lastName', this.lastName);
        // sessionStorage.setItem('email', this.email);
        orderStore.setOrderDetails({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        });
        // this.createOrder();
        this.$router.push({ path: '/payment' });
      } else {
        console.log('Please fill in all required fields.');
      }
    },
  }
}
</script>

<template>
  <HeaderMain
      :menu="menu"
      @update-products-by-menu="updateProductsByMenu"
  />
  <div>
    <div class="page-wrapper">
      <div>
        <h1 class="checkout-title" id="cart-title">Checkout</h1>
        <div class="mobile">
          <a @click="this.$router.push({ path: '/cart'})" class="return-link">
            <img  src="../../assets/icons/chevron-left.svg" alt="chevron-alt">
            <span>Return to cart</span>
          </a>
        </div>
        <div class="checkout-content">
          <div class="checkout-form">
            <p>
              <span>shipping details</span>
              <img  src="../../assets/icons/chevron-right.svg" alt="">
              <span>Payment</span>
            </p>
            <form>
              <div class="input-wrapper">
                <label for="first-name">First Name*</label>
                <input v-model="firstName" id="first-name" name="first-name" type="text" placeholder="First Name" required>
              </div>
              <div class="input-wrapper">
                <label for="last-name">Last Name*</label>
                <input v-model="lastName" type="text" id="last-name" name="last-name" placeholder="Last Name" required>
              </div>
              <div class="input-wrapper">
                <label for="email">Email*</label>
                <input v-model="email" type="text" name="email" id="email" placeholder="Email" required>
                <span v-if="!isValidEmail && email.length > 0" style="color: red;">Invalid email format</span>
                <span v-else-if="isValidEmail && email.length > 0" style="color: green;">Success</span>
              </div>
              <div class="input-wrapper">
                <label for="company-name">Company name (optional)</label>
                <input type="text" placeholder="Company name" id="company-name" name="company-name">
              </div>
              <div class="input-wrapper two-column-input">
                <label  for="address-line-1">Address Line 1*</label>
                <input v-model="address" type="text" placeholder="Address Line 1" name="address-line-1" id ="address-line-1" required>
              </div>
              <div class="input-wrapper two-column-input">
                <label for="address-line-2">Address Line 2 (optional)</label>
                <input type="text" placeholder="Address Line 2" name="address-line-2" id="address-line-2">
              </div>
              <div class="input-wrapper">
                <label for="country">Country</label>
                <input type="text" placeholder="Country" id="country" name="country">
              </div>
              <div class="input-wrapper">
                <label for="state-province">State / Province*</label>
                <input v-model="state" type="text" placeholder="State / Province" name="state-province" id="state-province" required>
              </div>
              <div class="input-wrapper">
                <label for="town-city">Town / City*</label>
                <input v-model="city" type="text" placeholder="Town / City" id="town-city" name="town-city" required>
              </div>
              <div class="input-wrapper">
                <label for="zip-postcode">ZIP / Postcode*</label>
                <input v-model="zip" type="text" placeholder="ZIP / Postcode" id="zip-postcode" name="zip-postcode" required>
              </div>
              <div class="input-wrapper two-column-input">
                <label for="phone-number">Phone Number*</label>
                <input v-model="phone" type="text" placeholder="Phone Number" id="phone-number" name="phone-number" required>
              </div>
              <div class="form-control two-column-input desktop">
                <div>
                  <a @click="this.$router.push({ path: '/cart'})" class="return-link">
                    <img  src="../../assets/icons/chevron-left.svg" alt="chevron-alt">
                    <span>Return to cart</span>
                  </a>
                </div>
<!--                <input class="button" type="button" value="Continue to payment">-->
                <button to="/payment" style="text-decoration: none" class="button" @click="validateAndSubmit" type="submit">Continue to payment</button>
              </div>
            </form>
          </div>
          <div >
            <p class="summary-title">SUMMARY</p>
            <div v-for="item in cartItems" :key="item.product_id" id="checkout-summary-content">
              <div class="summary-item"><img :src="item.product_image" alt="item-image">
                <div><p class="name">{{item.product_name}}</p>
                  <p>{{item.product_brand}}</p>
                  <div class="item-parameters">
                    <p><span class="name">Color:</span><span class="value">{{item.color}}</span></p>
                    <p><span class="name">Size:</span><span class="value">{{ item.size_name }}</span></p>
                    <p><span class="name">Qty:</span><span class="value">{{item.quantity}}</span></p>
                  </div>
                  <p class="price">${{ item.price }}</p></div>
              </div>
              <div class="summary-separator"></div>
            </div>
            <div id="checkout-summary-content">

            </div>
            <div class="summary-info">
              <div>
                <p class="name">Subtotal</p>
                <p class="price" id="subtotal">${{subtotal}}</p>
              </div>
              <div>
                <p class="name">Handling & duties</p>
                <p class="price">$0</p>
              </div>
              <div>
                <p class="name">Shipping</p>
                <p class="price">$0</p>
              </div>
              <div>
                <p class="name">Tax</p>
                <p class="price">$0</p>
              </div>
            </div>
            <div class="summary-total">
              <p>Total</p>
              <p id="total">${{subtotal}}</p>
            </div>
            <div class="mobile">
<!--              <button class="button" type="button">Continue to payment</button>-->
              <button to="/payment" class="button" :disabled="!isValidForm"  @click="validateAndSubmit" type="submit">Continue to payment</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="additional-info">
        <div>
          <div class="additional-item">
            <div>
              <img  src="../../assets/icons/bank-card.svg" alt="bank-card">
            </div>
            <h1 class="title">Secure Shopping</h1>
            <p class="text">
              We use stripe as a payment processor, which meets the credit
              card industry's rigorous PCI security standards
            </p>
          </div>
          <div class="additional-item">
            <div>
              <img  src="../../assets/icons/shield.svg" alt="shield">
            </div>
            <h1 class="title">Privacy Protection</h1>
            <p class="text">Your privacy and security is our number-one priority</p>
          </div>
          <div class="additional-item">
            <div>
              <img  src="../../assets/icons/message.svg" alt="message">
            </div>
            <h1 class="title">Best Customer Support</h1>
            <p class="text">
              Our friendly customer support team is always available
              to help answer any questions you may have
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterMain/>
</template>

<style scoped>
.checkout-title {
  font-size: 2em;
  margin-top: 2em;
  margin-bottom: 2.275em;
}
.input-error input {
  border: 1px solid red;
}
.checkout-content {
  display: grid;
  grid-template-columns: 1fr 21.25em;
  gap: 10em;
  margin-bottom: 6.25em;
}

.checkout-form > p > span {
  font-size: 0.875em;
  text-transform: uppercase;
}

.checkout-form > p > img {
  height: 1.25em;
  width: 1.25em;
}

.checkout-form > p > span:first-child {
  font-weight: 500;
  color: #111;
}

.checkout-form > p > span:last-child {
  font-weight: 400;
  color: #8D8D8D;
}

.checkout-form > p {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.checkout-form > form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5em 2em;
}

.checkout-form > form input {
  width: 100%;
  font-size: 0.875em;
  padding: 0.875em 1em;
  box-sizing: border-box;
  border: 1px solid #DCDCDC;
  outline: none;
}

.checkout-form > form label {
  margin-bottom: 0.5em;
  display: block;
  font-size: 0.85em;
}

.two-column-input {
  grid-column: 1 / 3;
}

.summary-title {
  border: none;
}

.summary-item img {
  width: 3.75em;
  height: 5.75em;
}

.summary-item {
  display: flex;
  gap: 1em;
}

.summary-separator {
  height: 1px;
  background: #DCDCDC;
  margin: 1.25em 0;
}

.summary-info {
  padding-top: 0;
}

.summary-item > div > p, .summary-item > div > p > span {
  font-size: 0.875em;
  font-weight: 400;
}

.summary-item .name {
  font-weight: 500;
  margin-bottom: 0.25em;
}

.summary-item > div > .item-parameters {
  display: flex;
  gap: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.75em;
}

.summary-item > div > .item-parameters span {
  font-size: 0.875em;
}

.summary-item > div > .item-parameters .name {
  color: #8B8B8B;
  padding-right: 0.35em;
}

.summary-item .price {
  font-weight: 500;
}

.form-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5em;
}

.form-control input {
  max-width: 17.75em;
  font-size: 1em;
  font-weight: 500;
}

.return-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1em;
  font-weight: 500;
  gap: 0.5em;
  cursor: pointer;
}

.return-link span {
  font-size: 1em;
}

.return-link img {
  height: 1.5em;
  width: 1.5em;
}

header.header {
  padding-bottom: 0;
  margin-bottom: 2.5em;
}

@media (max-width: 990px) {
  .checkout-title {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .checkout-content {
    display: block;
  }

  .checkout-form > p > span {
    font-size: 14px;
  }

  .checkout-form > p > img {
    height: 20px;
    width: 20px;
  }

  .checkout-form > form {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .two-column-input {
    grid-column: 1
  }

  .checkout-form > p {
    gap: 10px;
    margin-bottom: 40px;
  }

  .checkout-form > form label {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .checkout-form > form input {
    font-size: 14px;
    padding: 14px 16px;
  }

  .return-link {
    justify-content: start;
    margin-bottom: 32px;
    gap: 8px;
  }

  .return-link img {
    height: 24px;
    width: 24px;
  }

  .return-link span {
    font-size: 16px;
  }

  .page-wrapper {
    display: block;
    padding: 0 16px;
  }

  .page-wrapper div {
    width: 100%;
  }

  .summary-item img {
    width: 76px;
    height: 92px;
  }

  .summary-separator {
    margin: 24px 0;
  }

  .summary-item > div > p, .summary-item > div > p > span {
    font-size: 14px;
  }

  .summary-item .name {
    margin-bottom: 4px;
  }

  .summary-item > div > .item-parameters {
    margin-top: 8px;
    margin-bottom: 12px;
    gap: 16px;
  }

  .summary-item > div > .item-parameters span {
    font-size: 14px;
    font-weight: 300;
  }

  .summary-item {
    gap: 16px;
  }

  .button {
    width: 100%;
    padding: 14px 0;
    font-size: 14px;
    margin-bottom: 120px;
  }

  .button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .summary-title {
    margin-top: 40px;
  }

  .question .question-wrapper {
    height: 20px;
    width: 20px;
  }

  .question .question-wrapper img {
    height: 20px;
    width: 20px;
  }

  div.question {
    margin-top: -32px;
    margin-left: -12px;
  }

  p.summary-title {
    padding-bottom: 0;
  }

  .additional-item > div {
    margin-bottom: 16px;
  }

}
</style>
