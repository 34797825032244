import { defineStore } from 'pinia';

export const useCartStore = defineStore({
    id: 'cart',
    state: () => ({
        cartItems: [],
        cartVisible: false,
    }),
    actions: {
        setCartItems(items) {
            this.cartItems = items;
        },
        updateCartItemQuantity(row_id, newQuantity) {
            const itemToUpdate = this.cartItems.find(item => item.row_id === row_id);
            if (itemToUpdate) {
                itemToUpdate.quantity = newQuantity;
            }
        },
        addToCart(item) {
            this.cartItems.push(item);
        },
        toggleCartVisibility() {
            this.cartVisible = !this.cartVisible;
        },
        // removeCartItemByRowId(row_id) {
        //     this.cartItems = this.cartItems.filter(item => item.row_id !== row_id);
        // }
    },
    getters: {
        cartSubtotal: state => {
            if (state.cartItems.length === 0) {
                return 0; // Return 0 if cart is empty
            }

            // Calculate subtotal by summing up price * quantity for each item
            return state.cartItems.reduce((acc, item) => {
                // Ensure price and quantity are valid numbers before calculation
                const itemPrice = parseFloat(item.price);
                const itemQuantity = parseInt(item.quantity);

                if (!isNaN(itemPrice) && !isNaN(itemQuantity)) {
                    return acc + (itemPrice * itemQuantity);
                } else {
                    return acc; // Skip invalid items
                }
            }, 0).toFixed(2); // Ensure the subtotal is formatted to 2 decimal places
        },
    }
});
