import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia';
import router from './router'
// import clientInit from './clientInit'

const app = createApp(App);
const pinia = createPinia();

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        if (to.params.id) {
            document.title = `${to.params.id} - ${to.meta.title}`;
        } else {
            document.title = to.meta.title;
        }
    } else {
        document.title = 'Default Title';
    }
    next();
});

app.use(router);


// clientInit();

app.use(pinia);
app.mount('#app')
