<template>
  <div v-if="isLoading" class="loading-overlay">
    <div class="spinner"></div>
  </div>
  <div v-if="!isLoading" class="order_status_block">
    <div class="content">
      <p style="color: #8B8B8B;">Your order <span class="order-id">{{ orderId }}</span> has been completed.</p>
      <br/><br/>
      <p style="color: #8B8B8B;">We have received your order and are working directly with the merchant to ensure your product is shipped and delivered to you smoothly. We will update you via email in 24-48 hours. If you have any questions you can reach our team at help@soxy.com</p>
      <router-link to="/shop" class="button cart-button">Shop What's New</router-link>
    </div>
  </div>
</template>

<script>
import { useOrderStore } from "@/stores/useOrderStore";
import { useAuthStore } from "@/stores/auth";
import axios from "axios";
import { API_BASE_URL } from '@/components/config/config';

export default {
  name: "OrderPage",
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    orderId() {
      return this.orderStore.orderId;
    },
    firstName() {
      return this.orderStore.firstName;
    },
    lastName() {
      return this.orderStore.lastName;
    },
    email() {
      return this.orderStore.email;
    }
  },
  created() {
    this.orderStore = useOrderStore();
    this.loadUserDataFromStore();
    this.setOrderId();
    this.orderComplete();
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  },
  methods: {
    setOrderId() {
      const orderId = this.orderStore.orderId;
      console.log(orderId, 'O1');
      if (orderId) {
        this.orderStore.setOrderId(orderId);
      } else {
        console.warn('No orderId found in store');
      }
    },
    async orderComplete() {
      const authStore = useAuthStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error('No authentication token found');
        return;
      }

      try {
        const response = await axios.post(`${API_BASE_URL}/order/complete`, {
              orderId: this.orderId,
              first_name: this.firstName,
              last_name: this.lastName,
              email: this.email
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
        );
        console.log(response.data);
        this.orderStore.setOrderId(response.data.orderId);
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    },
    loadUserDataFromStore() {
      const { firstName, lastName, email } = this.orderStore.orderDetails;
      this.orderStore.setFirstName(firstName);
      this.orderStore.setLastName(lastName);
      this.orderStore.setEmail(email);
    }
  }
};
</script>


<style scoped>
.order_status_block {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  text-align: center;
  color: #8B8B8B;
}
.cart-button {
  display: flex;
  text-decoration: none;
  align-items: center;
  margin-top: 40px;
  align-content: center;
  flex-direction: column;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-id {
  color: #000;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #545454;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
