import { defineStore } from 'pinia';
import axios from 'axios';
import { API_BASE_URL } from '@/components/config/config';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: sessionStorage.getItem('token') || null,
        gclid: sessionStorage.getItem('gclid') || null
    }),
    actions: {
        async clientInit() {
            try {
                const response = await axios.post(`${API_BASE_URL}/client/init`, 'query=colors=&stores=&sort=Newest', {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });

                const { token } = response.data;

                if (token) {
                    this.token = token;
                    sessionStorage.setItem('token', token);

                }
            } catch (error) {
                console.error('Error:', error);
            }
        },
        loadToken() {
            this.token = sessionStorage.getItem('token');
        },
        setGclid(gclid) {
            this.gclid = gclid;
            sessionStorage.setItem('gclid', gclid);
        },
        clearGclid() {
            this.gclid = null;
            sessionStorage.removeItem('gclid');
        }
    }
});
