<script>
import JoinPopup from "@/components/popup/JoinPopup.vue";

export default {
  name: "WelcomePopup",
  components: {JoinPopup},
  data() {
    return {
      cartVisible: false,
      email: '',
      password: '',
      success: false,
      showJoinPopup: false,
    }
  },
  props: {
    showWelcomePopup: {
      type: Boolean,
      default: false
    },
  },
  emits: ['closePopup'],
  computed: {
    isValidEmail() {
      const emailPattern = /\w+@\w+\.\w+/;
      return emailPattern.test(this.email);
    },
    isValidPassword() {
      const passwordPattern = /^.{6,}$/;
      return passwordPattern.test(this.password);
    },
  },
  methods: {
    visiblePopUp() {
      this.success = false;
      this.$emit('closePopup');
    },
    visiblePopUpJoin(){
      this.showJoinPopup = true;
    },
    hideJoinPopup() {
      this.showJoinPopup = false;
    },
    handleBeforeLeave(el) {
      el.style.animation = 'fadeOut 0.3s forwards';
    }
  }
}

</script>

<template>
  <JoinPopup :showJoinPopup="showJoinPopup" @closePopup="hideJoinPopup"/>
  <transition name="fade" @before-leave="handleBeforeLeave">
    <div class="overlay_popup_welcome" @click="visiblePopUp" v-if="showWelcomePopup">
      <transition name="modal-fade" @before-leave="handleBeforeLeave">
      <div class="modal-container" @click.stop>
        <div class="close-menu-wrapper" @click="visiblePopUp">
          <img src="../../assets/icons/cross.svg" style="width: 20px; height: 20px;" class="close-mobile-menu" id="close-mobile-menu" >
        </div>
        <p v-if="!success" class="titleWishlist">Welcome Back</p>
        <p v-if="!success" class="subtitleWishlist">Sign in to below to continue building your wishlist</p>
        <form>
          <div v-if="!success" class="inputWishlistContainer">
            <div style="display: flex; flex-direction: column">
              <input class="inputWishlist" type="text" name="email" placeholder="Enter your email address" v-model="email" required>
              <span v-if="!isValidEmail && email.length > 0" style="color: red;">Invalid email format</span>
              <span v-else-if="isValidEmail && email.length > 0" style="color: green;">Email success</span>
            </div>
          </div>
        </form>

        <form>
          <div v-if="!success" class="inputWishlistContainer">
            <div style="display: flex; flex-direction: column">
              <input class="inputWishlist" type="password" name="email" placeholder="Enter your password" v-model="password" required>
              <span v-if="!isValidPassword && password.length > 0" style="color: red;">The password is short</span>
              <span v-else-if="isValidPassword && password.length > 0" style="color: green;">Password success</span>
            </div>
          </div>
        </form>

        <div v-if="!success" class="signInWishlistContainer">
          <p class="descriptionAlready">Already have an account?</p>
          <p class="signIn" @click="visiblePopUpJoin">Sign up</p>
        </div>
        <div v-else class="successMessage">
          <p class="successTitle">Success!</p>
          <p class="successSubtitle">You will be notified about price updates on this product.</p>
          <div class="buttonSuccess" @click="visiblePopUp">Close</div>
        </div>
        <div class="button">Login</div>
      </div>
      </transition>
    </div>
  </transition>
</template>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active, .modal-fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.modal-fade-enter, .modal-fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.close-menu-wrapper{
  position: relative;
  left: calc(55% - 30px);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
}
  .successMessage {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
  .successTitle {
    font-family: 'Zodiak';
    font-size: 32px;
    font-weight: 540;
    margin-bottom: 0.5em;
  }
  .successSubtitle {
    font-size: 16px;
    font-weight: 400;
    width: 353px;
    padding-bottom: 44px;
    text-align: center;
  }
  .buttonSuccess{
    border: none;
    background: #111;
    padding: 0.875em .3em;
    color: white;
    display: flex;
    width: 200px;
    font-size: 13px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  .overlay_popup_welcome {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s forwards;
  }

  .inputWishlist{
    width: 290px;
    height: 52px;
    border: solid 1px #000;
    padding-left: 20px;
  }

  .modal-container {
    display: flex;
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s forwards;
  }

  .inputWishlistContainer{
    display: flex;
    padding-bottom: 24px;
  }

  .signInWishlistContainer{
    display: flex;
    font-size: 14px;
    font-weight: 400;
    width: 276px;
  }

  .button {
    border: none;
    background: #111;
    margin-top: 20px;
    padding: 0.875em .3em;
    height: 21px;
    width: 300px;
    color: white;
    display: flex;
    font-size: 13px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    align-content: center;
  }

  .button:hover{
    text-decoration: underline;
  }

  .titleWishlist{
    font-family: 'Zodiak';
    font-size: 32px;
    font-weight: 540;
    padding-bottom: 12px;
  }

  .subtitleWishlist{
    font-size: 16px;
    font-weight: 400;
    width: 353px;
    padding-bottom: 44px;
    text-align: center;
  }

  .descriptionAlready{
    padding-right: 5px;
    color: #8B8B8B;
  }
  .signIn{
    cursor: pointer;
  }

  .signIn:hover{
    text-decoration: underline;
  }
</style>
