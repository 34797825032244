<script>
export default {
  name: "FooterMain"
}
</script>

<template>
  <footer class="footer" id="footer">
    <div class="footer-wrapper">
      <div>
        <img class="footer-logo" src="../../assets/icons/footer-logo.svg" alt="footer logo">
        <p class="subscribe-text">Subscribe to our newsletter</p>
        <div class="subscribe-input-wrapper">
          <input type="text" placeholder="Insert your email">
          <button>Subscribe</button>
        </div>
      </div>
      <div>
        <nav class="footer-navigation">
          <div>
            <p class="footer-navigation-title">Shop</p>
            <p class="footer-navigation-item">Home</p>
            <p class="footer-navigation-item">Shop Men's</p>
            <p class="footer-navigation-item">Shop Women's</p>
            <p class="footer-navigation-item">Shop Kids</p>
            <p class="footer-navigation-item">Shop Home & Living</p>
          </div>
          <div>
            <p class="footer-navigation-title">Company</p>
            <p class="footer-navigation-item"><a href="https://soxy.com/blogs/about/" class="footer-navigation-item">About Us</a></p>
            <p class="footer-navigation-item"><a href="https://help.soxy.com/article/221-shipping-policy" class="footer-navigation-item">Shipping Policy</a></p>
            <p class="footer-navigation-item"><a href="https://help.soxy.com/article/222-return-policy" class="footer-navigation-item">Return Policy</a></p>
            <p class="footer-navigation-item"><a href="https://soxy.com/terms" class="footer-navigation-item">Terms of Service</a></p>
            <p class="footer-navigation-item"><a href="https://soxy.com/privacy" class="footer-navigation-item">Privacy Policy</a></p>
            <p class="footer-navigation-item"><a href="https://soxy.com/blogs/contact-us/" class="footer-navigation-item">Contact Us</a></p>
          </div>
          <div>
            <p class="footer-navigation-title">Contact</p>
            <p class="footer-navigation-item" href="mailto:help@soxy.com"><a class="footer-navigation-item" style="text-decoration: none" href="mailto:help@soxy.com">help@soxy.com</a></p>
          </div>
        </nav>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  background-color: #111111;
  padding: 5.375em /* 86px */ 0;
  display: flex;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
}

.footer-wrapper {
  width: 77.5em; /* 1240px */
  display: flex;
  justify-content: space-between;
}

.footer-logo {
  margin-bottom: 1.625em; /* 26px */
  height: 28px;
  width: 121px;
}

.subscribe-text {
  color: #FFF;
  font-size: 1.375em; /* 22px */
  margin-bottom: 1em;
}

.subscribe-input-wrapper {
  display: flex;
  gap: -1px;
}

.subscribe-input-wrapper input {
  padding: 1em 0 1.25em 0;
  width: 18.75em; /* 300px */
  border: none;
  outline: none;
  color: white;
  font-weight: 400;
  border-bottom: 1px solid white;
  background-color: #111111;
}

.subscribe-input-wrapper button {
  padding: 0.875em 2.5em;
  background-color: white;
  border: none;
}

.footer-navigation-title, .footer-navigation-item {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.footer-navigation-item {
  font-size: 0.875em;
  margin-top: 1.5em;
}

.footer-navigation-title {
  font-weight: 500;
}

.footer-navigation {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.4375em; /* 39px */
}

@media (max-width: 990px) {
  .footer-wrapper {
    display: block;
  }

  .footer {
    padding: 80px 16px;
  }

  .footer-logo {
    margin-bottom: 32px;
  }

  .subscribe-text {
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: 500;
  }

  .subscribe-input-wrapper input {
    padding: 14px 0 13px 0;
    font-size: 14px;
    width: 100%;
  }

  .subscribe-input-wrapper {
    flex-direction: column;
    gap: 24px;
    margin-bottom: 60px;
  }

  .footer-navigation {
    grid-template-columns: 1fr 1fr;
  }

  .footer-navigation-title {
    font-size: 16px;
    font-weight: 900;
  }

  .footer-navigation-item{
    font-size: 14px;
    font-weight: 400;
    margin-top: 24px;
  }

  .subscribe-input-wrapper button {
    font-size: 14px;
    padding: 14px 0;
  }

  .empty-cart img {
    height: 72px;
    width: 72px;
  }

  .empty-cart > div > div {
    margin-bottom: 20px;
  }

  .add-items-to-cart {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .cart-button {
    width: 100%;
    padding: 14px 0;
    font-size: 14px;
    margin-bottom: 120px;
  }

  .footer-wrapper {
    width: 100%;
  }
}
</style>
