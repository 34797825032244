<script>
import HeaderMain from "@/components/main/HeaderMain.vue";
import FooterMain from "@/components/main/FooterMain.vue";
import {useAuthStore} from "@/stores/auth";
import { useOrderStore } from "@/stores/useOrderStore";
import { mask } from "vue-the-mask";
import axios from "axios";
import { API_BASE_URL } from '@/components/config/config';

export default {
  name: "PaymentPage",
  components: {FooterMain, HeaderMain},
  directives: { mask },
  created() {
    this.fetchCartData();
    this.orderStore = useOrderStore();

    // this.loadUserDataFromSessionStorage();
  },
  computed: {
    subtotal() {
      return this.cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);
    },

    total() {
      return (parseFloat(this.subtotal)).toFixed(2);
    },
    isValidEmail() {
      const emailPattern = /\w+@\w+\.\w+/;
      return emailPattern.test(this.email);
    },
    isValidForm() {
      return this.firstName && this.lastName && this.email && this.address && this.state && this.city && this.zip && this.phone;
    },
  },
  data() {
    return {
      menu: [
        {id: 1, label: "WOMEN", url: "shop/women"},
        {id: 2, label: "MEN", url: "shop/men"},
        {id: 3, label: "SHOES", url: "shop/womens-shoes"},
        {id: 1, label: "BAGS", url: "shop/womens-bags"},
        {id: 2, label: "KIDS", url: "shop/kids"},
        {id: 3, label: "HOME & LIVING", url: "shop/home-living"},
        {id: 1, label: "BEAUTY", url: "shop/womens-beauty-products"},
      ],
      cartItems: [],
      cardName: '',
      number: '',
      code: '',
      expired: '',
      orderId: '',

      firstName: '',
      lastName: '',
      email: '',
    }
  },
  methods: {
    updateProductsByMenu(menuItemUrl) {
      console.log("Menu item URL clicked:", menuItemUrl);
      this.$emit('updateProductsByMenu', menuItemUrl);
      this.$router.push(`/${menuItemUrl}`);
    },
    async fetchCartData() {
      const authStore = useAuthStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error('No authentication token found');
        return;
      }

      try {
        const response = await axios.get(`${API_BASE_URL}/cart/view`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log(response.data.data);
        this.cartItems = response.data.data;
        console.log(this.cartItems);
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    },

    async orderComplete() {
      const authStore = useAuthStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error('No authentication token found');
        return;
      }

      try {
        const response = await axios.post(`${API_BASE_URL}/order/complete`,
            {
              orderId: this.orderId,
              first_name: this.firstName,
              last_name: this.lastName,
              email: this.email
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
        );
        console.log(response.data);
        // this.complete = response.data.data;
        // console.log(this.complete);
        this.orderStore.setOrderId(response.data.orderId);
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    },
    loadUserDataFromSessionStorage() {
      this.firstName = sessionStorage.getItem('firstName') || '';
      this.lastName = sessionStorage.getItem('lastName') || '';
      this.email = sessionStorage.getItem('email') || '';
    },

    validateAndSubmit(){
      if (this.cardName && this.number && this.code && this.expired) {



        this.placeOrder();
        this.orderComplete();
        this.$router.push({ path: '/order' });
      } else {
        console.log('Please fill in all required fields.');
      }
    },

    async placeOrder() {
      const authStore = useAuthStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error('No authentication token found');
        return;
      }

      try {
        const response = await axios.post(
            `${API_BASE_URL}/order/create`,
            {
              quantity: 1,
              first_name: this.firstName,
              last_name: this.lastName,
              email: this.email
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
        );
        console.log('Order created:', response.data);
        // this.$router.push({path: '/success'});
      } catch (error) {
        console.error('Error creating order:', error);
      }
    }
  },
}
</script>

<template>
  <HeaderMain
      :menu="menu"
      @update-products-by-menu="updateProductsByMenu"
  />
  <div class="page-wrapper">
    <div>
      <h1 class="checkout-title" id="cart-title">Checkout</h1>
      <div class="mobile">
        <a class="return-link" @click="this.$router.push({ path: '/cart'})">
          <img src="../../assets/icons/chevron-left.svg" alt="chevron-alt">
          <span>Return to cart</span>
        </a>
      </div>
      <div class="checkout-content">
        <div class="checkout-form">
          <p>
            <span @click="this.$router.push({ path: '/checkout'})" style="cursor:pointer;">shipping details</span>
            <img src="../../assets/icons/chevron-right.svg" alt="">
            <span>Payment</span>
          </p>
          <form class="payment-form">
            <div class="cards two-column-input">
              <div class="credit-card">
                <div>
                  <img src="../../assets/icons/credit-card.svg">
                </div>
                <p>Credit Card</p>
              </div>
              <div class="cards-variants">
                <img src="../../assets/icons/visa.svg" alt="visa">
                <img src="../../assets/icons/mastercard.svg" alt="mastercard">
                <img src="../../assets/icons/maestro.svg" alt="maestro">
              </div>
            </div>
            <div class="input-wrapper two-column-input">
              <label for="card-number">Card number</label>
              <input v-model="number" v-mask="'#### #### #### ####'"  maxlength="19" placeholder="0000 0000 0000 0000" id="card-number" class="cc-number" name="card-number" required>
            </div>
            <div class="input-wrapper two-column-input">
              <label for="cardholder-name">Cardholder name</label>
              <input v-model="cardName" type="text" placeholder="Name on card" id="cardholder-name" name="cardholder-name" required>
            </div>
            <div class="input-wrapper">
              <label for="expiration-date">Expiration date</label>
              <input v-model="expired" v-mask="'## / ##'" type="tel" class="cc-expires" placeholder="MM / YY" id="expiration-date" name="expiration-date" required>
            </div>
            <div class="input-wrapper">
              <label for="security-code">Security code</label>
              <input v-model="code" type="tel" v-mask="'###'" class="cc-cvc" pattern="\d*" placeholder="CVV" id="security-code" name="security-code" required>
              <div class="question">
                <div class="question-wrapper"><img src="../../assets/icons/question.svg" alt=""></div>
              </div>
            </div>


          <div class="form-control two-column-input desktop">
            <div>
              <a @click="this.$router.push({ path: '/checkout'})" style="cursor:pointer;" class="return-link">
                <img src="../../assets/icons/chevron-left.svg" alt="chevron-alt">
                <span>Return to shipping</span>
              </a>
            </div>
            <button class="button" type="submit" @click="validateAndSubmit">Place order</button>
          </div>
          </form>
        </div>
        <div>
          <p class="summary-title">SUMMARY</p>
          <div v-for="item in cartItems" :key="item.product_id" id="checkout-summary-content">
            <div class="summary-item"><img :src="item.product_image" alt="item-image">
              <div><p class="name">{{item.product_name}}</p>
                <p>{{item.product_brand}}</p>
                <div class="item-parameters">
                  <p><span class="name">Color:</span><span class="value">{{item.color}}</span></p>
                  <p><span class="name">Size:</span><span class="value">{{ item.size_name }}</span></p>
                  <p><span class="name">Qty:</span><span class="value">{{item.quantity}}</span></p>
                </div>
                <p class="price">${{ item.price }}</p></div>
            </div>
            <div class="summary-separator"></div>
          </div>
          <div class="summary-info">
            <div>
              <p class="name">Subtotal</p>
              <p class="price" id="subtotal">${{ subtotal }}</p>
            </div>
            <div>
              <p class="name">Handling &amp; duties</p>
              <p class="price">$0</p>
            </div>
            <div>
              <p class="name">Shipping</p>
              <p class="price">$0</p>
            </div>
            <div>
              <p class="name">Tax</p>
              <p class="price">$0</p>
            </div>
          </div>
          <div class="summary-total">
            <p>Total</p>
            <p id="total">${{ subtotal }}</p>
          </div>
          <div class="mobile">
            <button class="button" @click="validateAndSubmit" type="button">Continue to payment</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile">
    <div class="additional-info">
      <div>
        <div class="additional-item">
          <div>
            <img src="../../assets/icons/bank-card.svg" alt="bank-card">
          </div>
          <h1 class="title">Secure Shopping</h1>
          <p class="text">
            We use stripe as a payment processor, which meets the credit
            card industry's rigorous PCI security standards
          </p>
        </div>
        <div class="additional-item">
          <div>
            <img src="../../assets/icons/shield.svg" alt="shield">
          </div>
          <h1 class="title">Privacy Protection</h1>
          <p class="text">Your privacy and security is our number-one priority</p>
        </div>
        <div class="additional-item">
          <div>
            <img src="../../assets/icons/message.svg" alt="message">
          </div>
          <h1 class="title">Best Customer Support</h1>
          <p class="text">
            Our friendly customer support team is always available
            to help answer any questions you may have
          </p>
        </div>
      </div>
    </div>
  </div>
  <FooterMain/>
</template>

<style scoped>
.checkout-form > p > span:last-child {
  color: #111111;
}

.payment-form {
  border: 1px solid #DCDCDC;
  background: #F7F7F7;
  padding: 2em 2.5em;
}

.form-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F7F7F7;
}

.credit-card > div {
  background-color: black;
  height: 1.5em;
  width: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
}

.credit-card p {
  font-size: 1.125em;
}

.credit-card {
  display: flex;
  gap: 0.75em;
  align-items: center;
}

.cards {
  display: flex;
  gap: 2.5em;
}

.cards-variants > img {
  width: 4.25em;
  height: 2.75em;
}

.cards-variants {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75em;
}

.form-control {
  margin-top: 2.5em;
  padding-top: 0;
}

.question-wrapper {
  height: 1.25em;
  width: 1.25em;
}

.question-wrapper > img {
  height: 1.25em;
  width: 1.25em;
}

.question {
  display: flex;
  justify-content: end;
  margin-top: -1.875em;
  margin-right: 0.75em;
}

.checkout-title {
  margin-bottom: 2.5rem;
  margin-top: 2em;
}

.credit-card > div > img {
  height: 1em;
  width: 1em;
}

@media (max-width: 990px) {
    .mobile {
      display: none !important;
    }
  .checkout-title {
    margin-bottom: 24px;
  }

  .credit-card > div {
    height: 24px;
    width: 24px;
    border-radius: 24px;
  }

  .credit-card > div > img {
    height: 16px;
    width: 16px;
  }

  .credit-card p {
    font-size: 18px;
  }

  .credit-card {
    gap: 12px;
    max-width: 145px;
  }

  .cards-variants > img {
    width: 46px;
    height: 30px;
  }

  .payment-form {
    padding: 32px 16px;
  }

  .cards-variants {
    gap: 12px;
  }

  .cards {
    gap: 19px;
    display: flex;
  }
}

.checkout-title {
  font-size: 2em;
  margin-bottom: 2.275em;
}

.checkout-content {
  display: grid;
  grid-template-columns: 1fr 21.25em;
  gap: 10em;
  margin-bottom: 6.25em;
}

.checkout-form > p > span {
  font-size: 0.875em;
  text-transform: uppercase;
}

.checkout-form > p > img {
  height: 1.25em;
  width: 1.25em;
}

.checkout-form > p > span:first-child {
  font-weight: 500;
  color: #111;
}

.checkout-form > p > span:last-child {
  font-weight: 400;
  color: #8D8D8D;
}

.checkout-form > p {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.checkout-form > form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5em 2em;
}

.checkout-form > form input {
  width: 100%;
  font-size: 0.875em;
  padding: 0.875em 1em;
  box-sizing: border-box;
  border: 1px solid #DCDCDC;
  outline: none;
}

.checkout-form > form label {
  margin-bottom: 0.5em;
  display: block;
  font-size: 0.85em;
}

.two-column-input {
  grid-column: 1 / 3;
}

.summary-title {
  border: none;
}

.summary-item img {
  width: 3.75em;
  height: 5.75em;
}

.summary-item {
  display: flex;
  gap: 1em;
}

.summary-separator {
  height: 1px;
  background: #DCDCDC;
  margin: 1.25em 0;
}

.summary-info {
  padding-top: 0;
}

.summary-item > div > p, .summary-item > div > p > span {
  font-size: 0.875em;
  font-weight: 400;
}

.summary-item .name {
  font-weight: 500;
  margin-bottom: 0.25em;
}

.summary-item > div > .item-parameters {
  display: flex;
  gap: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.75em;
}

.summary-item > div > .item-parameters span {
  font-size: 0.875em;
}

.summary-item > div > .item-parameters .name {
  color: #8B8B8B;
  padding-right: 0.35em;
}

.summary-item .price {
  font-weight: 500;
}

.form-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5em;
}

.form-control input {
  max-width: 17.75em;
  font-size: 1em;
  font-weight: 500;
}

.return-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1em;
  font-weight: 500;
  gap: 0.5em;
  cursor: pointer;
}

.return-link span {
  font-size: 1em;
}

.return-link img {
  height: 1.5em;
  width: 1.5em;
}

header.header {
  padding-bottom: 0;
  margin-bottom: 2.5em;
}

@media (max-width: 800px) {
  .checkout-title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .mobile {
    display: block !important;
  }
  .checkout-content {
    display: block;
  }

  .checkout-form > p > span {
    font-size: 14px;
  }

  .checkout-form > p > img {
    height: 20px;
    width: 20px;
  }

  .checkout-form > form {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .two-column-input {
    grid-column: 1
  }

  .checkout-form > p {
    gap: 10px;
    margin-bottom: 40px;
  }

  .checkout-form > form label {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .checkout-form > form input {
    font-size: 14px;
    padding: 14px 16px;
  }

  .return-link {
    justify-content: start;
    margin-bottom: 32px;
    gap: 8px;
  }

  .return-link img {
    height: 24px;
    width: 24px;
  }

  .return-link span {
    font-size: 16px;
  }

  .page-wrapper {
    display: block;
    padding: 0 16px;
  }

  .page-wrapper div {
    width: 100%;
  }

  .summary-item img {
    width: 76px;
    height: 92px;
  }

  .summary-separator {
    margin: 24px 0;
  }

  .summary-item > div > p, .summary-item > div > p > span {
    font-size: 14px;
  }

  .summary-item .name {
    margin-bottom: 4px;
  }

  .summary-item > div > .item-parameters {
    margin-top: 8px;
    margin-bottom: 12px;
    gap: 16px;
  }

  .summary-item > div > .item-parameters span {
    font-size: 14px;
    font-weight: 300;
  }

  .summary-item {
    gap: 16px;
  }

  .button {
    width: 100%;
    padding: 14px 0;
    font-size: 14px;
    margin-bottom: 120px;
  }

  .summary-title {
    margin-top: 40px;
  }

  .question .question-wrapper {
    height: 20px;
    width: 20px;
  }

  .question .question-wrapper img {
    height: 20px;
    width: 20px;
  }

  div.question {
    margin-top: -32px;
    margin-left: -12px;
  }

  p.summary-title {
    padding-bottom: 0;
  }

  .additional-item > div {
    margin-bottom: 16px;
  }

}
</style>
