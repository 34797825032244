<template>
  <HeaderMain
      :menu="menu"
      @update-products-by-menu="updateProductsByMenu"
  />
  <div class="sections-wrapper">
    <div class="sections">
      <div class="category-separator">
        <div v-if="isLoading" class="products-container">
          <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
        </div>
      <p class="category-text" v-if="!isLoading">{{sectionsGuide}}</p>
    </div>
      <section class="first-section">
        <div class="left-side desktop">
          <div v-for="content in contents" :key="content.id">
            <a style="text-decoration: none" :href="content.title_link">
              <div v-if="isLoading" class="products-container">
                <ImagePlaceholder style="height: 500px;" />
              </div>
              <img v-if="!isLoading" :src="content.image" alt="image">
              <div v-if="isLoading" class="products-container">
                <TextProductPlaceholder />
              </div>
              <p class="title-text" v-if="!isLoading">{{ content.category }}</p>
              <h1 class="section-text" v-if="!isLoading">{{ content.title }}</h1>
            </a>
          </div>
        </div>
        <div class="first-part mobile">
          <div id="first-section-wrapper"></div>
          <div id="first-section-content">
            <div class="images" id="images" style="transform: translate3d(0px, 0, -1px);">
              <div>
                <div v-for="content in contents" :key="content.id">
                  <a style="text-decoration: none" :href="content.title_link">
                    <div v-if="isLoading" class="products-container">
                      <ImagePlaceholder />
                    </div>
                    <img v-if="!isLoading" :src="content.image" alt="image">
                    <div v-if="isLoading" class="products-container">
                      <TextProductPlaceholder />
                    </div>
                    <p class="title-text" v-if="!isLoading">{{ content.category }}</p>
                    <h1 class="section-text" v-if="!isLoading">{{ content.title }}</h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="slider-control" id="slider-control">
          </div>
        </div>
        <div class="right-side">
          <div v-for="contentRight in contentsRightSide" :key="contentRight.id">

            <div>
              <a style="text-decoration: none" :href="contentRight.title_link">
                <div v-if="isLoading" class="products-container" style="position: relative; margin: 5px; width: 170px; height: 100%; background-color: #F7F7F7; border-radius: 10px;">
                  <TextProductPlaceholder />
                </div>
                <p  v-if="!isLoading">{{ contentRight.category }}</p>
                <h1  v-if="!isLoading" class="section-text">{{ contentRight.title }}</h1>
              </a>
            </div>
            <div v-if="isLoading" class="products-container" style="display: flex; width: 100%; height: 250px; flex-direction: row;">
              <ImagePlaceholder style="height: 200px; width: 250px;"/>
            </div>
            <img v-if="!isLoading" :src="contentRight.image" alt="image">
          </div>

        </div>
      </section>
      <div class="category-separator blockFashion">
        <div v-if="isLoading" class="products-container">
          <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
        </div>
        <p v-if="!isLoading" class="category-text">{{ sectionsFashion }}</p>
        <div class="separator"></div>
      </div>
    </div>
  </div>

  <!--  TUUUUUUT -->
  <section class="slider">
    <div class="slider-controls">
      <div class="slider-navigation" @click="slide(-1)">&#60;</div>
      <div class="slider-wrapper" :style="{ transform: 'translateX(' + translateValue + 'px)' }">
        <div class="slider-item" v-for="(contentsSlide, index) in contentsSlider" :key="index">
          <a style="text-decoration: none" :href="contentsSlide.title_link">
            <div v-if="isLoading" class="products-container" style="display: flex; width: 100%; height: 530px; flex-direction: row;">
            <ImagePlaceholder style="height: 500px; width: 300px;"/>
        </div>
            <img v-if="!isLoading" :src="contentsSlide.image" alt="image" class="sliderImg">
            <div class="slider-caption">
              <div v-if="isLoading" class="products-container" style="position: relative; margin: 5px; width: 320px; height: 100%; background-color: #F7F7F7; border-radius: 10px;">
                <StringProductPlaceholder style="padding-bottom: 10px;" />
              </div>
              <p v-if="!isLoading" class="title-text">{{ contentsSlide.category }}</p>
              <p v-if="!isLoading" class="product-name">{{ contentsSlide.title }}</p>
            </div>
          </a>
        </div>
      </div>
      <div class="slider-navigation" @click="slide(1)">&#62;</div>
    </div>
  </section>

  <div class="sections-wrapper blockFashion">
    <div class="sections">
      <div class="category-separator">
        <div v-if="isLoading" class="products-container">
          <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
        </div>
        <p v-if="!isLoading" class="category-text">{{ sectionTrendingProducts }}</p>
        <div class="separator"></div>
      </div>
      <section class="third-section">
        <div class="mobile mobile-scrolling-section-wrapper" id="third-section-wrapper"></div>
        <div class="mobile-scrolling-content third-section-content" id="third-section-scrolling-content">
          <div v-for="trendingProduct in trendingProducts" :key="trendingProduct.id">
            <a style="text-decoration: none" :href="`${'https://soxy.com' + trendingProduct.product.url_to_brand}`">
              <div v-if="isLoading" class="products-container" style="display: flex; width: 100%; height: 250px; flex-direction: row;">
                <ProductPlaceholder style="height: 300px; width: 200px;"/>
              </div>
              <img v-if="!isLoading" class="productImg" :src="trendingProduct.product.product_image" alt="image">
              <p v-if="!isLoading" class="title-text">{{ trendingProduct.product.store_name }}</p>
              <p v-if="!isLoading" class="product-fullname">{{ trendingProduct.product.name }}</p>
              <p v-if="!isLoading" class="product-fullprice">{{ trendingProduct.product.retail_price }}$</p>
            </a>
          </div>
        </div>
      </section>
      <div class="category-separator">
        <div v-if="isLoading" class="products-container">
          <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
        </div>
        <p v-if="!isLoading" class="category-text">{{ sectionsSpringIdeas }}</p>
        <div class="separator"></div>
      </div>
      <section class="fourth-section">
        <div v-for="springIdea in springIdeas" :key="springIdea.id">
          <a style="text-decoration: none" :href="springIdea.title_link">
            <div v-if="isLoading" class="products-container" style="display: flex; width: 100%; height: 250px; flex-direction: row;">
              <ImagePlaceholder style="height: 220px; width: 570px;"/>
            </div>
            <img class="fourthSection" :src="springIdea.image" v-if="!isLoading" alt="image">
            <div v-if="isLoading" class="products-container" style="position: relative; margin: 5px; width: 590px; height: 105px; background-color: #F7F7F7; border-radius: 10px;">
              <TextProductPlaceholder style="height: auto" />
            </div>
            <p v-if="!isLoading" class="title-text">{{ springIdea.category }}</p>
            <h1 v-if="!isLoading"> {{ springIdea.title }}</h1>
          </a>
        </div>
      </section>
      <div class="category-separator beautyBlock">
        <div v-if="isLoading" class="products-container">
          <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
        </div>
        <p v-if="!isLoading" class="category-text">{{sectionsBeauty}}</p>
        <div class="separator"></div>
      </div>
    </div>
  </div>
  <!--  TUUUUUUT -->
  <section class="fifth-section">
    <div class="scrolling-section-wrapper" id="fifth-section-wrapper"></div>
    <div class="scrolling-content" id="fifth-section-scrolling-content">
    <div class="slider-controls">
      <div class="slider-navigation" @click="slideBeauty(-1)">&#60;</div>
      <div class="slider-wrapper" :style="{ transform: 'translateX(' + translateValueBeauty + 'px)' }">
        <div class="beauty-item" v-for="(beauty, index) in beauties" :key="index">
          <a style="text-decoration: none" :href="beauty.title_link" class="beauty-link">
            <div v-if="isLoading" class="products-container" style="display: flex; width: 100%; height: 370px; flex-direction: row;">
              <ImagePlaceholder style="height: 340px; width: 250px;"/>
            </div>
            <img v-if="!isLoading" :src="beauty.image" class="left-image" alt="image">

            <div class="right-side-text">
              <div v-if="isLoading" class="products-container" style="position: relative; margin: 5px; width: 170px; height: 100%; background-color: #F7F7F7; border-radius: 10px;">
                <TextProductPlaceholder />
              </div>
              <p v-if="!isLoading" class="title-text">{{beauty.category}}</p>
              <h1 v-if="!isLoading">{{beauty.title}}</h1>
            </div>
          </a>
        </div>

      </div>
      <div class="slider-navigation" @click="slideBeauty(1)">&#62;</div>
    </div>      </div>
  </section>
  <div class="sections-wrapper">
    <div class="sections">
      <div class="category-separator">
        <div v-if="isLoading" class="products-container">
          <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
        </div>
        <p v-if="!isLoading" class="category-text">{{ sectionsLifestyle }}</p>
        <div class="separator"></div>
      </div>
      <section class="sixth-section">

        <div v-for="lifestyle in lifestyles" :key="lifestyle.id">
          <a style="text-decoration: none" :href="lifestyle.title_link">
            <div v-if="isLoading" class="products-container" style="display: flex; width: 100%; height: 530px; flex-direction: row;">
              <ImagePlaceholder style="height: 500px; width: 320px;"/>
            </div>
          <img v-if="!isLoading" :src="lifestyle.image" alt="image">
            <div v-if="isLoading" class="products-container">
              <StringProductPlaceholder style="width: 305px; padding-bottom: 10px;" />
            </div>
          <p v-if="!isLoading" class="title-text">{{lifestyle.category}}</p>
          <h1 v-if="!isLoading">{{lifestyle.title}}</h1>
          </a>
        </div>
      </section>
      <div class="category-separator">
        <div v-if="isLoading" class="products-container">
          <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
        </div>
        <p v-if="!isLoading" class="category-text">{{ sectionsFutured }}</p>
        <div class="separator"></div>
      </div>
      <section class="seventh-section">
        <div class="left-side" v-for="featured in featureds" :key="featured.id">
          <a style="text-decoration: none" :href="featured.title_link">
            <div v-if="isLoading" class="products-container" style="display: flex; width: 100%; height: 650px; flex-direction: row;">
              <ImagePlaceholder style="height: 600px; width: 750px;"/>
            </div>
          <img v-if="!isLoading" :src="featured.image" alt="image">
            <div v-if="isLoading" class="products-container">
              <StringProductPlaceholder style="width: 760px; padding-bottom: 10px;" />
            </div>
          <p v-if="!isLoading" class="title-text">{{featured.category}}</p>
          <h1 v-if="!isLoading">{{featured.title}}</h1>
        </a>
        </div>
        <div class="right-side">
          <div  v-for="featuredRight in featuredsRight" :key="featuredRight.id">
            <a style="text-decoration: none;display: flex; flex-direction: column; gap: 1.5em;" :href="featuredRight.title_link">
            <div class="mobile">
              <div v-if="isLoading" class="products-container">
                <StringProductPlaceholder style="width: 760px; padding-bottom: 0px;" />
              </div>
              <p v-if="!isLoading" class="title-text">{{featuredRight.category}}</p>
              <h1 v-if="!isLoading">{{featuredRight.title}}</h1>
            </div>
              <div v-if="isLoading" class="products-container" style="display: flex; width: 100%; height: 650px; flex-direction: row;">
                <ImagePlaceholder style="height: 600px; width: 750px;"/>
              </div>
            <img v-if="!isLoading" :src="featuredRight.image" alt="image">
              <div v-if="isLoading" class="products-container">
                <StringProductPlaceholder style="width: 760px; padding-bottom: 0px;" />
              </div>
            <p v-if="!isLoading" class="title-text desktop">{{featuredRight.category}}</p>
            <h1 v-if="!isLoading" class="desktop">{{featuredRight.title}}</h1>
              </a>
          </div>
        </div>
      </section>
      <div class="category-separator tranding-separator desktop">
        <div v-if="isLoading" class="products-container">
          <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
        </div>
        <p v-if="!isLoading" class="category-text tranding-text">{{ sectionsTrendingDeals }}</p>
        <div class="separator"></div>
      </div>
      <div class="mobile">
        <div class="category-separator">
          <div v-if="isLoading" class="products-container">
            <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
          </div>
          <p v-if="!isLoading" class="category-text">{{ sectionsBrands }}</p>
          <div class="separator"></div>
        </div>
      </div>
      <section class="eighth-section" id="eight-section">
        <div>
          <div class="tranding-item">
            <h1>ADP19INDHS</h1>
            <p>Save 25% Off + Free Shipping</p>
            <a href="https://www.adidas.com/">www.adidas.com</a>
          </div>
          <div class="separator"></div>
          <div class="tranding-item">
            <h1>75STYLE</h1>
            <p>Up To 75% Off Your Order</p>
            <a href="https://www.bergdorfgoodman.com/">www.bergdorfgoodman.com</a>
          </div>
          <div class="separator"></div>
          <div class="tranding-item">
            <h1>NC15FF</h1>
            <p>FARFETCH Discount Code: 15% Off Your Order</p>
            <a href="https://www.farfetch.com/shopping/women/items.aspx">www.farfetch.com</a>
          </div>
          <div class="separator"></div>
          <div class="tranding-item">
            <h1>GG25</h1>
            <p>Receive 25% Off Your Order</p>
            <a href="https://www.neimanmarcus.com/en-ua/">www.neimanmarcus.com</a>
          </div>
        </div>
        <div class="separator mobile"></div>
        <div>
          <div class="tranding-item">
            <h1>HYDRATE</h1>
            <p>Get a Free Gift With Purchase</p>
            <a href="https://www.nordstrom.com/">www.nordstrom.com</a>
          </div>
          <div class="separator"></div>
          <div class="tranding-item">
            <h1>15FORYOU</h1>
            <p>Get 15% Discount Using Promo Code</p>
            <a href="https://www.nordstromrack.com/">www.nordstromrack.com</a>
          </div>
          <div class="separator"></div>
          <div class="tranding-item">
            <h1>BOOT50</h1>
            <p>50% Off Women's Clearance</p>
            <a href="https://www.nordstromrack.com/">www.nordstromrack.com</a>
          </div>
          <div class="separator"></div>
          <div class="tranding-item">
            <h1>NMSALE</h1>
            <p>40% Off Alexander McQueen</p>
            <a href="https://www.neimanmarcus.com/en-ua/">www.neimanmarcus.com</a>
          </div>
        </div>
        <div class="separator mobile"></div>
        <div>
          <div class="tranding-item">
            <h1>RXKJ2Q</h1>
            <p>10% Off Sitewide</p>
            <a href="https://www.farfetch.com/shopping/women/items.aspx">www.farfetch.com</a>
          </div>
          <div class="separator"></div>
          <div class="tranding-item">
            <h1>GIFT1</h1>
            <p>Free 7-Pc Estee Lauder Gift With $35 Purchase</p>
            <a href="https://www.nordstromrack.com/">www.nordstrom.com</a>
          </div>
          <div class="separator"></div>
          <div class="tranding-item">
            <h1>MC10N26</h1>
            <p>Save 26% Off</p>
            <a href="https://www.farfetch.com/shopping/women/items.aspx">www.farfetch.com</a>
          </div>
          <div class="separator"></div>
          <div class="tranding-item">
            <h1>SAVEMORE</h1>
            <p>Get $60 Off $200, $20 Off $100+</p>
            <a href="https://global.adidas.com/">www.adidas.com</a>
          </div>
        </div>
      </section>
<!--      <button class="eighth-section-load-more mobile" id="eighth-section-load-button">Load more</button>-->
      <div class="category-separator blockFashion">
        <div v-if="isLoading" class="products-container">
          <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
        </div>
        <p v-if="!isLoading" class="category-text">{{ sectionsGiftsForHer }}</p>
        <div class="separator"></div>
      </div>
      <section class="ninth-section blockFashion">
        <div class="mobile mobile-scrolling-section-wrapper" id="ninth-section-wrapper"></div>
        <div class="mobile-scrolling-content ninth-section-content" id="ninth-section-scrolling-content">
          <div v-for="giftsforher in giftsforhers" :key="giftsforher.id">
            <a style="text-decoration: none" :href="`${'https://soxy.com' + giftsforher.product.url_to_brand}`">
              <div v-if="isLoading" class="products-container" style="display: flex; width: 100%; height: 250px; flex-direction: row;">
                <ProductPlaceholder style="height: 260px; width: 200px;"/>
              </div>
            <img v-if="!isLoading" :src="giftsforher.product.product_image" alt="image">
            <p v-if="!isLoading" class="title-text">{{ giftsforher.product.store_name }}</p>
              <a v-if="!isLoading" style="text-decoration: none" :href="`${giftsforher.product.redirect_url}`">
            <p v-if="!isLoading" class="product-fullname">{{ giftsforher.product.name }}</p>
                </a>
            <p v-if="!isLoading" class="product-fullprice">{{ giftsforher.product.retail_price }}$</p>
            </a>
          </div>
        </div>
      </section>
      <div class="category-separator ninth-section-category-separator">
        <div v-if="isLoading" class="products-container">
          <StringProductPlaceholder style="width: 500px; padding-bottom: 10px;" />
        </div>
        <p v-if="!isLoading" class="category-text">{{ sectionsBrands }}</p>
        <div class="separator"></div>
      </div>
      <section class="brands-section">
        <table class="desktop">
          <colgroup>
            <col span="1" style="width: 20%;">
            <col span="1" style="width: 20%;">
            <col span="1" style="width: 20%;">
            <col span="1" style="width: 20%;">
            <col span="1" style="width: 20%;">
          </colgroup>
          <tr>
            <td>
              <div><a href="https://soxy.com/shop/givenchy"><img src="../../assets/images/Givenchy.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/dior"><img src="../../assets/images/dior.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/balenciaga"><img src="../../assets/images/balenciaga.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/balmain"><img src="../../assets/images/balmain.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/alexander-wang"><img src="../../assets/images/alexanderwang.png" alt="images"></a></div>
            </td>
          </tr>
          <tr>
            <td>
              <div><a href="https://soxy.com/shop/dolce-gabbana"><img src="../../assets/images/dolce&gabbana.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/amiri"><img src="../../assets/images/amiri-1%201.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/canada-goose"><img src="../../assets/images/canada%20goose.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/fendi"><img src="../../assets/images/fendi.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/chloe"><img src="../../assets/images/chloe.png" alt="images"></a></div>
            </td>
          </tr>
          <tr>
            <td>
              <div><a href="https://soxy.com/shop/gucci"><img src="../../assets/images/gucci.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/hermes"><img src="../../assets/images/hermes.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/acne-studios"><img src="../../assets/images/Acne%20Studios.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/balmain"><img src="../../assets/images/balmain.png" alt="images"></a></div>
            </td>
            <td>
              <div><a href="https://soxy.com/shop/fear-of-god"><img src="../../assets/images/fear-of-god.png" alt="images"></a></div>
            </td>
          </tr>
        </table>
        <table class="mobile">
          <colgroup>
            <col span="1" style="width: 50%;">
            <col span="1" style="width: 50%;">
          </colgroup>
          <tbody>
          <tr>
            <td>
              <div><img src="../../assets/images/Givenchy.png" alt="images"></div>
            </td>
            <td>
              <div><img src="../../assets/images/dior.png" alt="images"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div><img src="../../assets/images/balenciaga.png" alt="images"></div>
            </td>
            <td>
              <div><img src="../../assets/images/balmain.png" alt="images"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div><img src="../../assets/images/dolce&gabbana.png" alt="images"></div>
            </td>
            <td>
              <div><img src="../../assets/images/amiri-1%201.png" alt="images"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div><img src="../../assets/images/gucci.png" alt="images"></div>
            </td>
            <td>
              <div><img src="../../assets/images/hermes.png" alt="images"></div>
            </td>
          </tr>
          </tbody>
        </table>
        <!--        <div class="all-brands-wrapper button-wrapper">-->
        <!--          <button class="all-brands button">View all brands</button>-->
        <!--        </div>-->
      </section>
    </div>
  </div>
  <FooterMain/>
</template>

<script>
import '@/assets/styles/fonts.css';
import HeaderMain from "@/components/main/HeaderMain.vue";
import FooterMain from "@/components/main/FooterMain.vue";
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import ImagePlaceholder from "@/components/UI/ImagePlaceholder.vue";
import TextProductPlaceholder from "@/components/UI/TextProductPlaceholder.vue";
import StringProductPlaceholder from "@/components/UI/StringProductPlaceholder.vue";
import ProductPlaceholder from "@/components/UI/ProductPlaceholder.vue";
import { API_BASE_URL } from '@/components/config/config';

export default {
  name: 'BodyMain',
  components: {
    ProductPlaceholder,
    StringProductPlaceholder, TextProductPlaceholder, ImagePlaceholder, FooterMain, HeaderMain},
  data() {
    return {
      menu: [
        {id: 1, label: "WOMEN", url: "shop/women"},
        {id: 2, label: "MEN", url: "shop/men"},
        {id: 3, label: "SHOES", url: "shop/womens-shoes"},
        {id: 1, label: "BAGS", url: "shop/womens-bags"},
        {id: 2, label: "KIDS", url: "shop/kids"},
        {id: 3, label: "HOME & LIVING", url: "shop/home-living"},
        {id: 1, label: "BEAUTY", url: "shop/womens-beauty-products"},
      ],
      contents: [{"": ""},{"": ""}],
      sections: [{"": ""}],
      contentsRightSide: [{"": ""},{"": ""},{"": ""}],
      contentsSlider: [{"": ""}, {"": ""}, {"": ""}],
      trendingProducts: [],
      giftsforhers: [],
      springIdeas: [],
      beauties: [],
      lifestyles: [],
      featureds: [],
      featuredsRight: [],
      translateValue: 0,
      currentIndex: 0,
      currentIndexBeauty: 0,
      translateValueBeauty: 0,
      sectionsGuide: '',
      sectionsFashion: '',
      sectionTrendingProducts: '',
      sectionsSpringIdeas: '',
      sectionsBeauty: '',
      sectionsLifestyle: '',
      sectionsFutured: '',
      sectionsTrendingDeals: '',
      sectionsGiftsForHer: '',
      sectionsBrands: '',
      isLoading: true,
    };
  },
  created() {
    this.currentIndex = Math.min(0, this.contentsSlider.length - 4);
    this.currentIndexBeauty = Math.min(0, this.beauties.length - 4);

    this.fetchContents();
  },
  methods: {
    updateProductsByMenu(menuItemUrl) {
      console.log("Menu item URL clicked:", menuItemUrl);
      this.$emit('updateProductsByMenu', menuItemUrl);
      this.$router.push(`/${menuItemUrl}`);
    },
    slideBeauty(direction) {
      const sliderWidthBeauty = document.querySelector('.beauty-item').offsetWidth;
      const maxIndexBeauty = Math.max(0, this.beauties.length - 4);
      if (direction === -1 && this.currentIndexBeauty > 0) {
        this.currentIndexBeauty--;
      } else if (direction === 1 && this.currentIndexBeauty < maxIndexBeauty) {
        this.currentIndexBeauty++;
      }

      this.currentIndexBeauty = Math.max(0, Math.min(this.currentIndexBeauty, maxIndexBeauty));
      this.translateValueBeauty = -this.currentIndexBeauty * sliderWidthBeauty;
    },
    slide(direction) {
      const sliderWidth = document.querySelector('.slider-item').offsetWidth;
      const maxIndex = Math.max(0, this.contentsSlider.length - 4);
      if (direction === -1 && this.currentIndex > 0) {
        this.currentIndex--;
      } else if (direction === 1 && this.currentIndex < maxIndex) {
        this.currentIndex++;
      }
      this.currentIndex = Math.max(0, Math.min(this.currentIndex, maxIndex));
      this.translateValue = -this.currentIndex * sliderWidth;
    },

    async fetchContents() {
      this.isLoading = true;
      try {
        const response = await axios.get(`${API_BASE_URL}/welcome`);
        console.log(response);
        this.sectionsGuide = response.data.sections[0].title;
        this.sectionsFashion = response.data.sections[2].title;
        this.sectionTrendingProducts = response.data.sections[3].title;
        this.sectionsSpringIdeas = response.data.sections[4].title;
        this.sectionsBeauty = response.data.sections[5].title;
        this.sectionsLifestyle = response.data.sections[6].title;
        this.sectionsFutured = response.data.sections[7].title;
        this.sectionsTrendingDeals = response.data.sections[8].title;
        this.sectionsGiftsForHer = response.data.sections[9].title;
        this.sectionsBrands = response.data.sections[11].title;

        this.contents = response.data.sections[0].contents;
        this.contentsRightSide = response.data.sections[1].contents;
        this.contentsSlider = response.data.sections[2].contents;
        this.trendingProducts = response.data.products.section_1;
        this.giftsforhers =  response.data.products.section_2;
        this.springIdeas = response.data.sections[4].contents;
        this.beauties = response.data.sections[5].contents;
        this.lifestyles = response.data.sections[6].contents;
        this.featureds = response.data.sections[7].contents;
        this.featuredsRight = response.data.sections[10].contents;
        console.log(this.sections)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    const authStore = useAuthStore();
     authStore.loadToken();
  },
};
</script>

<style scoped>
.left-image {
  width: 150px;
  height: auto;
}

.beauty-item {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.beauty-link {
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
  color: inherit;
  justify-content: space-between;
}

.right-side-text{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  width: 100%;
  margin-left: 20px;
  left: 0px;
  flex-direction: column;
}

.slider {
  position: relative;
  overflow: hidden;
}

.slider-controls {
  display: flex;
  width: 100%;
  height: 600px;
  justify-content: space-between;
}

.slider-navigation {
  position: relative;
  display: flex;
  margin: 10px;
  font-size: 30px;
  cursor: pointer;
  align-items: center;
  z-index: 1;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease;
}

.slider-item {
  flex: 0 0 25%;
  box-sizing: border-box;
  padding: 10px;
}

.slider-caption {
  display: flex;
  text-align: start;
  flex-direction: column;
  align-items: flex-start;
}

img {
  width: 100%;
  height: auto;
}

.sections-wrapper {
  display: flex;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
}

.sections {
  width: 77.5em; /* 1240px */
}

section {
  margin-bottom: 6.25em; /* 100px */
}

section .sliderImg{
  object-fit: cover;
  width: 100%;
  height: 80%;
}

.fourthSection{
  object-fit: cover;
  width: 100%;
  height: 80%;
}

.productImg{
  object-fit: cover;
  width: 100%;
  height: 80%;
}

section img {
  object-fit: cover;
  width: 100%;
}

.first-section {
  display: flex;
  gap: var(--default-gap);
}

.first-section .left-side {
  display: flex;
  gap: var(--default-gap);
}

.first-section .left-side div, .first-section .left-side div img {
  width: 21.25em /* 340px */;
}

.section-text {
  margin-top: 0.75em;
  font-family: 'Zodiak', sans-serif;
}

.title-text {
  margin-top: 1em;
  font-weight: 400;
}

.right-side > div {
  display: flex;
  align-items: center;
  gap: 1em;
}

.right-side div img {
  max-width: 11.25em;
}

.right-side {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.right-side .section-text {
  font-size: 1.125em;
}

.second-section > .scrolling-content {
  display: flex;
  gap: var(--default-gap);
  overflow-x: hidden;
  padding-left: var(--default-gap);
  padding-right: var(--default-gap);
}

.second-section > .scrolling-content > div {
  min-width: 22.75em; /* 364px */
}

.carousel {
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  flex: 0 0 auto;
}

.carousel-item img {
  max-width: 100%;
  height: auto;
}

.second-section > .scrolling-content > div > img {
  width: 100%;
  height: 27.9375em; /* 447px */
}

#second-section-wrapper {
  height: 33.125em /* 530px */
}

.scrolling-section-wrapper {
  width: 100%;
  position: absolute;
}

.third-section .third-section-content, .ninth-section .ninth-section-content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: var(--default-gap);
}

.third-section .title-text, .ninth-section .title-text {
  font-weight: 500;
}

.product-fullname{
  margin-top: 10px;
  font-family: 'Moderat', sans-serif;
}

.product-name, .product-price {
  margin-top: 0.5em;
  font-family: 'Zodiak';
  font-weight: 540;
}

.product-price {
  font-weight: 500;
}

.product-fullprice{
  margin-top: 10px;
  font-weight: 500;
}

.fourth-section {
  display: flex;
  gap: var(--default-gap);
}

.fourth-section .title-text, .fourth-section h1 {
  text-align: center;
}

.fourth-section h1 {
  margin-top: 1em;
  font-size: 1.625em; /* 26px */
}

.fifth-section img {
  width: 9.375em; /* 150px */
  height: 6.75em;
}

.fifth-section .scrolling-content {
  display: flex;
  background-color: var(--light-gray);
  overflow: hidden;
  height: 300px;
  gap: var(--default-gap);
  padding: 2.875em var(--default-gap); /* 46px */
  align-items: center;
}

.fifth-section .scrolling-content > div {
  display: flex;
  gap: 1em;
  width: 100%;
  align-items: center;
  min-width: 28.125em; /* 450px */
}

.fifth-section .scrolling-content h1 {
  font-size: 1.125em;
  margin-top: 0.75em;
}

.fifth-section .scrolling-content .title-text {
  margin-top: 0;
}

#fifth-section-wrapper {
  height: 12.5em; /* 200px */
}

.sixth-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--default-gap);
}

.sixth-section h1 {
  padding-top: 0.75em;
}

.seventh-section {
  display: grid;
  gap: var(--default-gap);
  grid-template-columns: auto auto;
}

.seventh-section .left-side h1 {
  font-size: 1.625em;
  margin-top: 1em;
}

.seventh-section .left-side h1, .seventh-section .left-side .title-text {
  text-align: center;
}

.seventh-section .left-side {
  /*  width: 49.125em 786px */
  width: 50.325em /* 786px */;
}

.seventh-section .right-side > div {
  flex-direction: column;
}

.seventh-section .right-side div img {
  max-width: initial;
}

.seventh-section {
  margin-bottom: 8.375em /* 134px */
}

.tranding-text {
  white-space: nowrap;
}

.tranding-separator {
  margin-bottom: 5.125em; /* 82px */
}

.eighth-section .separator {
  margin: 1.5em 0;
  height: 1px;
  background: #DCDCDC;
}

.eighth-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--default-gap);
}

.tranding-item {
  display: flex;
  gap: 0.75em;
  flex-direction: column;
}

.tranding-item a {
  text-decoration: none;
  font-family: 'Moderat', sans-serif;
}

.tranding-item h1 {
  font-family: 'Moderat', sans-serif;
  font-size: 1.5em;
  font-weight: 900;
  line-height: 115%; /* 27.6px */
}

.tranding-item p, .tranding-item a {
  font-size: 1.125em;
  font-weight: 400;
  line-height: 160.5%; /* 28.89px */
}

.tranding-item p {
  font-family: 'Zodiak', sans-serif;
}

.ninth-section {
  margin-bottom: 5em;
}

.ninth-section-category-separator {
  margin-bottom: 4.1875em; /* 67px */
}

.brands-section > table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 4.75em /* 76px */;
}

.brands-section > table img {
  width: initial;
  max-width: 15.625em;
}

.brands-section > table tr td > div {
  height: 13.125em; /* 210px */
  display: flex;
  justify-content: center;
  align-items: center;
}

.brands-section > table tr td {
  border: 1px solid #DBDBDB;
}

.brands-section > table tr td:first-child {
  border-left: none;
}

.brands-section > table tr:first-child td {
  border-top: none;
}

.brands-section > table tr td:last-child {
  border-right: none;
}

.brands-section > table tr:last-child td {
  border-bottom: none;
}

.all-brands-wrapper {
  margin-bottom: 4.9375em; /* 79px */
}

.first-part.mobile > div > .images {
  display: flex;
  gap: 16px;
  transition: transform 0.5s;
  flex-direction: column;
}

.first-part.mobile {
  overflow: hidden;
  display: block;

}

.first-part.mobile > div > div, .first-part.mobile > div > div img {
  width: calc(100vw - 32px);
}

.slider-control {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  gap: 8px;
}

.slider-control div {
  height: 6px;
  max-width: 6px;
  background-image: url("../../assets/icons/dot-inactive.svg");
}

.slider-control div.active {
  background-image: url("../../assets/icons/dot-active.svg");
}

.mobile-scrolling-section-wrapper {
  position: absolute;
}

@media (max-width: 1390px) {
  .brands-section > table tr td > div {
    height: 10em;
  }
}

@media (max-width: 1200px) {
  .brands-section > table img {
    transform: scale(0.85);
  }
}

@media (max-width: 1000px) {
  .brands-section > table img {
    transform: scale(0.7);
  }
}

@media (max-width: 990px) {
  .sections-wrapper {
    padding: 0 16px;
    margin-top: 35px;
  }
.blockFashion{
  display: none;
}
  .slider {
    display: none;
  }
.beautyBlock{
  display: none;
}
  .sections {
    width: 100%;
  }

  .first-section {
    display: block;
  }

  .title-text {
    font-size: 14px;
    margin-top: 16px;
  }

  .section-text {
    font-size: 24px;
    margin-top: 8px;
  }

  .first-section .right-side, .first-section .right-side div {
    width: 100%;
  }

  .first-section .right-side div img {
    height: 82px;
    width: 120px;
    max-width: initial;
  }

  .first-section .right-side div div p {
    font-size: 14px;
    font-weight: 400;
  }

  .right-side .section-text {
    font-size: 16px;
  }

  html {
    -webkit-tap-highlight-color: transparent;
    overflow-x: hidden;
  }

  .second-section > .scrolling-content > div > img {
    height: 314px;
    width: 256px;
  }

  .second-section > .scrolling-content > div {
    min-width: 256px;
  }

  .second-section > .scrolling-content {
    gap: 16px;
  }

  section {
    margin-bottom: 72px;
  }

  #second-section-wrapper {
    height: 412px;
  }

  .third-section-content div, .third-section-content div img,
  .ninth-section-content div, .ninth-section-content div img {
    width: 256px;
  }

  .third-section-content .title-text,
  .third-section-content .product-name,
  .third-section-content .product-price,
  .ninth-section-content .title-text,
  .ninth-section-content .product-name,
  .ninth-section-content .product-price {
    font-size: 16px;
  }

  #third-section-wrapper, #ninth-section-wrapper {
    height: 403px;
    width: 100%;
    left: 0
  }

  .image-placeholder.mobile{
    width: 100%;
    height: 300px !important;
    /* height: inherit; */
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .product-placeholder {
    position: relative;
    margin: 5px;
    width: calc(100% - 40px);
    height: 200px !important;
    padding: 10px;
    background-color: #F7F7F7;
    border-radius: 10px;
  }

  .product-placeholder-text{
    width: calc(100% - 40px) !important;
  }

  .product-placeholder-string  {
    position: relative;
    margin: 5px;
    width: calc(100vw - 70px) !important;
    padding: 10px;
    height: auto;
    background-color: #F7F7F7;
    border-radius: 10px;
  }
  .fourth-section {
    flex-direction: column;
    gap: 60px;
  }

  .fourth-section h1 {
    margin-top: 8px;
    font-size: 24px;
  }

  .fifth-section {
    display: none;
  }

  .fifth-section img {
    width: 120px;
    height: 82px;
  }

  .fifth-section .scrolling-content > div {
    min-width: 358px;
  }

  .fifth-section .scrolling-content {
    gap: 16px;
    padding: 40px;
  }

  .sixth-section {
    grid-template-columns: 1fr;
    gap: 60px;
  }

  .sixth-section h1 {
    font-size: 24px;
  }

  .seventh-section {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .seventh-section .right-side div {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .seventh-section .right-side div img {
    width: 120px;
    height: 82px;
  }

  .seventh-section .right-side,
  .seventh-section .left-side,
  .seventh-section .left-side img {
    width: calc(100vw - 32px);
  }

  .seventh-section .right-side h1 {
    font-size: 16px;
    margin-top: 8px;
  }

  .eighth-section {
    grid-template-columns: 1fr;
    gap: 0;
    max-height: 504px;
    overflow-y: hidden;
    margin-bottom: 40px;
    transition: max-height 0.5s;
  }

  .tranding-item h1 {
    font-size: 20px;
  }

  .tranding-item p, .tranding-item a {
    font-size: 14px;
  }

  .tranding-item {
    gap: 8px;
  }

  .eighth-section .separator {
    margin: 24px 0;
  }

  .eighth-section-load-more {
    display: none;
    width: 100%;
    background-color: white;
    border: 1px solid #111;
    padding: 14px 0;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 72px;
    cursor: pointer;
  }

  .third-section .third-section-content, .ninth-section .ninth-section-content {
    display: flex;
    width: 100%;
    overflow-x: hidden;
  }

  .brands-section > table tr td > div {
    height: 100px;
    width: calc(50vw - 16px);
  }

  .all-brands-wrapper .all-brands {
    width: 100%;
    padding: 14px 0;
    font-size: 14px;
    font-weight: 500;
  }

  .brands-section > table {
    margin-bottom: 40px;
  }

  .brands-section > table img {
    transform: scale(0.85);
  }

  .all-brands-wrapper {
    margin-bottom: 120px;
  }

  .first-section .right-side {
    gap: 32px;
  }

  #first-section-wrapper {
    height: 521px;
    position: absolute;
    width: calc(100vw - 32px);
    transform: translateZ(10px);
    z-index: 1000;
  }
}

</style>
