import { createRouter, createWebHistory } from 'vue-router'
import Main from '../components/main/Main.vue'
import ShopCatalog from '@/components/shop/ShopCatalog.vue';
import AboutUsPage from  '@/components/aboutUs/AboutUsPage.vue'
import NotFound from "@/components/main/NotFound.vue";
import CartPage from "@/components/cart/CartPage.vue";
import CheckoutPage from "@/components/cart/CheckoutPage.vue";
import PaymentPage from "@/components/cart/PaymentPage.vue";
import ShopProduct from "@/components/shop/ShopProduct.vue";
import OrderPage from "@/components/cart/OrderPage.vue";

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    meta: {
      title: 'Home - Best Deals You Need To See'
    }
  },
  {
    path: '/shop',
    name: 'ShopCatalog',
    component: ShopCatalog,
    meta: {
      title: 'Clothes, Bags & Shoes - Best Deals You Need To See'
    },
    children: [
      {
        path: ':id',
        name: 'ShopCatalogBrand',
        component: ShopCatalog,
        meta: {
          title: 'Best Deals You Need To See'
        },
      }
    ],
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: ShopCatalog
  },
  {
    path: '/product/:id',
    name: 'ShopProduct',
    component: ShopProduct,
    meta: {
      title: 'Best Deals You Need To See'
    },
    props: true,
  },
  {
    path: '/cart',
    name: 'CartPage',
    component: CartPage,
    meta: {
      title: 'Cart - Best Deals You Need To See'
    }
  },
  {
    path: '/checkout',
    name: 'CheckoutPage',
    component: CheckoutPage,
    meta: {
      title: 'Checkout - Best Deals You Need To See'
    },
    props: true,
  },
  {
    path: '/payment',
    name: 'PaymentPage',
    meta: {
      title: 'Payment - Best Deals You Need To See'
    },
    component: PaymentPage,
  },
  {
    path: '/order',
    name: 'OrderPage',
    meta: {
      title: 'Order - Best Deals You Need To See'
    },
    component: OrderPage,
  },
  {
    path: '/aboutUs',
    name: 'AboutUsPage',
    component: AboutUsPage
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'Sorry, this page was not found'
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
