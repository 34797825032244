<script>
import HeaderMain from "@/components/main/HeaderMain.vue";
import FooterMain from "@/components/main/FooterMain.vue";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import {useCartStore} from "@/stores/product";
import { API_BASE_URL } from '@/components/config/config';

export default {
  name: "CartPage",
  components: { FooterMain, HeaderMain },

  data() {
    return {
      menu: [
        {id: 1, label: "WOMEN", url: "shop/women"},
        {id: 2, label: "MEN", url: "shop/men"},
        {id: 3, label: "SHOES", url: "shop/womens-shoes"},
        {id: 1, label: "BAGS", url: "shop/womens-bags"},
        {id: 2, label: "KIDS", url: "shop/kids"},
        {id: 3, label: "HOME & LIVING", url: "shop/home-living"},
        {id: 1, label: "BEAUTY", url: "shop/womens-beauty-products"},
      ],
      favorites: [],
    };
  },
  created() {
    this.fetchCartData();
  },
  computed: {
    subtotal() {
      return this.cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);
    },
    total() {
      return (parseFloat(this.subtotal)).toFixed(2);
    },
    cartItems() {
      const cartStore = useCartStore();
      return cartStore.cartItems;
    }
  },
  methods: {
    updateProductsByMenu(menuItemUrl) {
      console.log("Menu item URL clicked:", menuItemUrl);
      this.$emit('updateProductsByMenu', menuItemUrl);
      this.$router.push(`/${menuItemUrl}`);
    },
    async fetchCartData() {
      const authStore = useAuthStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error('No authentication token found');
        return;
      }

      try {
        const response = await axios.get(`${API_BASE_URL}/cart/view`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log(response.data.data);

        const cartStore = useCartStore();
        cartStore.setCartItems(response.data.data);
        console.log(cartStore.cartItems, 'CARTITEMS');
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    },

    async removeCartItem(item) {
      const authStore = useAuthStore();
      const cartItemsPinia = useCartStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error('No authentication token found');
        return;
      }

      try {
        await axios.post(`${API_BASE_URL}/cart/delete`, {
          row_id: item.row_id
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        // this.cartItems = this.cartItems.filter(cartItem => cartItem.row_id !== item.row_id);
        cartItemsPinia.cartItems = cartItemsPinia.cartItems.filter(cartItem => cartItem.row_id !== item.row_id);
      } catch (error) {
        console.error('Error removing item from cart:', error);
      }
    },

    navigateToCheckout() {
      this.$router.push({ path: '/checkout' });
    },

    async updateCartItemQuantity(item) {
      const authStore = useAuthStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error("No authentication token found");
        return;
      }

      try {
        await axios.post(
            `${API_BASE_URL}/cart/update`,
            {
              row_id: item.row_id,
              quantity: item.quantity,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        );
      } catch (error) {
        console.error("Error updating cart item quantity:", error);
      }
    },

    async addToFavorites(productId) {
      try {
        const authStore = useAuthStore();
        authStore.loadToken();
        const token = authStore.token;

        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const response = await axios.post(`${API_BASE_URL}/favorite/add`, {
          product_id: productId
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log(response, 'ADD RES ');

        // Refresh favorites list after adding
        await this.loadFavorites();
      } catch (error) {
        console.error('Error adding to favorites:', error);
      }
    },
    async loadFavorites() {
      try {
        const authStore = useAuthStore();
        authStore.loadToken();
        const token = authStore.token;

        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const response = await axios.get(`${API_BASE_URL}/favorite/list`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        // Ensure the response is an array
        if (Array.isArray(response.data.data)) {
          this.favorites = response.data.data;
        } else {
          console.error('Favorites list is not an array');
          this.favorites = [];
        }

        console.log(response.data.data);
      } catch (error) {
        console.error('Error loading favorites:', error);
        this.favorites = []; // Ensure favorites is an array even on error
      }
    },
    isFavorite(productId) {
      console.log(productId, 'ISFAVORITE productId')
      return this.favorites.some(favorite => favorite.id == productId);
    }
  },
  mounted() {
    this.loadFavorites();
  },
};
</script>

<template>
  <HeaderMain
      :menu="menu"
      @update-products-by-menu="updateProductsByMenu"
  />
  <div>
    <div class="page-wrapper">
      <div>
        <div>
          <h1 class="cart-title" id="cart-title">Shopping cart</h1>
        </div>

        <div v-if="cartItems.length === 0" class="empty-cart" id="empty-cart">
          <div>
            <div>
              <img src="../../assets/icons/bag.svg">
              <p class="add-items-to-cart">Add items to your Shopping Bag</p>
              <router-link to="/shop" class="button cart-button">Shop What's New</router-link>
            </div>
          </div>
        </div>

        <div v-else id="cart-with-items" style="display: block;">
          <div>
            <table style="height: 0">
              <colgroup class="desktop">
                <col span="1" style="width: 12.8%; height: 8.375em">
                <col span="1" style="width: 40.7%; height: 8.375em">
                <col span="1" style="width: 14%; height: 8.375em">
                <col span="1" style="width: 13.2%; height: 8.375em">
                <col span="1" style="width: auto; height: 8.375em">
                <col span="1" style="width: auto; height: 8.375em">
              </colgroup>
              <thead class="desktop">
              <tr>
                <th>Product</th>
                <th></th>
                <th>Color</th>
                <th>Size</th>
                <th class="count">Quantity</th>
                <th class="price">Price</th>
              </tr>
              </thead>
              <tbody id="cart-items-container">
              <tr v-for="item in cartItems" :key="item.product_id">
                <td><img :src="item.product_image"></td>
                <td>
                  <div>
                    <div class="second-column">
                      <div class="name-and-manufacture"><p class="name">{{item.product_name}}</p>
                        <p class="manufacture">{{item.product_brand}}</p></div>
                      <div class="cart-item-control">
<!--                        <div data-id="6251794" class="move-to-whitelist"><img src="../../assets/icons/heart.svg">-->
<!--                          <p>Move to Whishlist</p></div>-->
                        <div class="remove-item" @click="addToFavorites(item.product_id)">
                          <img :src="isFavorite(item.product_id) ? require('@/assets/icons/heart-active.svg') : require('@/assets/icons/heart.svg')" alt="heart" style="z-index: 1">
                          <p>Move to Wishlist</p>
                        </div>

                        <div class="remove-item" @click="removeCartItem(item)">
                        <img src="../../assets/icons/remove.svg">
                          <p>Remove item</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </td>
                  <td>   <th class="mobileItem">Color</th><p class="value mobileItem">{{item.color}}</p></td>
                <td>   <th class="mobileItem">Size</th><p class="value mobileItem">{{ item.size_name }}</p></td>
                <td>
                  <th class="mobileItem">Quantity</th>
                  <p class="value count mobileItem">
                  <input class="count-input" maxlength="4" type="number" style="width: 0.6em" v-model.number="item.quantity"
                         @change="updateCartItemQuantity(item)">
                </p>
                </td>
                <td>
                  <th class="mobileItem">Price</th>
                  <p class="value price">{{item.price}}</p>
                </td>

              </tr>
              </tbody>
            </table>
            <div>
              <p class="summary-title">SUMMARY</p>
              <div class="summary-info">
                <div><p class="name">Subtotal</p>
                  <p class="price" id="subtotal">${{ subtotal }}</p></div>
                <div><p class="name">Handling &amp; duties</p>
                  <p class="price">$0</p></div>
                <div><p class="name">Shipping</p>
                  <p class="price">$0</p></div>
                <div><p class="name">Tax</p>
                  <p class="price">$0</p></div>
              </div>
              <div class="summary-total"><p>Total</p>
                <p id="total">${{ total }}</p></div>
              <button @click="navigateToCheckout"
                      class="button summary-button">Proceed to checkout
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="additional-info">
      <div>
        <div class="additional-item">
          <div>
            <img src="../../assets/icons/bank-card.svg" alt="bank-card">
          </div>
          <h1 class="title">Secure Shopping</h1>
          <p class="text">
            We use stripe as a payment processor, which meets the credit
            card industry's rigorous PCI security standards
          </p>
        </div>
        <div class="additional-item">
          <div>
            <img src="../../assets/icons/shield.svg" alt="shield">
          </div>
          <h1 class="title">Privacy Protection</h1>
          <p class="text">Your privacy and security is our number-one priority</p>
        </div>
        <div class="additional-item">
          <div>
            <img src="../../assets/icons/message.svg" alt="message">
          </div>
          <h1 class="title">Best Customer Support</h1>
          <p class="text">
            Our friendly customer support team is always available
            to help answer any questions you may have
          </p>
        </div>
      </div>
    </div>
  </div>
  <FooterMain/>
</template>


<style scoped>
.cart-title {
  font-size: 2em;
  margin-bottom: 3.125em;
  margin-top: 35px;
}

.empty-cart {
  display: flex;
  justify-content: center;
}

.empty-cart img {
  width: 3.375em;
}

.empty-cart > div > div {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25em;
  align-items: center;
  flex-direction: column;
}

.add-items-to-cart {
  font-weight: 500;
  margin-bottom: 2em;
  text-align: center;
}

.cart-button {
  /* width: 22.5em; */
  text-decoration: none;
  margin-bottom: 6.25em;
}

.mobileItem{
  display: none;
}

body > .header {
  margin-bottom: 2.5em;
}

#cart-with-items {
  display: none;
  margin-bottom: 7.75em;
}

#cart-with-items > div {
  display: flex;
  gap: 3.75em;
  justify-content: space-between;
}

#cart-with-items > div > div {
  width: 21.25em;
}

#cart-with-items > div > table {
  width: 100%;
}

#cart-with-items > div > table {
  border-collapse: collapse;
}

#cart-with-items > div > table th {
  text-align: left;
  color: #8B8B8B;
  font-weight: 500;
  font-size: 0.875em;
}

#cart-with-items > div > table th.count {
  text-align: center;
}

#cart-with-items > div > table th.price {
  text-align: right;
}

#cart-with-items > div > table th {
  padding-bottom: 1.25em;
}

#cart-with-items > div > table tr td {
  border-top: 1px solid #DCDCDC;
  padding: 2.5em 0;
}

#cart-with-items > div > table td > img,
#cart-with-items > div > table tr td:first-child {
  width: 6.875em;
}

#cart-items-container > tr {
  height: 8.375em;
}

.name-and-manufacture, .cart-item-control {
  padding-left: 1.25em;
}

.cart-item-control img {
  height: 1.25em;
  width: 1.25em;
}

.cart-item-control > div {
  display: flex;
  gap: 0.5em;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.second-column {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 8.375em;
}

.cart-item-control {
  display: flex;
  align-items: end;
  gap: 2em;
  transform: translateY(-0.125em);
}

.name-and-manufacture .name {
  font-size: 1em;
  font-weight: 500;
  color: #111;
  margin-bottom: 0.5em;
}

.name-and-manufacture .manufacture {
  font-weight: 300;
  font-size: 1em;
}

.move-to-whitelist p, .remove-item p {
  font-size: 0.8125em;
}

p.value {
  display: flex;
  align-items: start;
  height: 8.375em;
  font-size: 1em;
  font-weight: 400;
}

p.value.count {
  justify-content: center;
}

p.value.price {
  font-weight: 500;
  justify-content: end;
}

p.value.count input {
  padding: 0.5625em 0.8125em;
  width: 0.6em;
  height: 1.125em;
  font-size: 1em;
  border: 1px solid #DCDCDC;
  outline: none;
  transform: translateY(-0.8125em);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 990px) {
  #cart-with-items > div {
    display: block;
  }

  .cart-title {
    display: flex;
    font-size: 24px;
    width: 100%;
    margin-bottom: 40px;
    text-align: start;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }

  #cart-with-items > div > table {
    width: 100%;
  }

  #cart-with-items > div > table tr td {
    border: none;
  }

  #cart-items-container td > div > img {
    width: 110px;
  }

  #cart-items-container td > div {
    display: grid;
    gap: 15px;
    grid-template-columns: auto 1fr;
  }

  #cart-items-container td > div > div.cart-item-control {
    grid-column: 1 / 3;
  }

  .mobileItem{
    display: flex;
    flex-direction: column;
    align-content: space-between;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    margin: 0px;
    height: 0px;
    flex-wrap: nowrap;
  }

  .empty-cart{
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    text-align: start !important;
  }

  .page-wrapper {
    padding: 0 16px;
  }

  .page-wrapper div {
    width: 100%;
   /* display: flex; */
   /* flex-direction: column; */
    text-align: start;
  }

  #cart-with-items > div > table tr td {

    padding-top: 0;
  }

  #cart-with-items > div > table tr td {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    align-items: center;
  }

  #cart-with-items > div > table {
    margin-bottom: 20px;
  }

  .name-and-manufacture .name,
  .name-and-manufacture .manufacture {
    font-size: 16px;
  }

  .name-and-manufacture .name {
    margin-bottom: 8px;
  }

  .name-and-manufacture, .cart-item-control {
    padding-left: 0;
    margin-top: 20px;
    align-items: flex-start;
  }

  p.value, p.value > span {
    height: initial;
    font-size: 16px;
    display: flex;
    width: 100%;
    align-items: end;
    align-content: flex-end;

  }

  p.value > span:first-child {
    color: #8B8B8B;
  }

  p.value.price {
    margin-top: 12px;
    margin-bottom: 16px;
    justify-content: flex-end;
    height: initial;
    font-size: 16px;
    display: flex;
    width: 100%;
    align-items: center;

  }

  .space-between, p.value.count.space-between {
    display: flex;
    justify-content: space-between;
  }

  p.value.count {
    margin-top: 8px;
    align-items: end;
  }

  p.value.count input {
    transform: none;
    height: 34px;
    width: 36px;
    display: flex;
    justify-content: center;
    padding: 0 12px;
  }

  .cart-item-control > div > p {
    font-size: 13px;
  }

  .cart-item-control > div > img {
    height: 20px;
    width: 20px;
  }

  .cart-item-control > div, .cart-item-control {
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
  }

  .cart-item-control {
    max-width: 281px;
    gap: 22px;
    grid-template-columns: auto 115px;
  }

  .cart-item-control {
    justify-content: initial;
    width: initial !important;
  }

  .remove-item {
    max-width: 114px;
  }

  #cart-with-items > div > div {
    width: 100%;
  }

}


</style>
