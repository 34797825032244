<script>
import { useCartStore } from "@/stores/product";

export default {
  name: "HeaderMain",
  props:{
    menu: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isMobileMenuOpen: false,
      searchQuery: "",

    };
  },
  computed: {
    cartItemCount() {
      const cartStore = useCartStore();
      return cartStore.cartItems.length;
    }
  },
  emits: ['updateSearchQuery', 'updateProductsByMenu'],
  methods: {

    updateSearchQuery(event) {
      this.searchQuery = event.target.value;
      // this.$emit('updateSearchQuery', event.target.value);
    },
    handleSearchSubmit(event) {
      event.preventDefault();
      if (this.searchQuery) {
        // Replace spaces with plus signs
        const formattedQuery = this.searchQuery.split(' ').join('+');
        window.location.href = `/shop?q=${formattedQuery}`;
      }
    },
    updateProductsByMenu(menuItemUrl) {
      this.$emit('updateProductsByMenu', menuItemUrl);
    },

    closeMobileMenu() {
      this.isMobileMenuOpen = false;
      document.getElementById('mobile-menu').classList.remove('open');
    },
    openMobileMenu() {
      this.isMobileMenuOpen = true;
      document.getElementById('mobile-menu').classList.add('open');
    }
  },
}
</script>

<template>
  <div class="mobile-top-text">
    We’re independently supported by our readers and we may earn a commission when you buy through our links.
  </div>
  <header class="header">
    <div class="header-wrapper desktop">
      <div class="first-part">
        <router-link to="/" class="header-logo" style="text-decoration: none">
          soxy
        </router-link>
        <form @submit="handleSearchSubmit" class="header-search">
          <div class="search-icon">
            <img src="../../assets/icons/searh.svg"/>
          </div>
          <input
              type="text"
              :value="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search for a brand or product..."
          >
        </form>
        <div class="header-navigation">
          <nav>
            <div class="navigation-wrapper">
              <router-link to="/shop" class="navigation-item shop-page">Shop</router-link>
              <a href="https://soxy.com/blogs/" class="navigation-item magazine-page">Magazine</a>
              <a href="https://soxy.com/blogs/about/" class="navigation-item aboutUs-page">About Us</a>
              <a href="https://help.soxy.com/" class="navigation-item help-page">Help</a>
            </div>
          </nav>
          <router-link v-if="cartItemCount > 0" to="/cart" class="navigation-bag">
            <img id="navigation-bag" src="@/assets/icons/bag.svg"/>
            <div id="count-items-in-cart">
              <span>{{ cartItemCount }}</span>
            </div>
          </router-link>
        </div>
      </div>
      <div class="separator"></div>
      <div class="second-part">
        <div class="header-categories" v-for="menuItem in menu" :key="menuItem.id">
          <a href="#" class="navigation-item-menu" @click="updateProductsByMenu(menuItem.url)">{{ menuItem.label }}</a>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="menu-and-search">
        <div class="menu">
          <input type="checkbox" class="menu-checkbox" id="menu-checkbox" @click="openMobileMenu">
          <div class="menu-toggle"></div>
          <div class="menu-content"></div>
        </div>
        <div class="search" style="display: none">
          <img src="../../assets/icons/searh.svg" alt="">
        </div>
      </div>
      <div class="logo-mobile">
        <img src="../../assets/icons/mobile-logo.svg" alt="mobile-logo">
      </div>
      <router-link v-if="cartItemCount > 0" to="/cart" class="bag" style="visibility: visible">
        <img src="../../assets/icons/bag.svg" alt="">
        <div id="count-items-in-cart">
          <span>{{ cartItemCount }}</span>
        </div>
      </router-link>
    </div>
    <div class="mobile" id="mobile-menu">
      <div class="close-menu-wrapper" @click="closeMobileMenu">
        <img src="../../assets/icons/cross.svg" class="close-mobile-menu" id="close-mobile-menu" >
      </div>
      <div class="logo">
        <img src="../../assets/icons/mobile-logo.svg" alt="mobile-logo">
      </div>
      <div class="mobile-navigation">
        <a href="/shop" class="navigation-item">Shop</a>
        <a href="/blogs" class="navigation-item">Magazine</a>
        <a href="/blogs/about/" class="navigation-item">About</a>
        <a href="http://help.soxy.com/" class="navigation-item">Help</a>
        <div class="separator"></div>
        <div class="header-categories">
          <a href="/shop/women" class="navigation-item">Women</a>
          <a href="/shop/men" class="navigation-item">Men</a>
          <a href="/shop/womens-shoes" class="navigation-item">Shoes</a>
          <a href="/shop/womens-bags" class="navigation-item">Bags</a>
          <a href="/shop/kids" class="navigation-item">Kids</a>
          <a href="/shop/home-living" class="navigation-item">Home &amp; Living</a>
          <a href="/shop/womens-beauty-products" class="navigation-item">Beauty</a>
          <a href="/shop/kids" class="navigation-item">Kids</a>
          <a href="/brands" class="navigation-item">Brands</a>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>



.header-categories {
  display: flex;
  gap: 60px;
}

.second-part {
  display: flex;
  justify-content: center;
}
.mobile-top-text{
  background: #f0efed;
  color: #111;
  font-size: 11px;
  font-weight: 400;
  line-height: 29px;
  margin: 0;
  padding: 5px 15px;
  text-align: center;
}



.navigation-item-menu {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  margin: 2.2em;
}

.navigation-item-menu:hover {
  text-decoration: underline;
}

.mobile-top-text{
  background: #f0efed;
  color: #111;
  font-size: 11px;
  font-weight: 400;
  line-height: 29px;
  margin: 0;
  padding: 5px 15px;
  text-align: center;
}

.navigation-item {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
}

.header-wrapper {
  /*  width: 77.5em; 1240px */
  max-width: 1260px;
  width: 100%;
}
.header {
  display: flex;
  justify-content: center;
  padding-top: 2.25em;
  padding-left: 50px;
  padding-right: 50px;
}
.header-logo {
  text-transform: uppercase;
  font-size: 2.375em; /* 38px */
  font-style: normal;
  font-weight: 900;
  line-height: 115%; /* 43.7px */
}

.search-icon img {
  height: 1.5em;
  width: 1.5em;
}

.header-wrapper .first-part {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 2em;
}

.header-search {
  display: flex;
}

.header-search input {
  border: none;
  outline: none;
  background-color: var(--light-gray);
  padding-top: 1.0625em; /* 17px */
  padding-bottom: 1em;
  width: 100%; /* 540px */
  padding-left: 0.75em;
  font-size: 1em;
  font-weight: 400;
}

.header-search .search-icon {
  background-color: var(--light-gray);
  display: flex;
  align-items: center;
  padding-left: 1em;
}

.header-navigation {
  display: flex;
  gap: 1.5em;
  align-items: center;
}

.shop-page:hover,
.magazine-page:hover,
.aboutUs-page:hover,
.help-page:hover{
  text-decoration: underline;
}

.separator{
  width: calc(100% - 135px);
  text-decoration: underline;
  height: 1px;
  position: relative;
  background-color: #000;
  padding-left: 23px;
  padding-right: 23px;
  margin-right: 23px;
}

.navigation-wrapper {
  display: flex;
  gap: 32px;
}

.header-wrapper .separator {
  width: 100%;
  height: 1px;
  background: #DCDCDC;
  margin-top: 2em;
}

.menu-toggle {
  height: 2px;
  background-color: #111111;
  width: 18px;
}

.menu-toggle:before, .menu-toggle:after {
  content: '';
  height: 2px;
  background-color: #111111;
  display: block;
}

.menu-toggle:before {
  transform: translateY(-7px);
}

.menu-toggle:after {
  transform: translateY(5px);
}

.menu-and-search .menu input {
  height: 18px;
  width: 20px;
  position: absolute;
  transform: translate(0, -8px);
  opacity: 0;
}

.cart-container {
  position: absolute;
  z-index: 1000;
  width: 27.125em;
  background-color: white;
  border: 1px solid #111;
  transform: translate(calc(-100% + 2em), 10px);
  padding: 2em;
}

.cart-container.closed {
  display: none;
}

.cart-container.opened {
  display: block;
}

.cart-header {
  text-align: center;
  font-size: 0.875em;
}

#cart-items > div:not(.separator) {
  display: flex;
  gap: 1.25em;
}
#cart-items > div:not(.separator) > img {
  width: 6.875em;
}
#cart-items > div:not(.separator) .name {
  font-weight: 500;
  font-size: 1em;
  margin-bottom: 0.5em;
}

#cart-items > div:not(.separator) .manufacture {
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 1.25em;
}

#cart-items > div:not(.separator) .parameters {
  display: flex;
  gap: 1em;
  margin-bottom: 1.25em;
}

#cart-items > div:not(.separator) .parameters > p {
  color: #8B8B8B;
}

#cart-items > div:not(.separator) .parameters > p,
#cart-items > div:not(.separator) .parameters > span {
  font-size: 0.875em;
}

#cart-items > div:not(.separator) .price {
  font-size: 1em;
  font-weight: 500;
}

#cart-items .separator {
  margin: 1.5em 0;
}

.cart-total {
  margin: 2em 0;
  display: flex;
  justify-content: space-between;
}

.cart-total > p {
  font-size: 1.125em;
}

#cart-total-price {
  font-weight: 900;
}

.cart-container .button {
  width: 100%;
  display: block;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  text-decoration: none;
}

#cart-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  display: none;
}

#count-items-in-cart {
  position: absolute;
  background-color: #111111;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  border: 2px solid white;
  transform: translate(0.875em, -1.125em);
}

#count-items-in-cart > span {
  font-size: 0.75em;
  color: white;
  transform: translateY(-1px);
}

#navigation-bag {
  width: 1.5em;
  height: 1.5em;
}

@media (max-width: 990px) {
  .close-mobile-menu{
    position: fixed;
    right: 18px;
    top: 28px;
    z-index: 9999;
  }
  .header-categories {
    top: 0px;
    gap: 24px;
    display: flex;
    left: 20px;
    width: calc(100% - 135px);
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    position: relative;
  }
  .mobile-navigation{
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100%;
    top: 100px;
    gap: 20px;
    left: 33px;
    padding-left: 20px;
  }

  #menu-checkbox{
    z-index: 999;
  }

  #mobile-menu {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    flex-direction: column;
    visibility: hidden;
    transition: right 0.3s ease, visibility 0.3s ease;
  }

  #mobile-menu.open {
    right: 0;
    visibility: visible;
  }
  .close-mobile-menu {
    position: fixed;
    right: 18px;
    top: 28px;
    z-index: 9999;
  }

  .mobile-top-text {
    line-height: 18px;
  }
  .second-part {
    display: none;
  }
  .header {
    margin: 0;
    padding: 0;
    width: 100%;
    display: block;
  }

  .header .mobile {
    display: flex !important;
    justify-content: space-between;
    padding: 18px 16px;
    align-items: center;
  }

  .header .mobile .menu-and-search {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .logo-mobile{
    display: flex;
   /* position: relative; */
    width: 100%;
    justify-content: center;
    padding: 20px 0;
  }

  .mobile .logo {
    display: flex;
    position: absolute;
    left: 0;
    width: 100%;
    top: 25px;
    justify-content: center;
    padding: 20px 0;
  }
  .header-wrapper .separator, #mobile-menu > .separator{
    position: absolute;
    display: flex;
    width: 80%;
    height: 1px;
    top: 250px;
    background: #DCDCDC;
    margin-top: 32px;
    margin-bottom: 32px;
    align-items: center;
    justify-content: center;
  }
}
</style>
