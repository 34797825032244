<script>
import HeaderMain from "@/components/main/HeaderMain.vue";
import FooterMain from "@/components/main/FooterMain.vue";
import axios from "axios";
import ProductPlaceholder from "@/components/UI/ProductPlaceholder.vue";
import {useAuthStore} from "@/stores/auth";
import StringProductPlaceholder from "@/components/UI/StringProductPlaceholder.vue";
import { API_BASE_URL } from '@/components/config/config';
import WishlistPopup from "@/components/popup/WishlistPopup.vue";

export default {
  name: "ShopCatalog",
  components: {WishlistPopup, StringProductPlaceholder, ProductPlaceholder, FooterMain, HeaderMain},
  data() {
    return {
      products: [],
      favorites: [],
      previousProducts: [],
      selectedProduct: [],
      colors: [],
      stores: [],
      prices: [],
      explosion: false,
      brands: [
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
      ],
      menu: [
        {id: 1, label: "WOMEN", url: ""},
        {id: 2, label: "MEN", url: ""},
        {id: 3, label: "SHOES", url: ""},
        {id: 4, label: "BAGS", url: ""},
        {id: 5, label: "KIDS", url: ""},
        {id: 6, label: "HOME & LIVING", url: ""},
        {id: 7, label: "BEAUTY", url: ""},
      ],
      categories: [
          {'': ''},
          {'': ''},
          {'': ''},
          {'': ''},
      ],
      categories_brand: [
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
        {'': ''},
      ],
      newProducts: [],
      visibleProducts: [],
      observer: null,
      currentPage: 1,
      itemsPerPage: 24,
      placeholdersCount: 24,
      total: 0,
      total_formatted: '',
      sale: '',
      currentProductId: null,
      loading: true,
      showWishlistPopup: false,
      dropdownOpen: false,
      dropdownToggled: false,
      isOpenMobile: true,
      expandedCategories: true,
      expandedBrands: true,
      expandedColors: true,
      expandedStores: true,
      selectedSorting: 'Newest',
      p1: 0,
      p2: 99999999,
      q: '',
      selectedColors: [],
      selectedStores: [],
      selectedBrands: [],
      selectedPrices: [],
      selectedMenus: [],
      selectedCategories: [],
      appliedFilters: [],
      originalBrands: [],
      originalCategories: [],
      originalProducts: [],
      originalColor: [],
      breadcrumbs: [
        {index: 0, name: "All", url: "/shop"}
      ],
      searchQueryBrands: '',
      searchQuery: '',
      selectedBrandName: '',
      selectedPriceCode: '',
      brandName: '',
      selectedMenuName: '',
      selectedCategoriesName: '',
      previousColors: [],
      previousStores: [],
      selectedColorName: '',
    };
  },
  computed: {
    isAllSelected() {
      return this.p1 === 0 && this.p2 === 99999999;
    },
    filterCount() {
      return this.selectedColors.length + this.selectedStores.length + this.selectedPrices.length;
    },
    filteredBreadcrumbs() {
      return this.breadcrumbs.filter(breadcrumb => breadcrumb.name !== 'All');
    },
    buildBreadcrumbTitle() {
      if (this.breadcrumbs.length === 0) return '';

      // Створюємо новий масив з відформатованими назвами хлібних крихт
      const categories = this.filteredBreadcrumbs.map((breadcrumb, index) => {
        let name = breadcrumb.name;
        if (name.toLowerCase() === 'women') {
          name = "Women's Fashion";
        } else if (name.toLowerCase() === 'men') {
          name = "Men's Fashion";
        }

        // Якщо це не останній елемент і містить "Fashion", видаляємо "Fashion"
        if (index < this.filteredBreadcrumbs.length - 1 && name.includes("Fashion")) {
          name = name.replace(" Fashion", "");
        }

        return name;
      });

      console.log(categories, 'CAT BREAD')
      console.log(categories.length, 'CAT BREAD LENGTH')
      // if (categories.length >= 3 && categories[1].toLowerCase()) {
      //   categories.splice(1, 1);
      // }

      // if (categories.length >= 4) {
      //   categories.splice(2, 1);
      // } else if (categories.length >= 3 && categories[2].toLowerCase()) {
      //   categories.splice(1, 1);
      // }

      if (categories.length >= 4) {
        // Меняем местами элементы 0 и 1
        [categories[0], categories[1]] = [categories[1], categories[0]];
        categories.splice(2, 1);
      } else if (categories.length === 3) {
        // Оставляем все элементы, но меняем местами 0 и 1
        [categories[0], categories[1]] = [categories[1], categories[0]];
      } else if (categories.length >= 3 && categories[2].toLowerCase()) {
        categories.splice(1, 1);
      } else if (categories.length === 2) {
        // Удаляем слово "Fashion" и меняем местами элементы 0 и 1
        categories[0] = categories[0].replace(" Fashion", "");
        categories[1] = categories[1].replace(" Fashion", "");
        [categories[0], categories[1]] = [categories[1], categories[0]];
      }


      const reorderedCategories = this.reorderCategories(categories);
      const formattedCategories = this.formatCategories(reorderedCategories);

      return formattedCategories.join(' ');
    },

    // formattedTotal() {
    //   return new Intl.NumberFormat('en-US', { style: 'decimal' }).format(this.total).replace(/\./g, ",");
    // }
  },
  created() {
    const authStore = useAuthStore();

    authStore.loadToken();
    const urlParams = new URLSearchParams(window.location.search);
    this.selectedBrandName = urlParams.get('brand');
    this.selectedCategoriesName = urlParams.get('categories');
    const colorsParam = urlParams.get('colors');
    if (colorsParam) {
      this.selectedColors = colorsParam.split(',');
    }
    const storesParam = urlParams.get('stores');
    if (storesParam) {
      this.selectedStores = storesParam.split(',');
    }
    const pricesParam = urlParams.get('prices');
    if (pricesParam) {
      this.selectedPrices = pricesParam.split(',');
    }
    const sortParam = urlParams.get('sort');
    if (!this.selectedSorting || this.selectedSorting === '') {
      this.selectedSorting = 'Newest';
    } else if (this.selectedSorting !== 'Highest' && this.selectedSorting !== 'Low') {
      this.selectedSorting = sortParam || 'Newest';
    }

    const q = urlParams.get('q');
    const sale = urlParams.get('sale');

    if (q) this.q = q;
    if (q) {
      this.updateSearchQuery(q);
    }

    if (sale) this.sale = sale;
    const storedBrandName = sessionStorage.getItem('selectedBrandName');
    console.log(storedBrandName, 'ITS storedBrandName');
    const storedCategoriesName = sessionStorage.getItem('selectedCategoriesName');
    console.log(storedCategoriesName);
    if (storedBrandName) {
      this.selectedBrandName = storedBrandName;
    } else if (this.selectedBrands.length > 0) {
      this.selectedBrandName = this.selectedBrands[0].name;
    }
    if (storedCategoriesName) {
      this.selectedCategoriesName = storedCategoriesName;
    } else if (this.selectedBrands.length > 0) {
      this.selectedCategoriesName = this.selectedBrands[0].name;
    }
    this.selectSorting(this.selectedSorting);

  },
  watch: {
    searchQuery(newQuery) {
      if (!newQuery) {
        this.products = [...this.previousProducts];
      }
    }
  },
  methods: {
    reorderCategories(categories) {
      const womenIndex = categories.findIndex(category => category.toLowerCase().includes('women'));
      if (womenIndex !== -1 && womenIndex !== 0) {
        // Если "Women" не находится в начале, перемещаем его в начало
        const womenCategory = categories.splice(womenIndex, 1)[0];
        categories.unshift(womenCategory);
      }
      return categories;
    },

    formatCategories(categories) {
      const formattedCategories = categories.map(category => {
        // Добавляем апостроф к "Women", если его еще нет
        if (category.toLowerCase().startsWith('women') && !category.toLowerCase().startsWith("women's")) {
          return "Women's " + category.substring("Women".length).trim();
        }
        return category;
      });
      return formattedCategories;
    },

    async fetchFilters() {
      try {
        const response = await axios.get(`${API_BASE_URL}/filters`);
        console.log(response, 'MOUNTED BRANDS LIST');
        if (response.data && response.data.data && response.data.data.brands) {
          this.brands = Array.isArray(response.data.data.brands) ? response.data.data.brands : Object.values(response.data.data.brands);
        }

        console.log(this.brands, 'BRANDS FILTER');

        this.colors = response.data.data.colors || [];
        this.stores = response.data.data.stores || [];
        this.prices = response.data.data.prices || [];
        console.log(this.prices, 'PRICES FILTER');
      } catch (error) {
        console.error('Error fetching color data:', error);
      }
    },

    async fetchMenuLink() {
      try {
        const response = await axios.get(`${API_BASE_URL}/menu/main`)
        this.menu = response.data.data
        console.log(this.menu, 'MENU BAR');
        this.total_formatted = response.data.total_formatted;

        return response;
      } catch (e) {
        console.error('Error fetching menu data:', e);
      }
    },

    updateProductsByMenu(menuItem) {
      this.selectedBrands = [];
      this.selectedCategories = [];
      this.selectedBrands.push(menuItem);
      this.selectedCategories.push(menuItem);
      this.previousColors = [...this.selectedColors];
      this.searchQuery = '';
      this.previousColors = [];
      this.selectedMenuName = menuItem;
      sessionStorage.setItem('selectedMenuName', menuItem);
      const menu = this.menu.find(menu => menu.url === menuItem);
      if (menu) {
        const menuColors = menu.colors;
        if (menuColors && menuColors.length > 0) {
          menuColors.forEach(color => {
            if (!this.selectedColors.includes(color)) {
              this.selectedColors.push(color);
            }
          });
        }
        if (menu) {
          this.selectedMenuName = menu.name;
        }

        this.updateURLWithColors();
        this.updateURLWithStores();
        this.updateURLWithPrices();
        this.updateProducts();
        const urlParams = new URLSearchParams(window.location.search);
        const colorsParam = urlParams.get('colors');
        const storesParam = urlParams.get('stores');
        const sortParam = urlParams.get('sort');
        const pricesParam = urlParams.get('prices');
        const q = urlParams.get('q');
        const saleParam = this.sale;

        let queryParams = new URLSearchParams();

        if (colorsParam) queryParams.append('colors', colorsParam);
        if (storesParam) queryParams.append('stores', storesParam);
        if (sortParam) queryParams.append('sort', sortParam);
        if (pricesParam) queryParams.append('prices', pricesParam);
        if (saleParam) queryParams.append('sale', saleParam);
        if (q) queryParams.append('q', q);

        if (this.q){
          urlParams.append('q', this.q);
        }
        queryParams.delete('q');

        const queryString = queryParams.toString();
        const newUrl = queryString ? `${menuItem}?${queryString}` : menuItem;
        // const newUrl = `${menuItem}?${queryParams.toString()}`;
        this.$router.replace(newUrl);
      }
    },

    updateSelectedBrand(brandId, brandName) {
      console.log(brandName, brandId, 'Selected BRAND');
      this.products = [];
      this.selectedBrands = [];
      this.selectedCategories = [];
      this.searchQuery = '';

      if (!this.selectedBrands.includes(brandId)) {
        this.selectedBrands.push(brandId);
        this.previousColors = [...this.selectedColors];
        this.previousStores = [...this.selectedStores];
        this.selectedBrandName = brandId;
      }
      this.updateURLWithColors();
      this.updateURLWithStores();
      this.updateURLWithPrices();
      this.updateProducts();
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete('q');
      const newUrl = `${brandId}?${queryParams.toString()}`;
      this.$router.push(newUrl);
    },

    updateProductsByBrand(brandId) {
      this.selectedBrands = [];
      this.selectedBrands.push(brandId);
      this.products = [...this.newProducts];
      this.previousColors = [...this.selectedColors];

      this.previousColors = [];
      this.previousStores = [...this.selectedStores];
      this.previousStores = [];
      this.selectedBrandName = brandId;
      this.searchQuery = '';
      this.searchQueryBrands = '';

      const brand = this.brands.find(brand => brand.url === brandId);
      if (brand) {
        const brandColors = brand.colors;
        const brandStores = brand.stores;
        const brandPrices = brand.prices;

        if (brandStores && brandStores.length > 0) {
          brandStores.forEach(store => {
            if (!this.selectedStores.includes(store)) {
              this.selectedStores.push(store);
            }
          });
        }

        if (brandColors && brandColors.length > 0) {
          brandColors.forEach(color => {
            if (!this.selectedColors.includes(color)) {
              this.selectedColors.push(color);
            }
          });
        }

        if (brandPrices && brandPrices.length > 0) {
          brandPrices.forEach(price => {
            if (!this.selectedPrices.includes(price)) {
              this.selectedPrices.push(price);
            }
          });
        }

        // if (brandPrices && brandPrices.length > 0) {
        //   this.selectedPrices = brandPrices.map(price => `${price}`);
        // }
      }
      this.updateURLWithColors();
      this.updateURLWithStores();
      this.updateURLWithPrices();
      // this.updateURL();
      this.updateProducts();

      const urlParams = new URLSearchParams(window.location.search);
      const colorsParam = urlParams.get('colors');
      const storesParam = urlParams.get('stores');
      const sortParam = urlParams.get('sort');
      const pricesParam = urlParams.get('prices');
      const saleParam = this.sale;

      let queryParams = '';
      if (colorsParam) queryParams += `&colors=${colorsParam}`;
      if (storesParam) queryParams += `&stores=${storesParam}`;
      if (sortParam) queryParams += `&sort=${sortParam}`;
      if (pricesParam) queryParams += `&prices=${pricesParam}`;
      if (saleParam) queryParams += `&sale=${saleParam}`;


      const newUrl = queryParams ? `${brandId}?${queryParams.toString()}` : brandId;
      this.$router.replace(newUrl);
    },

    updateProductsByCategory(categoryId) {
      this.selectedBrands = [];
      // this.selectedStores = [];
      this.selectedBrands.push(categoryId);
      this.previousColors = [...this.selectedColors];
      this.previousColors = [];
      this.previousStores = [...this.selectedStores];
      this.previousStores = [];
      this.selectedCategoriesName = categoryId;
      sessionStorage.setItem('selectedCategoriesName', categoryId);
      this.searchQuery = '';
      this.searchQueryBrands = '';


      const category = this.categories.find(category => category.url === categoryId);
      if (category) {
        const categoryColors = category.colors;
        if (categoryColors && categoryColors.length > 0) {
          categoryColors.forEach(color => {
            if (!this.selectedColors.includes(color)) {
              this.selectedColors.push(color);
            }
          });
        }
        if (category) {
          this.selectedCategoriesName = category.name;
        }
        this.updateURLWithColors();
        this.updateURLWithStores();
        this.updateURLWithPrices();
        this.updateProducts();

        const urlParams = new URLSearchParams(window.location.search);
        const colorsParam = urlParams.get('colors');
        const storesParam = urlParams.get('stores');
        const sortParam = urlParams.get('sort');
        const pricesParam = urlParams.get('prices');
        const saleParam = this.sale;

        let queryParams = '';
        if (colorsParam) queryParams += `&colors=${colorsParam}`;
        if (storesParam) queryParams += `&stores=${storesParam}`;
        if (sortParam) queryParams += `&sort=${sortParam}`;
        if (pricesParam) queryParams += `&prices=${pricesParam}`;
        if (saleParam) queryParams += `&sale=${saleParam}`;

        const newUrl = queryParams ? `${categoryId}?${queryParams.toString()}` : categoryId;
        this.$router.replace(newUrl);
      }
    },

    async fetchProducts() {
      try {
        this.loading = true;
        const colorsQuery = this.selectedColors.join(',');
        const storesQuery = this.selectedStores.join(',');
        const pricesQuery = this.selectedPrices.join(',');
        const brandsQuery = this.selectedBrands.join(',');
        const searchQuery = this.searchQuery.trim();

        const response = await axios.get(`${API_BASE_URL}/products${brandsQuery}?page=${this.currentPage}&per_page=${this.itemsPerPage}&sort=${this.selectedSorting}&colors=${colorsQuery}&stores=${storesQuery}&prices=${pricesQuery}&q=${searchQuery}${this.sale ? `&sale=${this.sale}` : ''}`);
        console.log(response, 'RESPONSE ALL');
        console.log(response.data.data, 'RESPONSE DATA DATA');
        console.log(response.data.brands, 'RESPONSE DATA BRANDS');
        const newProducts = response.data.data.map(product => ({...product, loaded: true}));
        if (this.currentPage === 1) {
          this.products = response.data.data;
        } else {
          const newProducts = response.data.data.filter(newProduct => !this.products.some(existingProduct => existingProduct.id === newProduct.id));
          this.products.push(...newProducts);
        }

        if (this.currentPage === 1 || !this.categories.length) {
          this.products = response.data.data;
          this.categories = response.data.categories || [];
          this.originalBrands = response.data.brands || [];
          this.categories_brand = response.data.brands || [];
          this.breadcrumbs = response.data.breadcrumbs || [];
          this.products = [...newProducts];
          this.previousProducts = [...this.products];
        }
        if (response.data.total_formatted) {
          this.total_formatted = response.data.total_formatted;
          localStorage.setItem('total_formatted', this.total_formatted);
        }
        console.log( this.breadcrumbs, ' this.breadcrumbs')
        this.currentPage++;
        this.total_formatted = response.data.total_formatted;
        localStorage.setItem('total_formatted', this.total_formatted);
        this.total = response.data.total;

      } catch (error) {
        console.error('Error fetching product data:', error);

      } finally {
        if (!this.products || this.products.length === 0) {
          this.total = 0;
        }
        this.loading = false;
      }
    },

    updateFilter(filterType, filterValue) {
      if (this.isSelected(filterValue, filterType)) {
        this.addFilter(filterType, filterValue);
      } else {
        this.removeFilter(filterType, filterValue);
      }

      if (filterType === "Colors") {
        this.updateURLWithColors();
      }
      if (filterType === "Stores") {
        this.updateURLWithStores();
      }
      if (filterType === "Prices") {
        this.updateURLWithPrices();
      }
      this.updateProducts();
    },
    isSelected(filterValue, filterType) {
      if (filterType === "Colors") {
        return this.selectedColors.includes(filterValue);
      } else if (filterType === "Stores") {
        return this.selectedStores.includes(filterValue);
      } else if (filterType === "Prices") {
        return this.selectedPrices.includes(filterValue);
      }
      return false;
    },
    addFilter(filterType, filterValue) {
      const filterName = filterType === "Colors" ? this.getColorName(filterValue) : this.getColorName(filterValue);
      this.appliedFilters.push({type: filterType, value: filterValue, name: filterName});

      const filterStores = filterType === "Stores" ? this.getStoreName(filterValue) : this.getStoreName(filterValue);
      this.appliedFilters.push({type: filterType, value: filterValue, name: filterStores});

      const filterPrices = filterType === "Prices" ? this.getPriceName(filterValue) : this.getPriceName(filterValue);
      this.appliedFilters.push({type: filterType, value: filterValue, name: filterPrices});
      this.updateProducts();
    },
    getColorName(code) {
      const color = this.colors.find(c => c.code === code);
      return color ? color.name : "";
    },

    getStoreName(code) {
      const store = this.stores.find(s => s.code === code);
      return store ? store.name : "";
    },

    getPriceName(code) {
      const price = this.prices.find(p => p.code === code);
      return price ? price.name : "";
    },

    removeFilter(filterType, filterValue) {
      if (filterType === "Colors") {
        const colorIndex = this.selectedColors.indexOf(filterValue);
        if (colorIndex !== -1) {
          this.selectedColors.splice(colorIndex, 1);
        }
      } else if (filterType === "Stores") {
        const storeIndex = this.selectedStores.indexOf(filterValue);
        if (storeIndex !== -1) {
          this.selectedStores.splice(storeIndex, 1);
        }
      } else if (filterType === "Prices") {
        const priceIndex = this.selectedPrices.indexOf(filterValue);
        if (priceIndex !== -1) {
          this.selectedPrices.splice(priceIndex, 1);
        }
      }


      this.updateURL();
      this.updateProducts();
    },
    updateURL() {
      const urlParams = new URLSearchParams(window.location.search);

      if (this.selectedColors.length > 0) {
        urlParams.set('colors', this.selectedColors.join(','));
      } else {
        urlParams.delete('colors');
      }

      if (this.selectedStores.length > 0) {
        urlParams.set('stores', this.selectedStores.join(','));
      } else {
        urlParams.delete('stores');
      }

      if (this.selectedPrices.length > 0) {
        urlParams.set('prices', this.selectedPrices.join(','));
      } else {
        urlParams.delete('prices');
      }

      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
    },

    createObserver() {
      this.observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && this.total > 24 && this.currentPage < Math.ceil(this.total / this.itemsPerPage)) {
          this.fetchProducts(this.selectedSorting);
        }
      });
      this.observer.observe(this.$refs.observer);
    },

    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      this.dropdownToggled = true;
    },

    toggleFilter() {
      this.isOpenMobile = !this.isOpenMobile;
    },

    async globalUpdate(query, p1 = 0, p2 = 0) {
      try {
        let url = `${API_BASE_URL}/products?page=${this.currentPage}&per_page=${this.itemsPerPage}&sale=${this.sale}`;
        if (query.trim() !== '') {
          url += `&q=${query}`;
        }
        if (p1 > 0 && p2 > 0) {
          url += `&p1=${p1}&p2=${p2}`;
        }
        const response = await axios.get(url);
        if (response && response.data && response.data.data) {
          this.products = response.data.data;
          this.currentPage = 1;
          this.total = response.data.total;
        } else {
          this.products = [];
        }

        this.newProducts = response.data.data.filter(newProduct => !this.products.some(existingProduct => existingProduct.id === newProduct.id));
        this.products.push(...this.newProducts);

        this.currentPage++;

        // Update the URL with the search query
        const queryParams = new URLSearchParams(window.location.search);
        if (query.trim() === '') {
          queryParams.delete('q');

          // Reload the page if searchQuery is empty
          this.resetFilters(query);
        } else {
          queryParams.set('q', query);
        }
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        this.$router.replace(newUrl);

      } catch (error) {
        console.error('Error searching products:', error);
        this.products = [];
      }
    },


    async searchBrands(query) {
      try {
        const response = await axios.get(`${API_BASE_URL}/brands/search?brand=${query}`);
        return response.data.data;
      } catch (error) {
        console.error('Error searching brands:', error);
        return {};
      }
    },
    updateBrands(query) {
      if (query.trim() === '') {
        this.fetchProducts();
        this.categories_brand = this.originalBrands;
        this.searchQueryBrands = '';
        return;
      }
      this.searchBrands(query)
          .then(searchResults => {
            if (searchResults && Object.keys(searchResults).length > 0) {
              this.categories_brand = Object.values(searchResults);
            } else {
              this.categories_brand = [];
            }
          })
          .catch(error => {
            console.error('Error searching brands:', error);
            this.categories_brand = [];
          });
    },

    navigateToProductDetail(product) {
      let newUrl = product.url_to_detail;
      if (useAuthStore().gclid) {
        if (product.url_to_detail.includes('?')) {
          newUrl += `&gclid=${useAuthStore().gclid}`;
        } else {
          newUrl += `?gclid=${useAuthStore().gclid}`;
        }
      } else {
        newUrl = this.clearGclid(newUrl);
      }
      this.$router.push(newUrl);
    },
    clearGclid(url) {
      const regex = /[?&]gclid=[^&#]+/;
      const updatedUrl = url.replace(regex, '');
      return updatedUrl.replace(/[?&]$/, '');
    },
    toggleExpanded(section) {
      switch (section) {
        case 'categories':
          this.expandedCategories = !this.expandedCategories;
          this.dropdownToggled = true;
          break;
        case 'categories_brand':
          this.expandedBrands = !this.expandedBrands;
          this.dropdownToggled = true;
          break;
        case 'colors':
          this.expandedColors = !this.expandedColors;
          this.dropdownToggled = true;
          break;
        case 'stores':
          this.expandedStores = !this.expandedStores;
          this.dropdownToggled = true;
          break;
        default:
          break;
      }
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },

    // closeWindow(e) {
    //   if (e.target.classList.contains('overlay')) {
    //     this.dropdownOpen = false;
    //   }
    // },

    resetFilters(brandId) {
      this.selectedColors = [];
      this.selectedStores = [];
      this.selectedPrices = [];
      this.selectedSorting = 'Newest';
      this.selectedBrands = [];
      this.appliedFilters = [];
      this.categories_brand = [];
      this.searchQuery = '';

      this.updateProducts(brandId);
      const route = `/shop`;
      this.$router.push(route);
    },

    handleAllClick() {
      this.sale = '';
      this.selectedPrices = [];
      this.updateProducts();
      // this.updateURL();
    },

    updateSearchQuery(query) {

      this.$router.push({ path: '/shop' });
      this.searchQuery = query;
      this.products = [];
      this.currentPage = [];
      // this.selectedBrands = [];
      // this.selectedProduct = [];
      this.categories = [];
      //   const urlParams = new URLSearchParams(window.location.href);
      //   urlParams.delete('q');
      //   const newUrl = `${window.location.href}?${urlParams.toString()}`;
      //   window.history.replaceState({}, '', newUrl);
      // this.updateURLWithColors();
      // this.updateURLWithStores();
      this.fetchFilters();
      this.globalUpdate(query);
      // this.fetchProducts(this.selectedSorting);
      // this.selectSorting(this.selectedSorting)
    },


    updateProducts() {
      this.products = [];
      this.currentPage = 1;
      this.selectSorting(this.selectedSorting);
      this.fetchProducts(this.selectedSorting);
    },

    updateURLWithFilters(filters) {
      const params = new URLSearchParams(window.location.search);
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          params.set(key, filters[key]);
        } else {
          params.delete(key);
        }
      });

      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.pushState({}, '', newUrl);
    },

    selectSorting(sortOption) {
      this.products = [];
      this.selectedSorting = sortOption;

      console.log(sortOption, 'SORTING2');
      console.log(this.selectedSorting, 'SORTING');

      this.currentPage = 1;

      const currentPath = this.$route.path;
      const currentBrand = this.$route.query.categories_brand || '';
      const currentCategories = this.$route.query.categories || '';
      const currentMenu = this.$route.query.menu || '';
      const colorsString = this.selectedColors.length ? this.selectedColors.join(',') : null;
      const storesString = this.selectedStores.length ? this.selectedStores.join(',') : null;
      const pricesString = this.selectedPrices.length ? this.selectedPrices.join(',') : null;

      const urlParams = new URLSearchParams();

      if (currentBrand) {
        urlParams.set('categories_brand', currentBrand);
      }
      if (currentCategories) {
        urlParams.set('categories', currentCategories);
      }
      if (currentMenu) {
        urlParams.set('menu', currentMenu);
      }
      if (colorsString) {
        urlParams.set('colors', colorsString);
      }
      if (storesString) {
        urlParams.set('stores', storesString);
      }
      if (pricesString) {
        urlParams.set('prices', pricesString);
      }
      if (this.q) {
        urlParams.set('q', this.q);
      }

      if (['newest', 'low', 'high'].includes(sortOption)) {
        urlParams.set('sort', sortOption);
      } else {
        urlParams.delete('sort');
      }

      const queryString = urlParams.toString();
      const newUrl = `${currentPath}${queryString ? `?${queryString}` : ''}`;

      if (!['newest', 'low', 'high'].includes(sortOption)) {
        this.dropdownOpen = false;
      }

      this.$router.replace(newUrl);
    },

    updateURLWithSale() {
      const urlParams = new URLSearchParams(window.location.search);
      if (this.sale) {
        urlParams.set('sale', this.sale);
      } else {
        urlParams.delete('sale');
      }
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      this.$router.replace(newUrl);
    },

    toggleSaleFilter() {
      this.sale = this.sale === 'sale' ? '' : 'sale';
      this.updateProducts();
      this.updateURLWithSale();
    },

    updateURLWithColors() {
      const colorsString = this.selectedColors.join(',');
      const currentPath = this.$route.path;
      const urlParams = new URLSearchParams(window.location.search);
      if (colorsString) {
        urlParams.set('colors', colorsString);
        if (!this.selectedStores.length && !this.selectedPrices.length && !this.selectedSorting) {
          urlParams.delete('sort');
        }
      } else {
        urlParams.delete('colors');
        urlParams.delete('sort');
      }
      const queryString = urlParams.toString();
      const newUrl = `${currentPath}${queryString ? `?${queryString}` : ''}`;
      this.$router.replace(newUrl);
    },

    updateURLWithStores() {
      const storesString = this.selectedStores.join(',');
      const currentPath = this.$route.path;
      const urlParams = new URLSearchParams(window.location.search);
      if (storesString) {
        urlParams.set('stores', storesString);
      } else {
        urlParams.delete('stores');
      }
      const queryString = urlParams.toString();
      const newUrl = `${currentPath}${queryString ? `?${queryString}` : ''}`;
      this.$router.replace(newUrl);
    },

    updateURLWithPrices(){
      const pricesString = this.selectedPrices.join(',');
      const currentPath = this.$route.path;
      const urlParams = new URLSearchParams(window.location.search);
      if (pricesString) {
        urlParams.set('prices', pricesString);

      } else {
        urlParams.delete('prices');
      }
      const queryString = urlParams.toString();
      const newUrl = `${currentPath}${queryString ? `?${queryString}` : ''}`;
      this.$router.replace(newUrl);
    },


    navigateToBreadcrumb(url) {
      // Отримуємо поточний URL
      const currentUrl = new URL(window.location.href);

      // Отримуємо поточні параметри URL
      const currentParams = currentUrl.searchParams;

      // Видаляємо параметр 'q', якщо він є
      currentParams.delete('q');

      // Розкодовуємо значення параметрів, які можуть бути закодовані
      const decodedParams = {};
      for (const [key, value] of currentParams.entries()) {
        decodedParams[key] = decodeURIComponent(value);
      }

      // Створюємо новий URL з поточною адресою сторінки та збереженими параметрами
      let newUrl = `${currentUrl.origin}${url}?`;
      Object.entries(decodedParams).forEach(([key, value], index) => {
        newUrl += `${key}=${value}`;
        if (index < Object.entries(decodedParams).length - 1) {
          newUrl += "&";
        }
      });

      // Перенаправляємо на новий URL
      window.location.href = newUrl;
    },
    hideWishlistPopup() {
      this.showWishlistPopup = false;
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
    async addToFavorites(productId) {
      try {
        const authStore = useAuthStore();
        authStore.loadToken();
        const token = authStore.token;

        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const previousLength = this.favorites.length;

        if (previousLength > 0) {
          const response = await axios.post(`${API_BASE_URL}/favorite/add`, {
            product_id: productId
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          console.log(response);

          await this.loadFavorites();
        }

        if (previousLength === 0) {
          console.log('Showing WishlistPopup');
          this.currentProductId = productId;
          this.showWishlistPopup = true;
        }
      } catch (error) {
        console.error('Error adding to favorites:', error);
      }
    },
    async loadFavorites() {
      try {
        const authStore = useAuthStore();
        authStore.loadToken();
        const token = authStore.token;

        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const response = await axios.get(`${API_BASE_URL}/favorite/list`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        // Ensure the response is an array
        if (Array.isArray(response.data.data)) {
          this.favorites = response.data.data;
        } else {
          console.error('Favorites list is not an array');
          this.favorites = [];
        }

        console.log(response);
      } catch (error) {
        console.error('Error loading favorites:', error);
        this.favorites = []; // Ensure favorites is an array even on error
      }
    },
    isFavorite(productId) {
      return this.favorites.some(favorite => favorite.id === productId);
    }
  },

  mounted() {
    this.loadFavorites();
    const authStore = useAuthStore();
    if (authStore.gclid) {
      const currentUrl = new URL(window.location.href);
      if (!currentUrl.searchParams.has('gclid')) {
        currentUrl.searchParams.append('gclid', authStore.gclid);
        this.$router.push(currentUrl.toString());
      }
    }
    // authStore.loadToken();
    console.log(this.$router);
    const totalFormattedFromLocalStorage = localStorage.getItem('total_formatted');
    if (this.total_formatted === '' && totalFormattedFromLocalStorage) {
      this.total_formatted = totalFormattedFromLocalStorage;
    }
    if (this.$route.path === '/shop') {
      this.fetchProducts(this.selectedSorting);
    } else {
      this.updateProductsByMenu(this.$route.path);
      this.updateProductsByBrand(this.$route.path);
      this.updateProductsByCategory(this.$route.path);
      this.updateSelectedBrand(this.$route.path);
      this.updateURLWithColors();
      this.updateURLWithStores();

      this.selectSorting(this.selectedSorting);
      this.updateURLWithSale();
    }
    document.addEventListener('click', this.handleClickOutside);
    // const storedBreadcrumbs = localStorage.getItem('breadcrumbs');
    // if (storedBreadcrumbs) {
    //   this.breadcrumbs = JSON.parse(storedBreadcrumbs);
    // }
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = this.$route.query.q;
    if (searchQuery) {
      this.searchQuery = searchQuery;
      this.globalUpdate(this.searchQuery);
    }

    const colorsParam = urlParams.get('colors');
    const storesParam = urlParams.get('stores');
    const storesPrice = urlParams.get('prices');
    const sort = urlParams.get('sort');

    if (colorsParam) {
      this.selectedColors = colorsParam.split(',');
    }
    if (storesParam) {
      this.selectedStores = storesParam.split(',');
    }
    if (storesPrice) {
      this.selectedPrices = storesPrice.split(',');
    }

    const gclid = urlParams.get('gclid');

    if (gclid) {
      console.log('Parameter gclid exists:', gclid);

      this.$route.meta.gclid = gclid;
    } else {
      console.log('Parameter gclid does not exist or is empty.');
    }
    // this.fetchProducts(this.selectedSorting);
    this.fetchProducts(sort);
    this.fetchFilters(); //MOUNT BRANDS LIST
    this.updateURLWithColors();
    this.updateURLWithStores();
    this.createObserver(sort);
    this.fetchMenuLink();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<template>
  <WishlistPopup :visible="showWishlistPopup" :productId="currentProductId" @favoriteAdded="loadFavorites" @closePopup="hideWishlistPopup" />
  <HeaderMain
      :menu="menu"
      @update-search-query="updateSearchQuery"
      @update-products-by-menu="updateProductsByMenu"
  />
  <div class="page-wrapper">
    <div>

      <div class="categories-content">
        <div :class="['categories-filter', isOpenMobile ? 'closed' : 'opened']" id="categories-filter">
          <div class="categories-name desktop">
            <p @click="resetFilters" class="breadCrumbs">All /&nbsp;</p>
            <span v-for="(breadcrumb, index) in filteredBreadcrumbs" :key="index" class="breadCrumbs">
          <span></span>
          <p @click="navigateToBreadcrumb(breadcrumb.url)" class="breadCrumbs">{{ breadcrumb.name }} / &nbsp;</p>
        </span>
          </div>

          <!--          TOTAL COUNT -->

          <div v-if="loading" class="products-container"  style="margin-bottom: 2.5em;">
            <StringProductPlaceholder style="width: 220px; padding-bottom: 0px;"/>
          </div>
          <div v-if="!loading" class="count-of-products desktop">{{ this.total_formatted }} <p style="color: #8B8B8B; margin-left: 5px">
            items</p></div>
          <div class="mobile">
            <div class="filter-title" @click="toggleFilter">
              <p>Filter</p>
              <img id="close-filters" src="@/assets/icons/cross.svg">
            </div>
          </div>

          <!--          CATEGORIES-->
          <div class="accessories filter-group filter-with-count  filter-category">
            <p class="filter-title filter-with-hiding" @click="toggleExpanded('categories')">
              <span>Category</span>
              <img
                  :src="expandedCategories ? require('@/assets/icons/chevron-down.svg') : require('@/assets/icons/chevron-right.svg')"
                  alt="chevron"/>
            </p>
            <div class="filter-group-content" id="categoriesList"
                 :class="{ open: expandedCategories, close: !expandedCategories && dropdownToggled }">
              <div v-for="category in categories" :key="category.id" class="filter-group-content" id="categoriesList">
                <div class="filtering-group" @click="updateProductsByCategory(category.url)">
                  <div v-if="loading" class="products-container">
                    <StringProductPlaceholder style="width: 220px; padding-bottom: 0px;" />
                  </div>

                  <p v-if="!loading" class="name">{{ category.name }}</p>
                  <p v-if="!loading" class="count">{{ category.count }}</p>
                </div>
              </div>
            </div>
          </div>

          <!--         BRANDS -->
          <div class="brands filter-group filter-with-count">
            <p class="filter-title filter-with-hiding" @click="toggleExpanded('categories_brand')">
              <span>Brands</span>
              <img
                  :src="expandedBrands ? require('@/assets/icons/chevron-down.svg') : require('@/assets/icons/chevron-right.svg')"
                  alt="chevron"/>
            </p>
            <div class="brands-search">
              <img src="@/assets/icons/searh.svg" alt="search">
              <input type="text" placeholder="Search" v-model="searchQueryBrands"
                     @input="updateBrands(searchQueryBrands)">
            </div>
            <div :class="{ open: expandedBrands, close: !expandedBrands && dropdownToggled }">
              <div v-for="brand in categories_brand" :key="brand.id" class="filter-group-content">
                <div class="filtering-group" @click="updateProductsByBrand(brand.url)">
                  <div v-if="loading" class="products-container">
                    <StringProductPlaceholder style="width: 220px; padding-bottom: 0px;"/>
                  </div>

                  <p v-if="!loading" class="name">{{ brand.name }}</p>
                  <p v-if="!loading" class="count">{{ brand.count }}</p>
                </div>
              </div>
            </div>
          </div>

          <!--         COLORS -->
          <div class="colors filter-group filter-with-checkbox">
            <p class="filter-title filter-with-hiding" @click="toggleExpanded('colors')" ref="dropdown">
              <span>Colors</span>
              <img
                  :src="expandedColors ? require('@/assets/icons/chevron-down.svg') : require('@/assets/icons/chevron-right.svg')"
                  alt="chevron"/>
            </p>
            <div :class="{ open: expandedColors, close: !expandedColors && dropdownToggled }">
              <form id="colors-form" class="filter-form">
                <div class="filtering-group-colors">
                  <div v-for="color in colors" :key="color.id" class="container-filter">
                    <input
                        type="checkbox"
                        :id="'color-' + color.id"
                        :value="color.code"
                        v-model="selectedColors"
                        @change="updateFilter('Colors', color.code)"
                    >
                    <span class="checkmark">
                    <img src="@/assets/icons/checked.svg" alt="checked" v-if="selectedColors.includes(color.code)">
                  </span>
                    <p class="name">{{ color.name }}</p>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <!--         STORES -->
          <div class="stories filter-group filter-with-checkbox">
            <p class="filter-title filter-with-hiding" @click="toggleExpanded('stores')" ref="dropdown">
              <span>Stores</span>
              <img
                  :src="expandedStores ? require('@/assets/icons/chevron-down.svg') : require('@/assets/icons/chevron-right.svg')"
                  alt="chevron"/>
            </p>
            <div :class="{ open: expandedStores, close: !expandedStores && dropdownToggled }">
              <form id="stories-form" class="filter-form">
                <div class="filtering-group-stores">
                  <div v-for="store in stores" :key="store.id" class="container-filter">
                    <input
                        type="checkbox"
                        :id="'store-' + store.id"
                        :value="store.code"
                        v-model="selectedStores"
                        @change="updateFilter('Stores', store.code)">

                    <span class="checkmark">
                     <img src="@/assets/icons/checked.svg" alt="checked" v-if="selectedStores.includes(store.code)">
                    </span>
                    <p class="name">{{ store.name }}</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="categories-products" id="categories-products">
          <div class="bread-crumbs-container">
            <div v-if="loading" class="products-container" style="margin-left: 10px;">
              <StringProductPlaceholder style="height: 0%; width: calc(40vw - 100px); padding-bottom: 0px;" />
            </div>
            <h1 class="categories-title-update desktop" v-if="!loading && !searchQuery && !selectedBrands.length">Clothes, Bags &
              Shoes</h1>
            <h1 class="categories-title-update desktop" v-else-if="!loading && !searchQuery && selectedBrands.length">
              {{ buildBreadcrumbTitle }}
            </h1>
            <h1 class="categories-title-update desktop" v-else>{{ searchQuery }}</h1>
            <p class="categories-subtitle desktop">No matter what you’re searching for, we have just the fashion review
              for you.</p>
            <div class="pick-category-group">
              <p class="pick-item" @click="handleAllClick"
                 :class="{ 'highlighted': isAllSelected && selectedPrices.length === 0 }">
                All
              </p>
              <p class="pick-item" @click="toggleSaleFilter" :class="{ 'highlighted': sale === 'sale' }">
                On Sale
              </p>
              <div class="pick-item" v-for="price in prices" :key="price.id" :class="{ 'highlighted': selectedPrices.includes(price.code) }">
                <input
                    type="checkbox"
                    :id="'price-' + price.id"
                    :value="price.code"
                    v-model="selectedPrices"
                    @change="updateFilter('Prices', price.code)"
                    style="position: absolute; top: 0px; appearance: none; left: 0px; width: 100%; height: 100%; cursor: pointer"
                >

                <p class="name" >{{ price.name }}</p>
              </div>
            </div>
          </div>
          <h1 class="mobile"> {{
              breadcrumbs.length > 0 && breadcrumbs[breadcrumbs.length - 1].name === 'All' ? 'Clothes, Bags & Shoes' : (breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].name : '')
            }}</h1>
          <div class="categories-title">

            <div class="mobile" @click="toggleFilter">
              <div class="mobile-filter" id="mobile-filter">
                <img src="@/assets/icons/filter.svg" alt="filter">
                <p>Filter (<span id="filters-count">{{ filterCount }}</span>)</p>
              </div>
            </div>
            <div class="filter-sort-container">
              <!--              COLOR BAR-->
              <div class="applied-filters-content" id="applied-filters-content">
                <div v-for="(color, index) in selectedColors" :key="index" class="filter-colors">
                  <p>{{ color }}</p>
                  <img src="@/assets/icons/cross.svg" alt="cross" @click="removeFilter('Colors', color)" class="crossRemove"/>
                </div>
                <!--                STORES BAR-->
                <div v-for="(stores, index) in selectedStores" :key="index" class="filter-colors">
                  <p>{{ stores }}</p>
                  <img src="@/assets/icons/cross.svg" alt="cross" @click="removeFilter('Stores', stores)" class="crossRemove"/>
                </div>
                <!--                PRICES BAR-->
                <div v-for="id in selectedPrices" :key="id" class="filter-colors">
                  <p>{{ getPriceName(id) }}</p>
                  <img src="@/assets/icons/cross.svg" alt="cross" @click="removeFilter('Prices', id)" class="crossRemove"/>
                </div>

                <div v-if="selectedColors.length || selectedStores.length || selectedPrices.length> 0" @click="resetFilters"
                     style="background: none; text-decoration: underline; cursor: pointer">Clear all
                </div>
              </div>
              <!--              SORT BAR-->
              <div class="categories-sorting" @click="toggleDropdown()" ref="dropdown">
                <p>Sort by:</p>
                <div class="sorting-variants">
                  <p class="sorting-value">{{ selectedSorting }}</p>
                  <img src="@/assets/icons/chevron-down.svg">
                </div>
                <ul :class="{ open: dropdownOpen, close: !dropdownOpen && dropdownToggled }" class="sorting-options">
                  <li @click="selectSorting('newest')">Newest</li>
                  <li @click="selectSorting('low')">Lowest Price</li>
                  <li @click="selectSorting('high')">Highest Price</li>
                </ul>
              </div>
              <!--              <div v-if="dropdownOpen" class="overlay" @click="closeWindow"></div>-->
            </div>
          </div>
          <div class="items-count mobile">{{ total }} <p style="color: #8B8B8B; margin-left: 5px">items</p></div>

          <div class="mobile">
            <div class="applied-filters filter-group">
              <!--              MOBILE FILTER-->
              <div class="applied-filters-content selected-filters" id="mobile-applied-filters-content">
                <!--                STORES BAR MOBILE-->
                <div v-for="(stores, index) in selectedStores" :key="index" class="filter-colors">
                  <p>{{ stores }}</p>
                  <img src="@/assets/icons/cross.svg" alt="cross" @click="removeFilter('Stores', stores)" class="crossRemove"/>
                </div>
                <!--              COLOR BAR MOBILE-->
                <div v-for="(color, index) in selectedColors" :key="index" class="filter-colors">
                  <p>{{ color }}</p>
                  <img src="@/assets/icons/cross.svg" alt="cross" @click="removeFilter('Colors', color)" class="crossRemove"/>
                </div>
                <!--                PRICES BAR-->
                <div v-for="id in selectedPrices" :key="id" class="filter-colors">
                  <p>{{ getPriceName(id) }}</p>
                  <img src="@/assets/icons/cross.svg" alt="cross" @click="removeFilter('Prices', id)" class="crossRemove"/>
                </div>
                <div v-if="selectedColors.length > 0" @click="resetFilters"
                     style="background: none; font-size: 14px; text-decoration: underline; cursor: pointer">Clear all
                </div>
              </div>
            </div>
          </div>
          <div class="warning_undefined" v-if="this.total_formatted === '0'">Oops, looks like there's no products
            available for your
            selection
          </div>

          <div class="category-products">

            <div
                v-for="product in products"
                :key="product.id"
                class="product"
            >
              <div class="heart-wrapper" @click="addToFavorites(product.id)">
                <img
                    :src="isFavorite(product.id) ? require('@/assets/icons/heart-active.svg') : require('@/assets/icons/heart.svg')"
                    alt="heart"
                    style="z-index: 1"
                    class="heart-icon"
                    :class="{ 'active': isFavorite(product.id), 'explosion': explosion }"
                >
              </div>
              <img :src="product.image" alt="product-image" class="product-image"
                   @click="navigateToProductDetail(product)">
              <p :title="product.brand" class="author" @click="updateSelectedBrand(product.url_to_brand)">
                {{ `${product.brand.slice(0, 22)}${product.brand.length > 22 ? '...' : ''}` }}</p>
              <p :title="product.title">{{
                  `${product.title.slice(0, 20)}${product.title.length > 20 ? '...' : ''}`
                }}</p>
              <div class="container-price">
                <p class="old-price" v-if="product.old_price !== product.price">
                  ${{ product.old_price }}
                </p>
                <p class="price">${{ product.price }}</p>
              </div>
            </div>
            <div ref="observer" style="height: 1px;"></div>

            <div class="scroll-up-container" @click="scrollToTop">
              <img class="scroll-up" src="@/assets/images/row-up.png" alt="Scroll to top"/>
            </div>

          </div>
          <div v-if="loading" class="products-container">
            <ProductPlaceholder v-for="n in placeholdersCount" :key="n"/>
          </div>
          <div class="all-brands-wrapper button-wrapper" v-if="total > 24 && currentPage < Math.ceil(total / itemsPerPage)">
            <button class="all-brands button" @click="fetchProducts(selectedSorting)">Load more</button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <FooterMain/>
</template>

<style scoped>
.heart-wrapper {
  cursor: pointer;
}

.heart-icon {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.heart-icon.active {
  transform: scale(1.2);
  opacity: 1;
}

.heart-icon.explosion {
  animation: heartExplosion 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes heartExplosion {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.heart-wrapper {
  width: calc(100% - 2px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.heart-wrapper img {
  height: 24px;
  width: 24px;
  /* transform: translate(-0.75em, 0.75em); */
}
.filter-colors{
  cursor: default;
}
.crossRemove{
  cursor: pointer;
}
.category-products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 60px;
  align-items: center;
  justify-content: center;
}
.hidden-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-label {
  position: relative;
  border: 1px solid #8B8B8B;
  background-color: #fff;
  padding: 10px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  user-select: none;
}

.checkbox-label:hover {
  border: 1px solid #000;
}

.hidden-checkbox:checked + .checkbox-label {
  background-color: #e0e0e0; /* Задайте стиль для активного состояния */
  border-color: #000; /* Измените границу для активного состояния */
}
.products-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px 14px;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.warning_undefined {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10%;
  justify-content: center;
  text-align: center;
}

.highlighted {
  border: 2px solid #000 !important;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
}

.women-filter:hover,
.men-filter:hover,
.shoes-filter:hover,
.bags-filter:hover,
.kids-filter:hover,
.home-living-filter:hover,
.beauty-filter:hover {
  text-decoration: underline;
}

.breadCrumbs {
  display: flex;
  cursor: pointer;
  height: 30px;
  text-align: center;
  color: #000;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}

.breadCrumbs:hover {
  color: #8B8B8B;
}

.filter-sort-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 20px;
}

.pick-category-group {
  display: flex;
  width: 90%;
  margin-left: 14px;
  margin-right: 14px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.pick-item {
  position: relative;
  border: 1px solid #8B8B8B;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
}

.pick-item:hover {
  border: 1px solid #000;
}

.categories-subtitle {
  font-size: 16px;
  color: #8B8B8B;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.categories-title-update {
  font-size: x-large;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
}

.bread-crumbs-container {
  position: relative;
  width: 100%;
  padding-bottom: 20px;
  background-color: #F7F7F7;
}

.scroll-up {
  bottom: 25px;
  right: 25px;
  position: fixed;
  width: 50px;
  height: 50px;
  z-index: 1;
  cursor: pointer;
}

.all-brands-wrapper {
  margin-bottom: 4.9375em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.all-brands-wrapper .all-brands {
  position: relative;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;
}

.container-price {
  display: flex;
}

.old-price {
  margin-right: 15px;
  text-decoration: line-through;
}

.product {
  transition: transform 0.3s ease;
}

.product:hover {
  transform: scale(1.1);
  text-decoration: underline;
}

.product {
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
@keyframes openDropdown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
    opacity: 1;
  }
}

@keyframes closeDropdown {
  from {
    max-height: 136px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}

.open {
  animation: openDropdown 0.3s forwards;
}

.close {
  animation: closeDropdown 0.3s forwards;
}
.sorting-options {
  position: absolute;
  display: flex;
  transform: translate(0, 15px);
  border: 1px solid black;
  background-color: white;
  margin-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  height: 136px;
  z-index: 2;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  list-style-type: none;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

.sorting-options li {
  cursor: pointer;
  padding: 7px;
}

.sorting-options li:hover {
  text-decoration: underline;
}

.categories-name {
  position: relative;
  margin-bottom: 2.4375em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
  justify-content: flex-start;
}

.categories-content {
  display: grid;
  grid-template-columns: 15.25em 61.25em;
  gap: 2.25em;
}

.count-of-products {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 2.5em;
  display: flex;
}

.filter-title {
  font-size: 1em;
  font-weight: 900;
  line-height: 115%;
  margin-bottom: 1.25em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.applied-filters-content > div {
  background-color: var(--light-gray);
  display: flex;
  gap: 0.75em;
  align-items: center;
  padding: 0.375em 0.75em;
}

.applied-filters-content {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  gap: 0.625em
}

.applied-filters-content > div > img {
  height: 0.75em;
  width: 0.75em;
}

.filter-group {
  margin-bottom: 1.25em;
}

.filter-group > div > div:not(:last-child),
.filter-group > form > div:not(:last-child),
.filter-group-content > div:not(:last-child) {
  margin-bottom: 0.75em;
}

.filter-group > div, .filter-group > form, .filter-group-content {
  overflow: hidden;
}

.filtering-group {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.filtering-group .count {
  color: #8B8B8B;
}

.filtering-group .name {
  display: flex;
  align-items: center;
}

.brands-search {
  display: flex;
  background-color: var(--light-gray);
  gap: 0.75em;
  padding: 0.75em 0 0.75em 1em;
  margin-bottom: 1.5em;
}

.brands-search input {
  border: none;
  outline: none;
  background-color: var(--light-gray);
  width: 100%;
  padding-right: 1em;
  box-sizing: border-box;
}

.filter-form .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.25em;
  width: 1.25em;
}

.checkmark {
  height: 1.25em;
  width: 1.25em;
  box-sizing: border-box;
  border: 1px solid #111;
  display: flex;
  align-items: center;
}

.container input:checked ~ .checkmark {
  background-color: #111;
}

.container-filter {
  position: relative;
  display: flex;
  margin-bottom: 12px;
  justify-content: flex-start;
  flex-direction: row;
  gap: 12px;
}

.container-filter input:checked ~ .checkmark {
  background-color: #111;
}

.filter-form .container-filter input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.25em;
  width: 1.25em;
}

.checkmark img {
  margin-left: 0.125em;
  height: 0.875em;
  width: 0.875em;
}

.filter-form .filtering-group {
  justify-content: initial;
  gap: 0.75em;
}

.categories-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5em;
}

.categories-title h1 {
  font-size: 2em;
}

.categories-sorting > p {
  font-size: 1em;
  color: #8B8B8B;
  display: flex;
  align-items: center;
}

.categories-sorting {
  display: flex;
  cursor: pointer;
  gap: 0.5em;
}

.sorting-variants {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.sorting-variants img {
  height: 1.5em;
  width: 1.5em;
}

.categories-content {
  margin-bottom: 9.625em;
}

.filter-group div:last-child, .filter-group form:last-child {
  /*max-height: 20em;*/
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
  overflow: hidden;

}

.filter-group div.hidden:last-child, .filter-group form.hidden:last-child {
  max-height: 0;
}

.filter-group.hidden {
  margin-bottom: 0;
  -webkit-transition: margin-bottom 0.5s;
  -moz-transition: margin-bottom 0.5s;
  -ms-transition: margin-bottom 0.5s;
  -o-transition: margin-bottom 0.5s;
  transition: margin-bottom 0.5s;
}

.filter-title img, .filter-title.hidden img {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.filter-title.hidden img {
  animation-name: hide-filter-group;
}

.filter-title img {
  animation-name: display-filter-group;
}

.applied-filters-content > div {
  margin-bottom: 0 !important;
}

@keyframes hide-filter-group {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

@keyframes display-filter-group {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.categories-name {
  margin-bottom: 40px;
}

.categories-content {
  display: grid;
  grid-template-columns: 244px 1fr;
  gap: 36px;
}

.count-of-products {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 40px;
}

.filter-title {
  font-size: 16px;
  font-weight: 900;
  line-height: 115%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.applied-filters-content > div {
  background-color: var(--light-gray);
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 6px 12px;
}

.applied-filters-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.applied-filters-content > div > img {
  height: 12px;
  width: 12px;
}

.filter-group {
  margin-bottom: 40px;
}

.filter-group > div > div:not(:last-child),
.filter-group > form > div:not(:last-child),
.filter-group-content > div:not(:last-child){
  margin-bottom: 12px;
}

.filter-group > div, .filter-group > form, .filter-group-content {
  overflow: hidden;
}

.filtering-group {
  display: flex;
  justify-content: space-between;
}

.filtering-group .count {
  color: #8B8B8B;
}

.filtering-group .name {
  display: flex;
  align-items: center;
}

.brands-search {
  display: flex;
  background-color: var(--light-gray);
  gap: 12px;
  padding: 12px 0 12px 16px;
  margin-bottom: 24px;
}

.brands-search input {
  border: none;
  outline: none;
  background-color: var(--light-gray);
  width: 100%;
  padding-right: 16px;
  box-sizing: border-box;
}

.filter-form .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.checkmark {
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  border: 1px solid #111;
  display: flex;
  align-items: center;
}

.container input:checked ~ .checkmark {
  background-color: #111;
}

.checkmark img {
  margin-left: 2px;
  height: 14px;
  width: 14px;
}

.filter-form .filtering-group{
  justify-content: initial;
  gap: 12px;
}

.categories-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.categories-title h1 {
  font-size: 32px;
}

.categories-sorting > p {
  font-size: 16px;
  width: 70px;
  color: #8B8B8B;
  display: flex;
  align-items: center;
}

.categories-sorting {
  display: flex;
  gap: 8px;
}

.sorting-variants {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sorting-variants img {
  height: 24px;
  width: 24px;
}

.category-products {
  margin-bottom: 0px;
}

.categories-content {
  margin-bottom: 140px;
}

.filter-group div:last-child, .filter-group form:last-child {
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
}

.filter-group div.hidden:last-child, .filter-group form.hidden:last-child {
  max-height: 0 !important;
}

.filter-group.hidden {
  margin-bottom: 0;
  -webkit-transition: margin-bottom 0.5s;
  -moz-transition: margin-bottom 0.5s;
  -ms-transition: margin-bottom 0.5s;
  -o-transition: margin-bottom 0.5s;
  transition: margin-bottom 0.5s;
}

.filter-title img, .filter-title.hidden img {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.filter-title.hidden img {
  animation-name: hide-filter-group;
}

.filter-title img {
  animation-name: display-filter-group;
}

.applied-filters-content > div {
  margin-bottom: 0 !important;
}

.product-image__wrapper > .category-product__image {
  max-width: calc((1260px - 352px) / 4);
  max-height: calc((1260px - 352px) / 4);
}


.sorting-dropdown-wrapper {
  position: absolute;
  width: 165px;
  transform: translate(0, 45px);
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sorting-dropdown {
  border: 1px solid black;
  background-color: white;
  padding: 0 20px;
  border-radius: 4px;
  width: 158px;
  height: 136px;
}

.sorting-link {
  font-size: 14px;
  padding: 8px 0;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 10px;
}

@media (max-width: 1374px) {
  .heart-wrapper {
    width: calc(90% - 30px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 1340px) {
  .product-image__wrapper > .category-product__image {
    max-height: calc((1260px - 352px) / 3);
    max-width: calc((1260px - 352px) / 3);
  }

  .heart-wrapper {
    width: calc(100% - 20px);
    padding-right: 20px;
    padding-left: 5px;
    left: calc(100% - 320px);
    justify-content: flex-end;
    align-items: center;
  }

  .products-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1080px) {
  .product-image__wrapper > .category-product__image {
    max-height: calc((100vw - 32px - 352px) / 2);
    max-width: calc((100vw - 32px - 352px) / 2);
  }
  .heart-wrapper {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .products-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}



/* 244px 32px */

@media (max-width: 990px) {
  .product-image__wrapper > .category-product__image {
    max-height: calc((100vw - 32px) / 2);
    max-width: calc((100vw - 32px) / 2);
  }
}

@keyframes hide-filter-group {
  from { transform: rotate(0deg); }
  to { transform: rotate(90deg ); }
}

@keyframes display-filter-group {
  from { transform: rotate(90deg); }
  to { transform: rotate(0deg ); }
}

@media (max-width: 990px) {
  .categories-content {
    display: block;
  }

  .page-wrapper {
    padding: 0 16px;
  }

  .categories-filter {
    position: fixed;
    background-color: white;
    width: calc(100vw - 32px);
    top: 0;
    left: -100vw;
    z-index: 10000;
    padding: 24px 16px;
    height: calc(100vh - 48px);
    overflow-y: scroll;
    transition: max-width 0.5ms;
  }

  .category-products {
    grid-template-columns: 1fr 1fr;
  }

  .category-products > div > .product-image {
    width: 100%;
  }

  .category-products > div > p {
    font-size: 14px;
  }

  .category-products > div > p.author {
    margin-top: 16px;
  }

  .category-products > div > p:not(:last-child) {
    margin-bottom: 8px;
  }

  .category-products {
    margin-bottom: 60px;
  }

  .categories-products .button-wrapper .button {
    width: 100%;
    padding: 14px 40px;
    font-size: 14px;
    font-weight: 500;
  }

  .mobile-filter {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .mobile-filter p span, .mobile-filter p {
    font-size: 14px;
  }

  .categories-sorting > p {
    font-size: 14px;
  }

  .sorting-variants .sorting-value {
    font-size: 14px;
  }

  .categories-products h1 {
    font-size: 24px;
    padding: 24px 0;
  }

  .mobile .applied-filters .applied-filters-content > div p {
    font-size: 14px;
  }

  .mobile .applied-filters .applied-filters-content > div {
    padding: 12px;
  }

  .applied-filters-content > div > img {
    height: 12px;
    width: 12px;
  }

  .mobile .applied-filters {
    margin-bottom: 24px;
  }

  .items-count.mobile {
    margin-bottom: 12px;
  }

  .mobile.items-count, .mobile.items-count span {
    font-size: 14px;
  }

  .mobile.items-count span {
    color: #8B8B8B;
  }

  .categories-title {
    margin-bottom: 24px;
  }

  .mobile .filter-title p {
    font-size: 24px;
    font-weight: 900;
    line-height: 115%;
  }

  .mobile .filter-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .mobile .filter-title img {
    height: 20px;
    width: 20px;
    margin-right: 2px;
  }

  .filter-title span {
    font-size: 20px;
    line-height: 115%;
  }

  .filter-title {
    margin-bottom: 24px;
  }

  .filtering-group {
    justify-content: initial;
    gap: 6px;
  }

  .filtering-group p {
    font-size: 16px;
  }

  .filter-group {
    margin-bottom: 40px;
  }

  .filter-group > div > div:not(:last-child),
  .filter-group > form > div:not(:last-child),
  .filter-group-content > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .checkmark {
    height: 20px;
    width: 20px;
  }

  .checkmark img {
    height: 14px;
    width: 14px;
    margin-left: 2px;
  }

  .stories.filter-group {
    margin-bottom: 0;
  }

  .filter-form .filtering-group {
    gap: 12px;
  }

  .brands-search input {
    font-size: 14px;
    font-weight: 400;
    padding-left: 12px;
  }

  .brands-search {
    gap: 0;
    padding: 10px 16px;
  }

  .categories-filter.opened {
    animation-name: open-filters;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
  }

  .categories-filter.closed {
    animation-name: hide-filters;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
  }

  .filter-form.hidden input {
    display: none;
  }

  @keyframes open-filters {
    from {
      left: -100vw;
    }
    to {
      left: 0;
    }
  }

  @keyframes hide-filters {
    from {
      left: 0;
    }
    to {
      left: -100vw;
    }
  }
}

@media (max-width: 990px) {
  .categories-content {
    display: block;
  }

  .products-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px 64px;
    flex-wrap: wrap;
    justify-items: center;
  }

  .bread-crumbs-container {
    display: none;
  }

  .page-wrapper {
    padding: 0 16px;
  }

  .categories-filter {
    position: fixed;
    background-color: white;
    width: calc(100vw - 32px);
    top: 0;
    left: -100vw;
    z-index: 10000;
    padding: 24px 16px;
    height: calc(100vh - 48px);
    overflow-y: scroll;
    transition: max-width 0.5ms;
  }

  .filter-sort-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .category-products {
    grid-template-columns: 4fr;
  }

  .category-products > div > .product-image {
    width: 100%;
  }

  .category-products > div > p {
    font-size: 14px;
  }

  .category-products > div > p.author {
    margin-top: 16px;
  }

  .category-products > div > p:not(:last-child) {
    margin-bottom: 8px;
  }

  .category-products {
    margin-bottom: 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px 24px;
    justify-items: center;
    align-items: center;
    justify-content: center;
  }

  .categories-products .button-wrapper .button {
    width: 100%;
    padding: 14px 40px;
    font-size: 14px;
    font-weight: 500;
  }

  .mobile-filter {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .mobile-filter p span, .mobile-filter p {
    font-size: 14px;
    width: 60px;
  }

  .categories-sorting > p {
    font-size: 14px;
    width: 60px;
  }

  .sorting-variants .sorting-value {
    font-size: 14px;
  }

  .categories-products h1 {
    font-size: 24px;
    padding: 24px 0;
  }

  #applied-filters-content {
    display: none;
  }

  .mobile .applied-filters .applied-filters-content > div p {
    font-size: 14px;
  }

  .mobile .applied-filters .applied-filters-content > div {
    padding: 12px;
  }

  .applied-filters-content > div > img {
    height: 12px;
    width: 12px;
  }

  .mobile .applied-filters {
    margin-bottom: 24px;
  }

  .items-count.mobile {
    margin-bottom: 12px;
  }

  .mobile.items-count, .mobile.items-count span {
    font-size: 14px;
    display: flex !important;
  }

  .mobile.items-count span {
    color: #8B8B8B;
  }

  .categories-title {
    align-items: flex-end;
    margin-bottom: 24px;
  }

  .mobile .filter-title p {
    font-size: 24px;
    font-weight: 900;
    line-height: 115%;
  }

  .mobile .filter-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .mobile .filter-title img {
    height: 20px;
    width: 20px;
    margin-right: 2px;
  }

  .filter-title span {
    font-size: 20px;
    line-height: 115%;
  }

  .filter-title {
    margin-bottom: 24px;
  }

  .filtering-group {
    justify-content: initial;
    gap: 6px;
  }

  .filtering-group p {
    font-size: 16px;
  }

  .filter-group {
    margin-bottom: 40px;
  }

  .filter-group > div > div:not(:last-child),
  .filter-group > form > div:not(:last-child),
  .filter-group-content > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .filter-group-content {
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .rotate-up {
    transform: rotate(180deg);
  }

  .checkmark {
    height: 20px;
    width: 20px;
  }

  .checkmark img {
    height: 14px;
    width: 14px;
    margin-left: 2px;
  }

  .stories.filter-group {
    margin-bottom: 0;
  }

  .filter-form .filtering-group {
    gap: 12px;
  }

  .brands-search input {
    font-size: 14px;
    font-weight: 400;
    padding-left: 12px;
  }

  .brands-search {
    gap: 0;
    padding: 10px 16px;
  }

  .categories-filter.opened {
    animation-name: open-filters;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
  }

  .categories-filter.closed {
    animation-name: hide-filters;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
  }

  .filter-form.hidden input {
    display: none;
  }

  @keyframes open-filters {
    from {
      left: -100vw;
    }
    to {
      left: 0;
    }
  }

  @keyframes hide-filters {
    from {
      left: 0;
    }
    to {
      left: -100vw;
    }
  }
}

@media (max-width: 600px) {
  .product-placeholder {
    width: 160px !important;
  }
  .products-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px 0px;
    align-items: center;
    flex-wrap: wrap;
  }

  .category-products {
    margin-bottom: 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px 24px;
    justify-items: center;
  }
}
</style>
