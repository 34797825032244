<script>
import HeaderMain from "@/components/main/HeaderMain.vue";
import FooterMain from "@/components/main/FooterMain.vue";
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import { useCartStore } from "@/stores/product";
import BrandPlaceholder from "@/components/UI/ImagePlaceholder.vue";
import TextProductPlaceholder from "@/components/UI/TextProductPlaceholder.vue";
import StringProductPlaceholder from "@/components/UI/StringProductPlaceholder.vue";
import ProductPlaceholder from "@/components/UI/ProductPlaceholder.vue";
import { API_BASE_URL } from '@/components/config/config';

export default {
  name: "ShopProduct",
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {ProductPlaceholder, StringProductPlaceholder, TextProductPlaceholder, BrandPlaceholder, FooterMain, HeaderMain},
  created() {
    this.fetchProductData();
    this.fetchCartData();
    this.fetchProduct();
  },

  data() {
    return {
      isDescriptionOpen: false,
      isShippingOpen: false,
      dropdownToggled: false,
      n: 1,
      item: {
        quantity: 1
      },
      favorites: [],
      newlyAddedItem: null,
      dropdownOpen: {},
      selectedProduct: {},
      itemDropdownOpen: {},
      isLoading: true,
      products: [{"": ""}, {"": ""}, {"": ""}, {"": ""}],
      cartVisible: false,
      menu: [
        {id: 1, label: "WOMEN", url: "shop/women"},
        {id: 2, label: "MEN", url: "shop/men"},
        {id: 3, label: "SHOES", url: "shop/womens-shoes"},
        {id: 4, label: "BAGS", url: "shop/womens-bags"},
        {id: 5, label: "KIDS", url: "shop/kids"},
        {id: 6, label: "HOME & LIVING", url: "shop/home-living"},
        {id: 7, label: "BEAUTY", url: "shop/womens-beauty-products"},
      ],

      cookies: [],
    }
  },
  computed: {
    hasGclid() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has('gclid');
    },
    subtotal() {
      const cartStore = useCartStore();
      return cartStore.cartSubtotal;
    },
    total() {
      const cartStore = useCartStore();
      return cartStore.cartTotal;
    },
    cartItems() {
      const cartStore = useCartStore();
      return cartStore.cartItems;
    }
  },
  methods: {
    toggleDescription() {
      this.dropdownToggled = true;
      this.isDescriptionOpen = !this.isDescriptionOpen;
    },
    toggleShipping() {
      this.dropdownToggled = true;
      this.isShippingOpen = !this.isShippingOpen;
    },
    updateProductsByMenu(menuItemUrl) {
      console.log("Menu item URL clicked:", menuItemUrl);
      this.$emit('updateProductsByMenu', menuItemUrl);
      this.$router.push(`/${menuItemUrl}`);
    },
    navigateToBreadcrumb(url) {
      window.location.href = url;
    },
    async fetchProductData() {
      this.isLoading = true;
      try {
        const response = await axios.get(`${API_BASE_URL}/product/${this.$route.params.id}`);
        this.selectedProduct = response.data.data;
        console.log(this.selectedProduct.product, 'AZAZAIIII');
        console.log(this.selectedProduct, 'AAAAA');
      } catch (error) {
        console.error('Error fetching product data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchProduct() {
      this.isLoading = true;
      try {
        const response = await axios.get(`${API_BASE_URL}/product/${this.$route.params.id}/similar`);
        this.products = response.data.data;
      } catch (error) {
        console.error('Error fetching product data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async removeCartItem(item) {
      const authStore = useAuthStore();
      const cartItemsPinia = useCartStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error('No authentication token found');
        return;
      }

      try {
        console.log('Removing item:', item);
        const response = await axios.post(`${API_BASE_URL}/cart/delete`, {
          row_id: item.row_id,
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          cartItemsPinia.cartItems = cartItemsPinia.cartItems.filter(cartItem => cartItem.row_id !== item.row_id);
          console.log('Cart items after removal:', cartItemsPinia.cartItems);
        } else {
          console.error('Failed to remove item:', response.data);
        }
      } catch (error) {
        console.error('Error removing item from cart:', error.response ? error.response.data : error.message);
      }
    },
    async updateCartItemQuantity(item) {
      const authStore = useAuthStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error('No authentication token found');
        return;
      }

      try {
        const response = await axios.post(
            `${API_BASE_URL}/cart/update`,
            {
              row_id: item.row_id,
              quantity: item.quantity
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
        );

        const cartStore = useCartStore();
        cartStore.updateCartItemQuantity(item.row_id, item.quantity);

        localStorage.setItem(item.row_id, item.quantity);

        console.log('Product quantity updated in cart:', response.data);
      } catch (error) {
        console.error('Error updating product quantity in cart:', error);
      }
    },
    async fetchCartData() {
      const authStore = useAuthStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error("No authentication token found");
        return;
      }

      try {
        const response = await axios.get(`${API_BASE_URL}/cart/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const cartStore = useCartStore();
        cartStore.setCartItems(response.data.data);

        console.log("Cart items fetched:", response.data.data);

        // if (newlyAddedItem) {
        //   cartStore.addToCart(newlyAddedItem);
        //   newlyAddedItem = null;
        // }

      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    },
    toggleCartVisibility() {
      const cartStore = useCartStore();
      cartStore.toggleCartVisibility();
    },
    async addToCart() {
      // if (this.hasAddedToCart) {
      //   return;
      // }

      this.cartVisible = true;
      const authStore = useAuthStore();
      authStore.loadToken();

      const token = authStore.token;

      if (!token) {
        console.error("No authentication token found");
        return;
      }

      const cartStore = useCartStore();
      const existingItem = cartStore.cartItems.find(
          (item) => item.product_id == this.selectedProduct.product.id
      );

      if (existingItem) {
        try {
          const response = await axios.post(
              `${API_BASE_URL}/cart/update`,
              {
                row_id: existingItem.row_id,
                quantity: existingItem.quantity + 1,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
          );

          cartStore.updateCartItemQuantity(
              existingItem.row_id,
              existingItem.quantity + 1
          );

          existingItem.quantity += 1;

          console.log("Product quantity updated in cart:", response.data);
        } catch (error) {
          console.error("Error updating product quantity in cart:", error);
        }
      } else {
        try {
          const response = await axios.post(
              `${API_BASE_URL}/cart/add`,
              {
                product_id: this.selectedProduct.product.id,
                quantity: 1,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
          );

          const newCartItem = response.data.data;

          cartStore.addToCart({
            row_id: newCartItem.row_id,
            product_id: newCartItem.product_id,
            product_name: this.selectedProduct.product.title,
            product_image: this.selectedProduct.product.image,
            url_to_brand: this.selectedProduct.product.url_to_brand,
            price: this.selectedProduct.product.price,
            quantity: newCartItem.quantity,
          });

          newCartItem.quantity = 1;
          console.log("Product added to cart:", response.data);

        } catch (error) {
          console.error("Error adding product to cart:", error);
        }
      }
      await this.fetchCartData();
    },

    toggleDropdown(item) {
      if (this.dropdownOpen[item.row_id] === undefined) {
        this.dropdownOpen[item.row_id] = false;
      }

      this.dropdownOpen[item.row_id] = !this.dropdownOpen[item.row_id];
    },

    // getUpdatedQuantity(row_id) {
    //   const cartStore = useCartStore();
    //   const item = cartStore.cartItems.find(item => item.row_id === row_id);
    //   return item ? item.quantity : '';
    // },

    selectQuantity(item, quantity) {
      item.quantity = quantity;
      this.updateCartItemQuantity(item);
      this.dropdownOpen[item.row_id] = false;
    },
    visiblePopUp(){
      this.cartVisible = !this.cartVisible;
    },
    formatUrl(url) {
      return url.replace(/&/g, '&\n');
    },

    redirectUrl() {
      const url = this.selectedProduct.product?.redirect_url;
      const pageUrl = window.location.pathname;
      console.log(pageUrl, 'CURRENT URL');

      if (url) {
        const formattedUrl = this.formatUrl(url);
        const authStore = useAuthStore();
        authStore.loadToken();
        const token = authStore.token;
        console.log(token, 'TOKEN');
        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const cookies = document.cookie.split(';').map(cookie => {
          const [name, value] = cookie.trim().split('=');
          return { name, value };
        });
        console.log(cookies, 'COOKIES');

        axios.post(`${API_BASE_URL}/reward/click`, {
          href: formattedUrl,
          page_url: pageUrl,
          cookies: cookies
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
            .then(() => {
              window.open(url);
            })
            .catch(error => {
              console.error('Error sending click event:', error);
              window.open(url);
            });
      } else {
        console.warn('Redirect URL not found');
      }
    },
    async addToFavorites(productId) {
      try {
        const authStore = useAuthStore();
        authStore.loadToken();
        const token = authStore.token;

        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const response = await axios.post(`${API_BASE_URL}/favorite/add`, {
          product_id: productId
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log(response, 'ADD RES ');

        await this.loadFavorites();
      } catch (error) {
        console.error('Error adding to favorites:', error);
      }
    },
    async loadFavorites() {
      try {
        const authStore = useAuthStore();
        authStore.loadToken();
        const token = authStore.token;

        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const response = await axios.get(`${API_BASE_URL}/favorite/list`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (Array.isArray(response.data.data)) {
          this.favorites = response.data.data;
        } else {
          console.error('Favorites list is not an array');
          this.favorites = [];
        }

        console.log(response.data.data);
      } catch (error) {
        console.error('Error loading favorites:', error);
        this.favorites = [];
      }
    },
    isFavorite(productId) {
      return this.favorites.some(favorite => favorite.id === productId);
    },
    handleBeforeLeave(el) {
      el.style.animation = 'fadeOut 0.3s forwards';
    }
  },
  mounted() {
    this.loadFavorites();
    const authStore = useAuthStore();
    if (authStore.gclid) {
      const currentUrl = new URL(window.location.href);
      if (!currentUrl.searchParams.has('gclid')) {
        currentUrl.searchParams.append('gclid', authStore.gclid);
        this.$router.push(currentUrl.toString());
      }
    }
    authStore.loadToken();
  },
}
</script>

<template>
  <div class="overlay" :class="{ 'fadeIn': cartVisible, 'fadeOut': !cartVisible }" @click="visiblePopUp" v-if="cartVisible">
    <div class="modal fixed-right fade show" id="modalShoppingCart" tabindex="-1" style="display: block;"
         aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-vertical" role="document">
        <div class="modal-content" @click.stop>
          <div style="background-color: #fff;" class="modal-header line-height-fixed font-size-lg">
            <strong class="mx-auto">Your Cart (<span id="header-cart-qty" data-qty="8">{{ cartItems.length }}</span>)</strong>
            <img src="@/assets/icons/cross.svg" alt="cross" class="cross" @click="visiblePopUp"/>
          </div>
          <ul class="list-group list-group-lg list-group-flush" style="overflow-y: auto; overflow-x: hidden;" id="header-cart-list">
            <li class="list-group-item navbar-cart-product" data-id="4812747" data-qty="8" data-price="435" data-cost="3480">
              <div class="row align-items-center" v-for="item in cartItems" :key="item.product_id">
                <div class="col-4">
                  <a target="_blank">
                    <img class="img-fluid" :src="item.product_image" alt="Long-Sleeve Wrap Bodysuit" title="Long-Sleeve Wrap Bodysuit">
                  </a>
                </div>
                <div class="col-8">
                  <p class="font-size-sm font-weight-bold mb-6" style="width: 50%;">
                    <a class="text-body">{{ item.product_name }}</a> <br>
                    <span class="text-muted">${{ item.price }}</span>
                  </p>
                  <div class="d-flex align-items-center">
                    <div class="custom-dropdown">
                      <div class="dropdown-selected" @click="toggleDropdown(item)">
                        {{ item.quantity  }}
                      </div>
                      <div class="dropdown-options" :class="{ 'show-dropdown': dropdownOpen[item.row_id] }">
                      <div
                            v-for="n in 5"
                            :key="n"
                            class="dropdown-option"
                            @click="selectQuantity(item, n)"
                        >
                          {{ n }}
                        </div>
                      </div>
                    </div>
<!--                    <select class="custom-select custom-select-xxs w-auto select-qty" v-model="item.quantity" @change="updateCartItemQuantity(item)">-->
<!--                      <option v-for="n in 5" :value="n">{{ n }}</option>-->
<!--                    </select>-->
                    <div class="font-size-xs text-gray-400 navbar-cart-del header-cart__remove" style="cursor: pointer; color: grey; filter: invert(47%) sepia(2%) saturate(17%) hue-rotate(315deg) brightness(106%) contrast(80%);" @click="removeCartItem(item)">Remove <img src="@/assets/icons/cross.svg" alt="cross"/></div>
                  </div>
                </div>


              </div>
            </li>
          </ul>
          <div class="modal-footer line-height-fixed font-size-sm bg-light mt-auto">
            <strong>Subtotal</strong> <strong class="ml-auto" data-cost="3480" id="header-cart-cost">${{ subtotal }}</strong>
          </div>
          <div class="modal-body" id="header-cart-controls" style="">
            <a class="btn btn-dark" @click="this.$router.push({ path: '/checkout'})">Continue to Checkout</a>
            <a class="btn btn-block btn-outline-dark" @click="this.$router.push({ path: '/cart'})">View Cart</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <HeaderMain
      :menu="menu"
      @update-products-by-menu="updateProductsByMenu"
  />
  <div class="page-wrapper">
    <div>
      <div class="categories-name desktop">
        <p class="breadCrumbs">&nbsp;</p>
        <div v-if="isLoading" class="products-container" style="width: 100%;">
          <StringProductPlaceholder />
        </div>
        <div v-if="!isLoading" style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <span v-for="(breadcrumb, index) in selectedProduct.breadcrumbs" :key="index"
              :class="{ 'last-breadcrumb': index === selectedProduct.breadcrumbs.length - 1 }">
          <p
              v-if="index !== selectedProduct.breadcrumbs.length - 1"
              @click="navigateToBreadcrumb(breadcrumb.url)"
              class="breadCrumbs"
          >
            {{ breadcrumb.name }} / &nbsp;
          </p>
          <p
              v-else
              class="breadCrumbs black-text"
          >
            {{ breadcrumb.name }}
          </p>
        </span>
        </div>
      </div>
      <div class="product-info">
        <div class="product-images">

          <div id="first-section-wrapper" class="mobile">
            <div class="left-side-images" id="left-side-images" v-for="addtional_image in selectedProduct.product?.addtional_images" :key="addtional_image.id" style="transform: translate3d(0px, 0px, -1px)">
              <img :src="addtional_image" alt="product-image-1" class="active">
            </div>
            <div v-if="isLoading" class="products-container">
              <BrandPlaceholder style="height: 500px; display: flex; width: calc(100vw - 62px);"/>
            </div>
            <img v-if="!isLoading" :src="this.selectedProduct.product?.image" alt="product-image-1" id="main-image">
          </div>
          <div class="product-images-controller" id="product-images-controller">
          </div>

          <div  class="main-image desktop">
            <div v-if="isLoading" class="products-container">
              <BrandPlaceholder style="height: 500px;"/>
            </div>
            <img v-if="!isLoading" :src="this.selectedProduct.product?.image" alt="product-image-1" id="main-image">
          </div>
        </div>
        <div>
          <div class="product-title">
            <div v-if="isLoading" class="products-container">
              <TextProductPlaceholder style="width: auto;"/>
            </div>
            <p class="product-author" v-if="!isLoading">{{ this.selectedProduct.product?.brand }}</p>
            <h1 class="product-name" v-if="!isLoading">{{ this.selectedProduct.product?.title }}</h1>
            <div class="product-price" v-if="!isLoading">
              <p class="price" style="text-decoration: line-through" v-if="this.selectedProduct.product?.old_price !== this.selectedProduct.product?.price">
                ${{ this.selectedProduct.product?.old_price }}</p>
              <p class="price">${{ this.selectedProduct.product?.price }}</p>
            </div>
          </div>
          <div class="product-colors">
            <div v-if="isLoading" class="products-container" style="width: 100%;">
              <StringProductPlaceholder style="padding-bottom: 10px;"/>
            </div>
            <div class="current-color" v-if="!isLoading">
              <span>Color:</span>
              <span class="color-value">&nbsp;{{ this.selectedProduct.product?.color }}</span>
            </div>
          </div>
          <div class="product-size">
            <div class="select-size">
              <div class="current-value" v-for="size in selectedProduct.product?.sizes" :key="size.id">{{ size }}</div>
            </div>
          </div>
          <div>
            <div class="shop-now" v-if="hasGclid">
              <p class="title" style="font-size: 1.125em; font-weight: 900; margin-bottom: 0.75em;">Best price:</p>
              <button class="button" @click="redirectUrl">
                {{ `${'Buy at' + ' ' + this.selectedProduct.product?.store_name}` }}
              </button>
              <p class="or">or</p>
            </div>
            <div class="add-to-cart">
              <button class="button" @click="addToCart">Add to cart</button>
              <div class="description">
                <p class="title">SHOPPING CONCIERGE:</p>
                <p class="text">
                  One click shopping across hundreds of stores. Add the items you
                  wish to buy and let us handle the rest. Learn more
                </p>
              </div>
            </div>
            <div class="product-description section-with-hiding">
              <p @click="toggleDescription">
                <span>Product Description</span>
                <img :class="{ rotate: isDescriptionOpen, 'no-rotate': !isDescriptionOpen }" src="../../assets/icons/chevron-down.svg" alt="chevron-down"/>
              </p>
              <transition name="modal-fade" @before-leave="handleBeforeLeave">
              <p v-if="isDescriptionOpen" :class="{ open: isDescriptionOpen, close: !isDescriptionOpen && dropdownToggled }" class="text">

                {{ this.selectedProduct.product?.description }}

              </p>
              </transition>
            </div>
            <div class="section-with-hiding" style=" cursor: pointer;">
              <p @click="toggleShipping">
                <span>Shipping & Returns</span>
                <img :class="{ rotate: isShippingOpen, 'no-rotate': !isShippingOpen }" src="../../assets/icons/chevron-down.svg" alt="chevron-down"/>
              </p>
              <transition name="modal-fade" @before-leave="handleBeforeLeave">
              <p v-if="isShippingOpen" :class="{ open: isShippingOpen, close: !isShippingOpen && dropdownToggled }" class="text">
                North American orders are shipped within 1-2 business days. You will receive an automatic dispatch email
                containing your tracking information once your order has shipped.
                Our ultimate priority is your happiness with your product and the experience on our website. If you order
                a product that does not fit or match your expectations, you can return the product within 30 days in the
                original condition with no questions asked. Please email us to start a return/exchange process.
              </p>
              </transition>
            </div>
        </div>
      </div>
      </div>
      <div v-if="this.products?.length > 0">
        <div class="category-separator">
          <p class="category-text">You might also like</p>
          <div class="separator"></div>
        </div>

        <div class="also-like">
          <div class="category-products product" v-for="product in products" :key="product.id">
            <div style="display: flex; flex-direction: column; align-content: center">
              <div v-if="isLoading" class="products-container" style="padding-bottom: 50px; display: flex; justify-content: space-between;">
                <ProductPlaceholder />
              </div>
              <div class="heart-wrapper" @click="addToFavorites(product.id)" v-if="!isLoading">
                <img
                    :src="isFavorite(product.id) ? require('@/assets/icons/heart-active.svg') : require('@/assets/icons/heart.svg')"
                    alt="heart"
                    style="z-index: 1"
                    class="heart-icon"
                    :class="{ 'active': isFavorite(product.id), 'explosion': explosion }"
                >
              </div>
              <a style="text-decoration: none" :href="`${product.url_to_product}`">
                <img v-if="!isLoading" :src="product?.product_image" alt="product-image" class="product-image author">
                <p v-if="!isLoading" class="author">{{ product?.brand }}</p>
              </a>
              <a style="text-decoration: none" :href="`${product.redirect_url}`">
                <p v-if="!isLoading" class="author" :title="product?.title">{{ product?.title ? `${product.title.slice(0, 22)}${product.title.length > 22 ? '...' : ''}` : 'No title available' }}</p>
              </a>
              <div class="price">
                <p v-if="!isLoading" class="price" style="text-decoration: line-through">${{ product.retail_price }}</p>&nbsp;&nbsp;&nbsp;
                <p v-if="!isLoading" class="price">${{ product?.price }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterMain/>
</template>

<style scoped>
.heart-wrapper {
  cursor: pointer;
}

.heart-icon {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.heart-icon.active {
  transform: scale(1.2);
  opacity: 1;
}

.heart-icon.explosion {
  animation: heartExplosion 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes heartExplosion {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active, .modal-fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.modal-fade-enter, .modal-fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.product {
  transition: transform 0.3s ease;
}

.product:hover {
  transform: scale(1.1);
  text-decoration: underline;
}

.product {
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}

@keyframes slideDown {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 500px; /* Adjust this value as needed */
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    max-height: 500px; /* Same as above */
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}

.open {
  animation: slideDown 0.5s forwards;
}

.close {
  animation: slideUp 0.5s forwards;
}
.rotate {
  transform: rotate(180deg);
  transition: transform 0.5s;
}

.no-rotate {
  transition: transform 0.5s;
}
.heart-wrapper{
  display: flex;
  flex-direction: row-reverse;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 40px;
  text-align: center;
}

.dropdown-selected {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.dropdown-options {
  position: relative;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  width: 38px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
}

.dropdown-option {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.show-dropdown {
  display: block; /* Показываем выпадающий список при активации */
  animation: slideDown 0.3s ease; /* Анимация появления */
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.dropdown-option:hover {
  background-color: #f1f1f1;
}
.black-text {
  color: black;
}

.category-products > div > .product-image {
  object-fit: contain;
  width: 12.437em;
  height: 200px;
}

.breadCrumbs {
  cursor: pointer;
}

.product-image{
  object-fit: contain;
  width: 14.437em !important;
  height: 14.437em !important;
}

.author:hover{
  text-decoration: underline;
}

.product-image > author:hover{
  text-decoration: underline;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, .2);
  outline: 0;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
}

.text-body {
  text-decoration: none;
}

.text-muted {
  font-size: 2rem;
  font-weight: 700;
  color: #10100E !important;
}

.d-flex {
  display: flex;
  position: relative;
  top: 20px;
  width: 60%;
  justify-content: space-between;
  flex-direction: row;
}

.ml-auto {
  font-size: 2rem;
  font-weight: 700;
}

.bg-light {
  background-color: #f7f7f7;
}

.btn-dark {
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  margin-bottom: 20px;
  background-color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.875rem 1.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
}

.btn-block {
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #000;
  padding: 0.875rem 1.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
}

.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important
}

.col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
  position: relative;
  left: 70px;
}

.custom-select-xxs {
  height: calc(1.3rem + .375rem + 2px);
  padding: 0rem 1.0rem .1875rem .5rem;
  font-size: 1rem;
  border: 2px solid;
  cursor: pointer;
}

.img-fluid {
  position: relative;
  width: 100%;
  left: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.cross {
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  z-index: 2;
  cursor: pointer;
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  -o-transition: transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform: translate(0, -50px);
  -ms-transform: translate(0, -50px);
  transform: translate(0, -50px)
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    -o-transition: none;
    transition: none
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02)
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: -webkit-calc(100% - 1rem);
  max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
  max-height: -webkit-calc(100vh - 1rem);
  max-height: calc(100vh - 1rem);
  overflow: hidden
}

.modal-dialog-scrollable .modal-footer, .modal-dialog-scrollable .modal-header {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: -webkit-calc(100% - 1rem);
  min-height: calc(100% - 1rem)
}

.modal-dialog-centered::before {
  display: block;
  height: -webkit-calc(100vh - 1rem);
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, .2);
  outline: 0;
  justify-content: space-between;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .3
}

.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: center;
  padding: 1.5rem 2rem;
  border-bottom: 2px solid #000
}

.modal-header .close {
  padding: 1.5rem 2rem;
  margin: -1.5rem -2rem -1.5rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.3
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, .2);
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.fadeOut {
  animation: fadeOut 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
.modal-body {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  padding: 2rem;
  flex-direction: column;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.75rem;
}

.modal-footer > * {
  margin: .25rem
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}


.categories-name {
  margin-top: 2em;
  margin-bottom: 2em;
}

.product-info {
  display: grid;
  grid-template-columns: 1fr 29.375em;
  gap: 2em;
  margin-bottom: 2em;
}

.product-images {
  display: grid;
  grid-template-columns: 6.875em 1fr;
  gap: 1em;
}

.left-side-images img {
  width: 100%;
  margin-bottom: 0.75em;
}

.left-side-images img.active {
  border: 1px solid #111111;
}

.main-image img {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

.product-title .product-name {
  font-size: 2em;
}

.product-price .price {
  font-size: 1.75em;
  font-weight: 500;
  letter-spacing: -0.6px;
}

.product-price {
  display: flex;
  gap: 1em;
  align-items: center;
}

.product-price .cashback {
  background-color: #111111;
  color: white;
  font-size: 0.875em;
  display: flex;
  align-items: center;
  padding: 0 0.875em;
  height: 32px;
}

.product-title {
  margin-bottom: 2.5em;
}

.product-details {
  background-color: var(--light-gray);
  padding: 1em;
  margin-bottom: 1.875em;
}

.product-details div span {
  text-decoration: underline;
}

.current-color span:first-child {
  color: #8B8B8B;
}

.current-color {
  margin-bottom: 1.125em;
}

.colors-variants .active {
  border: 1px solid black;
}

.colors-variants {
  display: flex;
  gap: 0.625em;
}

.product-colors {
  margin-bottom: 2.375em;
}

.product-size .text {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 1em;
}

.select-size {
  background-color: var(--light-gray);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 1.0625em 1.25em;
}

.select-size .current-value {
  text-align: center;
  font-size: 1.375em;
  font-weight: 400;
  line-height: 138%;
}

.product-size {
  margin-bottom: 3.25em;
}

.product-cashback {
  font-size: 1.375em;
  font-style: normal;
  font-weight: 900;
  line-height: 115%;
  margin-bottom: 1.1875em;
}

.shop-now button {
  background-color: #00aa5b;
  width: 100%;
  font-size: 1.125em;
  padding: 1.375em 0;
  cursor: pointer;
}

.add-to-cart button {
  width: 100%;
  font-size: 1.125em;
  padding: 1.375em 0;
  cursor: pointer;
}

.price-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  margin-top: 2.75em;
}

.price-alert p, .or {
  font-size: 1.125em;
  font-weight: 500;
}

.or {
  text-align: center;
  margin-top: 1.5em;
}

.add-to-cart {
  margin-top: 1.5em;
  margin-bottom: 2.275em;

}

.add-to-cart .description {
  background-color: var(--light-gray);
  padding: 1.25em;
}

.add-to-cart .description .title {
  font-size: 1.125em;
  font-weight: 900;
  margin-bottom: 0.75em;
}

.add-to-cart .description .text {
  font-size: 1em;
  font-weight: 400;
}

.section-with-hiding p span {
  font-weight: 900;
  font-size: 1.125em;

}

.section-with-hiding p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.product-description {
  margin-bottom: 1.875em;
  cursor: pointer;
}

.compare-across .text {
  font-size: 1.125em;
  font-weight: 900;
  line-height: 115%;
  margin-bottom: 1em;
}

.compare-across .text span {
  font-weight: 500;
  font-size: 1em;
  line-height: 115%;
}

.price{
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.compare-across .price {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 115%;
  margin-bottom: 1em;
}

.compare-across {
  margin-bottom: 2.3125em;
}

.compare-variants > div {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 1em;
}

.compare-variants > div > img {
  height: 6.25em;
}

.compare-variants > div > div .name {
  margin-bottom: .075em;
  color: #0D99FF;
  font-size: 1.375em;
}

.compare-variants > div > div .price {
  font-size: 1.5em;
  font-weight: 400;
}

.button.visit-site {
  padding: 0.875em 2.5em;
  font-size: 1em;
}

.compare-variants .separator {
  width: 100%;
  height: 1px;
  background: #DCDCDC;
  margin: 2em 0;
}

.compare-variants {
  margin-bottom: 9.25em;
}

.also-like .category-products {
  display: grid;
  padding-left: 23px;
  padding-right: 23px;
  grid-template-columns: repeat(1, 4fr);
  justify-items: center;
  align-items: center;
  justify-content: space-between;
}

.also-like {
  display: flex;
  margin-bottom: 3.875em;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  justify-items: center;
  align-items: center;
}

.load-more .button-wrapper .button {
  font-weight: 500;
}

.load-more {
  margin-bottom: 6.0625em;
}

.main-image {
  grid-column: 2;
  grid-row: 1;
}

.colors-variants img {
  height: 4.375em;
  width: 4.375em;
}

button.visit-site .desktop {
  color: white;
}

@media (max-width: 990px) {

  .main-image {
    grid-column: initial;
    grid-row: initial;
  }

  .product-info {
    display: block;
    margin-bottom: 80px;
    margin-top: 4px;
  }

  .page-wrapper {
    overflow-x: hidden;
    width: calc(100vw - 32px);
    padding: 0 16px
  }

  .page-wrapper > div {
    width: calc(100vw - 32px);
  }

  .product-images {
    display: grid;
    grid-template-columns: auto;
    gap: 0;
    transform: translateX(-16px);
    width: 100vw;
    overflow-x: hidden;
  }

  .product-images .left-side-images {
    display: flex;
    transition: transform 0.5s;
  }

  .product-images .left-side-images img {
    display: none;
    height: initial;
    width: 100vw;
    margin-bottom: 0;
  }

  .also-like .category-products {
    overflow-x: hidden;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px 16px;
  }

  .also-like .category-products > div,
  .also-like .category-products > div > img {
    width: 100%;
  }

  .heart-wrapper {
    width: calc(50vw - 24px);
  }

  .left-side-images > img.active {
    border: none;
  }

  #first-section-wrapper {
    display: flex !important;
    text-align: center;
    transform: translateZ(10px);
    z-index: 1000;
    object-fit: contain;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .product-images-controller {
    display: flex;
    width: calc(100vw - 32px);
    padding: 0 16px;
    margin: 12px 0 32px 0;
  }

  .product-images-controller div {
    background: #E9E9E9;
    height: 1px;
    width: 100%;
  }

  .product-images-controller div.active {
    background-color: #111111;
  }

  .product-author {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .product-title .product-name {
    font-size: 24px;
  }

  .product-price .price {
    font-size: 24px;
    letter-spacing: -0.48px;
  }

  .product-price {
    gap: 16px;
  }

  .product-price .cashback {
    font-size: 14px;
    padding: 7px 14px 8px 11px;
    line-height: normal;
    height: initial;
  }

  .product-title {
    margin-bottom: 32px;
  }

  .product-details div, .product-details div span {
    font-size: 14px;
    line-height: 140%;
  }

  .product-details {
    padding: 16px;
    margin-bottom: 32px;
  }

  .current-color > span {
    font-size: 14px;
    line-height: normal;
  }

  .current-color {
    margin-bottom: 16px;
  }

  .colors-variants img {
    height: 70px;
    width: 70px;
  }

  .colors-variants {
    gap: 10px;
    width: calc(100vw - 32px);
    overflow-x: scroll;
    transform: translateX(-16px);
    padding: 0 16px;
  }

  .colors-variants::-webkit-scrollbar {
    display: none;
  }

  .product-colors, .compare-across {
    margin-bottom: 32px;
  }

  .product-size .text {
    font-size: 14px;
    line-height: 138%;
    margin-bottom: 12px;
  }

  .select-size {
    font-size: 16px;
    font-weight: 400;
    line-height: 138%;
    padding: 16px;
  }

  .select-size img {
    height: 24px;
    width: 24px;
  }

  .product-size {
    margin-bottom: 40px;
  }

  .product-cashback {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .shop-now button, .add-to-cart button {
    padding: 19px 0;
    font-size: 16px;
  }

  .price-alert {
    background-color: var(--light-gray);
    padding: 15px 0;
  }

  .price-alert p {
    font-size: 16px;
    font-weight: 500;
    gap: 8px;
  }

  .or {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
  }

  .add-to-cart .description .title {
    font-size: 18px;
    line-height: 115%;
  }

  .add-to-cart .description .text {
    font-size: 14px;
    line-height: 140%;
  }

  .add-to-cart .description {
    padding: 24px 16px;
  }

  .section-with-hiding p span,
  .compare-across .text,
  .compare-across .text span,
  .compare-across .price {
    font-size: 16px;
  }

  .product-description {
    margin-bottom: 16px;
  }

  .compare-across .text {
    margin-bottom: 16px;
  }

  .compare-variants > div {
    grid-template-columns: auto 1fr;
    gap: 16px 24px;
  }

  .compare-variants > div > button {
    grid-column: 1 / 3;
    color: #111;
    background-color: white;
    border: 1px solid #111;
  }

  .button.visit-site {
    font-size: 14px;
    padding: 14px 0;
  }

  .compare-variants > div > img {
    width: 80px;
    height: 100px;
  }

  .compare-variants > div > div .name,
  .compare-variants > div > div .price {
    font-size: 16px;
  }

  .compare-variants > div > div > .name {
    line-height: 140%;
    margin-bottom: 8px;
  }

  .compare-variants .separator {
    margin: 24px 0;
  }

  .compare-variants {
    margin-bottom: 72px;
  }

  .category-products > div > p {
    font-size: 14px;
  }

  .category-products > div > p.author {
    margin-top: 16px;
  }

  .category-products > div > p:not(:last-child) {
    margin-bottom: 8px;
  }

  .also-like {
    display: grid;
    margin-bottom: 40px;
    grid-template-columns: repeat(2, 4fr);
    justify-items: center;
    justify-content: space-between;
    align-items: center;
  }

  .load-more button {
    width: 100%;
    padding: 14px 0;
    font-size: 14px;
  }

  .load-more {
    margin-bottom: 120px;
  }
}


</style>
